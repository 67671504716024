/** @format */

import React, {
  createContext,
  useContext,
  useMemo,
  type PropsWithChildren,
} from 'react'
import useSWR, {type KeyedMutator} from 'swr'

import {FullPageLoading} from '@src/components/tailwind/Loading'

import {useFetch} from '@src/hooks/useFetch'
import * as toast from '@src/toast'
import type {Form} from '@src/types/Form'
import {formbutton} from '@src/utils'

import {useAccountContext} from './useAccountContext'

export type AllFormsContext = {
  formByHashId: Map<string, Form>
  forms: Form[]
  reloadAllForms: KeyedMutator<Form[]>
}

const Context = createContext<AllFormsContext | null>(null)

type FormsApiResponse = {
  forms: Form[]
  ok: true
}

export function AllFormsContextProvider(props: PropsWithChildren) {
  const {children} = props
  const {account} = useAccountContext()

  const fetch = useFetch()
  const {data, mutate} = useSWR(
    {
      account: account.id,
      endpoint: '/api-int/forms',
    },
    async ({endpoint}) => {
      const {forms}: FormsApiResponse = await fetch(endpoint, {method: 'GET'})
      return forms
    },
    {
      onError() {
        toast.error('Error fetching forms list')
      },
      onSuccess(forms) {
        formbutton()('set', {'Num Forms': forms.length})
      },
      revalidateOnFocus: false,
    },
  )

  const formByHashId = useMemo(
    () => new Map(data?.map(f => [f.hashid, f])),
    [data],
  )

  if (!data) {
    return <FullPageLoading />
  }

  const ctx = {
    formByHashId,
    forms: data,
    reloadAllForms: mutate,
  }

  return <Context.Provider value={ctx}>{children}</Context.Provider>
}

export function useAllFormsContext(): AllFormsContext {
  const ctx = useContext(Context)
  if (!ctx) {
    throw new Error(
      'useAllFormsContext must be used within AllFormsContextProvider.',
    )
  }
  return ctx
}

export const __testingOnly__ = {
  Context,
}
