/** @format */

import React, {useContext} from 'react'
import {ExclamationIcon} from '@heroicons/react/outline'
import LoaderButton from '../../components/tailwind/LoaderButton'
import Button from '../../components/tailwind/Button'
import {ModalContext} from '../../components/tailwind/ModalContext'

export default function RemoveMemberModal(props) {
  const {member, removeMember} = props
  const {closeCurrentModal} = useContext(ModalContext)

  return (
    <>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to remove{' '}
              <strong>
                {[member.first_name, member.last_name].join(' ') ||
                  member.email}
              </strong>{' '}
              from this team? This member will be unable to access any projects,
              forms, and submissions on this team.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <LoaderButton
          className="w-full inline-flex px-4 py-2 text-base sm:w-auto sm:ml-3"
          onClick={() => removeMember()}
        >
          Remove
        </LoaderButton>
        <Button
          className="mt-3 sm:mt-0 w-full inline-flex px-4 py-2 text-base sm:w-auto sm:ml-3"
          onClick={() => closeCurrentModal()}
        >
          Cancel
        </Button>
      </div>
    </>
  )
}
