/** @format */

import PromiseWindow from 'promise-window'
import React, {
  createContext,
  PropsWithChildren,
  ReactEventHandler,
  useCallback,
  useContext,
  useState,
} from 'react'

import {useModalContext} from '@src/components/tailwind/ModalContext'

import ajax from '@src/ajax'
import {useLoadingContext} from '@src/contexts'
import {useFormApi} from '@src/contexts/FormApi'
import {revalidateListValidationsQuery} from '@src/forms/FormPage/Workflow/Validation/ValidationApi'
import * as toast from '@src/toast'
import type {NewPlugin, Plugin} from '@src/types/Form'
import type {PluginKind} from '@src/types/Plugin'

const newAPIPlugins = new Set<PluginKind>([
  'airtable',
  'asana',
  'constantcontact',
  'email',
  'gorgias',
  'hubspot',
  'notion',
  'salesforce',
  'stripe',
  'stripe_v2',
  'trello',
])

export interface PluginContextType {
  loadPluginOptions: (
    plugin: Plugin | NewPlugin,
    options?: {
      onSuccess?: (result: any) => void
      params?: URLSearchParams | Record<string, string>
    },
  ) => void
  makeOAuthConnectHandler: (
    plugin: NewPlugin,
    options?: {
      doCreate?: (data: any) => any
      // Some plugins do not have finish setup stage, pass this flag to skip it
      // and close the modal.
      closeModal?: boolean
      // Any custom querystring to pass to the auth API
      params?: URLSearchParams
    },
  ) => ReactEventHandler
  connectPlugin: (plugin: NewPlugin, payload: any, closeModal?: boolean) => void
  updateSettings: (
    plugin: Plugin | NewPlugin,
    payload: any,
    finishSetup?: boolean,
    closeModal?: boolean,
  ) => void
  makeUpdateSettingsHandler: (
    plugin: Plugin | NewPlugin,
    getPayload: () => any,
    finishSetup?: boolean,
    closeModal?: boolean,
  ) => ReactEventHandler
  connecting: boolean
}

export const PluginContext = createContext<PluginContextType>({
  loadPluginOptions: () => {},
  makeOAuthConnectHandler: () => () => {},
  connectPlugin: () => {},
  updateSettings: () => {},
  makeUpdateSettingsHandler: () => () => {},
  connecting: false,
})

function oAuthPopup(
  hashid: string,
  pluginKind: PluginKind,
  hasTestKey: boolean,
  config: any,
  onCancel?: () => void,
  params?: URLSearchParams,
) {
  let endpoint = newAPIPlugins.has(pluginKind)
    ? `/api-int/forms/${hashid}/plugins/${pluginKind}/auth`
    : `/forms/${hashid}/plugins/${pluginKind}/auth`

  if (params) {
    endpoint = `${endpoint}?${params}`
  }
  const promise = hasTestKey
    ? Promise.resolve({})
    : PromiseWindow.open(endpoint, config)

  promise.catch(async (err: string) => {
    switch (err) {
      case 'closed':
        onCancel && onCancel()
        break
      default:
        throw err
    }
  })

  return promise
}

export const PluginContextProvider = ({children}: PropsWithChildren) => {
  const form = useFormApi()
  const currentForm = form.current

  const {ready} = useLoadingContext()
  const {closeCurrentModal} = useModalContext()

  const [connecting, setConnecting] = useState(false)
  const loadPluginOptions = useCallback(
    (plugin, {onSuccess, params} = {}) => {
      let endpoint = `/api-int/forms/${currentForm.hashid}/plugins/${plugin.kind}/options`
      if (params) {
        endpoint = `${endpoint}?${new URLSearchParams(params)}`
      }

      ajax({
        method: 'GET',
        endpoint,
        onSuccess,
        errorMsg: 'Failed to fetch plugin options',
      })
    },
    [currentForm.hashid],
  ) satisfies PluginContextType['loadPluginOptions']

  const connectOAuth: (
    plugin: NewPlugin,
    opts: {
      config?: {width: number; height: number}
      onSuccess?: () => void
      doCreate?: (data: any) => any
      onFailure?: (err: string) => void
      onClosePopup?: () => void
      params?: URLSearchParams
    },
  ) => void = (
    plugin,
    {
      config = {width: 601, height: 800},
      onSuccess,
      onFailure,
      onClosePopup,
      doCreate,
      params,
    },
  ) => {
    oAuthPopup(
      currentForm.hashid,
      plugin.kind,
      !!plugin.hasTestKey,
      config,
      onClosePopup,
      params,
    )
      .then((data: any) => {
        if (doCreate) {
          const payload = doCreate(data)
          let endpoint = newAPIPlugins.has(plugin.kind)
            ? `/api-int/forms/${currentForm.hashid}/plugins/${plugin.kind}/`
            : `/forms/${currentForm.hashid}/plugins/${plugin.kind}/auth`
          ajax({
            endpoint: endpoint,
            method: 'POST',
            payload,
            onSuccess,
            onError: async (r: string) => {
              throw r
            },
          })
        } else {
          onSuccess && onSuccess()
        }
      })
      .catch(onFailure)
  }

  const makeOAuthConnectHandler: PluginContextType['makeOAuthConnectHandler'] =
    (plugin, {doCreate, closeModal = false, params} = {}) => {
      return e => {
        e.preventDefault && e.preventDefault()

        setConnecting(true)

        if (!plugin.authed) {
          connectOAuth(plugin, {
            doCreate,
            params,
            onSuccess: async () => {
              setConnecting(false)
              await form.revalidate()
              if (closeModal) {
                closeCurrentModal()
              } else {
                ready()
              }
            },
            onFailure: err => {
              setConnecting(false)
              switch (err) {
                case `${plugin.kind}-failure`:
                  toast.warning(
                    `Plugin returned an error when we tried to connect.`,
                  )
                  break
                default:
                  toast.warning(err)
                  ready()
                  break
              }
            },
            onClosePopup: () => {
              setConnecting(false)
              ready()
            },
          })
        }
      }
    }

  const connectPlugin: PluginContextType['connectPlugin'] = (
    plugin,
    payload,
    closeModal = true,
  ) => {
    ajax({
      endpoint: `/api-int/forms/${currentForm.hashid}/plugins/${plugin.kind}`,
      method: 'POST',
      payload,
      successMsg: `Plugin connected`,
      errorMsg: `Failed to connect plugin`,
      onSuccess: async () => {
        await Promise.all([
          form.revalidate(),
          revalidateListValidationsQuery(currentForm.hashid),
        ])

        if (closeModal) {
          closeCurrentModal()
        } else {
          ready()
        }
      },
      onError: () => {
        ready()
      },
    })
  }

  const updateSettings: PluginContextType['updateSettings'] = (
    plugin,
    payload,
    finishSetup = false,
    closeModal = finishSetup,
  ) => {
    ajax({
      endpoint: `/api-int/forms/${currentForm.hashid}/plugins/${plugin.kind}`,
      method: 'PATCH',
      payload: {
        plugin_data: {
          ...plugin.info,
          ...payload,
        },
        ...(finishSetup ? {enabled: true} : {}),
      },
      successMsg: `Plugin updated successfully.`,
      errorMsg: `Failed to update plugin`,
      onSuccess: async () => {
        await Promise.all([
          form.revalidate(),
          revalidateListValidationsQuery(currentForm.hashid),
        ])
        if (closeModal) {
          closeCurrentModal()
        } else {
          ready()
        }
      },
    })
  }

  const makeUpdateSettingsHandler: PluginContextType['makeUpdateSettingsHandler'] =
    (plugin, getPayload, finishSetup, closeModal) => {
      const handleEvent: ReactEventHandler = e => {
        e.preventDefault && e.preventDefault()
        updateSettings(plugin, getPayload(), finishSetup, closeModal)
      }
      return handleEvent
    }

  return (
    <PluginContext.Provider
      value={{
        loadPluginOptions,
        makeOAuthConnectHandler,
        connectPlugin,
        updateSettings,
        makeUpdateSettingsHandler,
        connecting,
      }}
    >
      {children}
    </PluginContext.Provider>
  )
}

export function usePluginContext(): PluginContextType {
  return useContext(PluginContext)
}
