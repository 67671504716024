/** @format */

import * as Sentry from '@sentry/react'
import React, {ReactNode} from 'react'
import {Link} from 'react-router-dom'

import LoaderButton from '@src/components/tailwind/LoaderButton'
import * as Modal from '@src/components/tailwind/ModalV2'

import {amplitude} from '@src/amplitude'
import {Plan, Product, useLoadingContext} from '@src/contexts'
import {getByCardName} from '@src/images/cards'
import * as toast from '@src/toast'
import {Card} from '@src/types/Account'

import {
  Discount,
  Estimate,
  PaymentSection,
  SelectPlan,
  useSelectPlan,
} from './common'

type WithSavedCardProps = {
  card: Card
  description?: ReactNode
  discount?: string
  isOpen: boolean
  onConfirmUpgrade: (plan: Plan) => Promise<void> | void
  options: Product['id'][]
  title?: string
}

export function WithSavedCard(props: WithSavedCardProps) {
  const {
    card,
    description,
    discount,
    isOpen,
    onConfirmUpgrade,
    options,
    title,
  } = props

  const selectPlan = useSelectPlan(options)

  const {ready} = useLoadingContext()
  async function handleConfirmUpgrade() {
    try {
      await onConfirmUpgrade(selectPlan.state)
      amplitude.logEvent('Upgrade Plan Completed', {
        product: selectPlan.state.product,
        stripe_plan: selectPlan.state.stripe_plan,
      })
    } catch (err) {
      toast.error(
        err instanceof Error
          ? err.message
          : 'Could not upgrade plan. Please reach out to support.',
      )
      Sentry.withScope(scope => {
        scope.setTag(
          'location',
          'UpgradeSubscriptionModal.confirmUpgradeHandler',
        )
        scope.setTag('paymentMethod', 'saved-card')
        Sentry.captureException(err)
      })
    } finally {
      ready()
    }
  }

  return (
    // Cannot wrap in <form> and event.preventDefault()
    // since some callers (e.g. FormPage Settings) still relies on the event to
    // propagate up to the parent.
    <Modal.DefaultLayout>
      <Modal.Header title={title} />
      <Modal.Content>
        <div className="grid gap-6">
          <div className="grid gap-4">
            {description && <p className="text-sm">{description}</p>}
            <SelectPlan {...selectPlan.props} />
          </div>
          <PaymentSection>
            <div className="grid gap-2">
              {discount && <Discount>{discount}</Discount>}
              <Estimate
                isActive={isOpen}
                params={{
                  plan: selectPlan.state.stripe_plan,
                  postalCode: card.address_zip,
                  country: card.address_country,
                }}
              />
            </div>
            <div className="rounded bg-gray-100 p-4">
              <p className="mb-1 text-sm">You card on file will be charged.</p>
              <div className="flex gap-x-2">
                <img
                  alt={card.brand}
                  className="shrink-0"
                  height={20}
                  src={getByCardName(card.css_name)}
                  width={30}
                />
                <span className="text-sm text-gray-500">
                  •••• {card.last4} (Exp: {card.exp_month}/{card.exp_year})
                </span>
                <Link
                  className="ml-auto shrink-0 text-xs text-gray-500 underline hover:text-gray-700"
                  to="/account/billing"
                >
                  Change
                </Link>
              </div>
            </div>
          </PaymentSection>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className="flex flex-row-reverse">
          <LoaderButton onClick={handleConfirmUpgrade}>
            Confirm Upgrade
          </LoaderButton>
        </div>
      </Modal.Footer>
    </Modal.DefaultLayout>
  )
}
