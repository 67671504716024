/** @format */

import React, {useContext, useState} from 'react'
import {ModalContext} from '../../components/tailwind/ModalContext'
import {InformationCircleIcon} from '@heroicons/react/outline'
import LoaderButton from '../../components/tailwind/LoaderButton'
import Button from '../../components/tailwind/Button'
import Input from '../../components/tailwind/Input'
import ajax from '../../ajax'
import {LoadingContext} from '../../contexts/LoadingContext'
import {MembershipContext} from './Team'
import {AccountContext} from '../../contexts/AccountContext'
import {isValidEmail} from '../../utils'

export default function AddMemberModal() {
  const {closeCurrentModal} = useContext(ModalContext)
  const {ready} = useContext(LoadingContext)
  const {memberships, dispatchMemberships} = useContext(MembershipContext)
  const [email, setEmail] = useState('')
  const {profile} = useContext(AccountContext)

  async function addMember() {
    let current_membership = memberships.filter(
      membership => membership.member.id === profile.id
    )[0]
    await ajax({
      method: 'POST',
      endpoint: `/api-int/team/memberships`,
      payload: {
        email: email,
        membership_id: current_membership.membership_id,
        // TODO: change this to role when we add more roles
        role: 'admin'
      },
      onSuccess: member => {
        dispatchMemberships({type: 'add', payload: member})
        closeCurrentModal()
      },
      successMsg: `Successfully invited member`,
      errorMsg: 'Failed to invite member'
    })
    ready()
  }

  return (
    <>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
          <InformationCircleIcon
            className="h-6 w-6 text-blue-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <p className="text-sm text-gray-500">
            New members will have the same privileges as you. This gives them
            the ability to:
          </p>
          <ul className="font-medium">
            <li>Create, update, and delete forms</li>
            <li>Create, update, and delete projects</li>
            <li>Update billing information</li>
          </ul>
        </div>
      </div>
      <p className="mt-4 text-sm text-gray-500">
        Enter the email address of the user you'd like to invite.
      </p>
      <div>
        <Input
          autoFocus
          placeholder="you@example.com"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <LoaderButton
          className="w-full inline-flex px-4 py-2 text-base sm:w-auto sm:ml-3"
          disabled={!isValidEmail(email)}
          onClick={() => addMember()}
        >
          Invite
        </LoaderButton>
      </div>
    </>
  )
}
