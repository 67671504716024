/** @format */

import {RadioGroup, Switch} from '@headlessui/react'
import {CheckIcon} from '@heroicons/react/solid'
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js'
import clsx from 'clsx'
import React, {useContext, useEffect, useState} from 'react'

import {Alert} from '@src/components/tailwind/Alert'
import FormspreeSelect from '@src/components/tailwind/FormspreeSelect'
import LoaderButton from '@src/components/tailwind/LoaderButton'
import {useModalContext} from '@src/components/tailwind/ModalContext'

import ajax from '../ajax'
import Spinner from '../components/tailwind/Spinner'
import {AccountContext} from '../contexts/AccountContext'
import {LoadingContext} from '../contexts/LoadingContext'
import * as toast from '../toast'

const CHECKOUT_STEPS = {
  billing_details: 'BILLING_DETAILS',
  payment: 'PAYMENT',
}

const formspree = window.formspree

export default function TrialPaymentForm(props) {
  const stripe = useStripe()
  const elements = useElements()
  const {ready} = useContext(LoadingContext)
  let [data, setData] = useState({
    name: undefined,
    address_line1: undefined,
    address_city: undefined,
    address_state: undefined,
    address_country: undefined,
    address_zip: '',
    monthly: true,
  })
  const {closeCurrentModal} = useModalContext()
  const [estimate, setEstimate] = useState('...')
  const {account, profile, reloadAccount, card} = useContext(AccountContext)
  let [step, setStep] = useState(
    card ? CHECKOUT_STEPS.payment : CHECKOUT_STEPS.billing_details,
  )
  let [elementsComplete, setElementsComplete] = useState(false)
  let currentTrialProduct = formspree.products.find(
    product => product.id === account.product,
  )

  const productOptions = formspree.products
    .filter(product =>
      currentTrialProduct.available_products.includes(product.id),
    )
    .map(product => {
      return {
        id: product.id,
        title: product.name,
        description: data.monthly
          ? `\$${product.monthly.price}/month`
          : `\$${product.yearly.price}/year`,
        monthly: {
          price: product.monthly.price,
          plan: product.monthly.stripe_plan,
        },
        yearly: {price: product.yearly.price, plan: product.yearly.stripe_plan},
      }
    })

  let [product, setProduct] = useState(productOptions[0].id)
  let selectedProduct = productOptions.find(
    productOption => productOption.id === product,
  )

  useEffect(() => {
    updateEstimate()
  }, [product, data.monthly])

  useEffect(() => {
    if (data.address_zip.length === 5 && data.address_country === 'US') {
      updateEstimate()
    }
  }, [data.address_zip])

  const canContinue = () => {
    return [data.name, data.address_line1, data.address_country].every(field =>
      Boolean(field),
    )
  }

  const canSubmit = () => {
    return (
      card ||
      (elementsComplete &&
        [data.name, data.address_line1, data.address_country].every(field =>
          Boolean(field),
        ))
    )
  }

  const updateEstimate = async () => {
    let stripePlan = data.monthly
      ? selectedProduct.monthly.plan
      : selectedProduct.yearly.plan
    setEstimate(<Spinner type="circle" className="h-4 w-4 pt-1 text-black" />)
    await ajax({
      method: 'GET',
      endpoint: `/api-int/estimate?plan=${stripePlan}&postalCode=${data.address_zip}&country=${data.address_country}`,
      onSuccess: async data => {
        setEstimate(data.amount)
      },
      onError: async data => {
        setEstimate('error')
      },
    })
  }

  const subscribe = async () => {
    await ajax({
      method: 'POST',
      endpoint: '/api-int/subscribe',
      onSuccess: async data => {
        amplitude.logEvent('Subscribed')
        gtag_report_conversion()
        closeCurrentModal()
      },
      onError: () => {
        ready()
      },
      errorMsg: 'Failed to subscribe',
      successMsg: 'Successfully subscribed!',
      encourageRetry: false,
    })
  }

  const completeCheckout = async e => {
    e.preventDefault()
    const cardElement = elements.getElement(CardElement)

    let payload = {
      plan: data.monthly
        ? selectedProduct.monthly.plan
        : selectedProduct.yearly.plan,
    }

    if (!card) {
      let {paymentMethod, error} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: data.name,
          email: profile.email,
          address: {
            line1: data.address_line1,
            city: data.address_city,
            state: data.address_state,
            postal_code: data.address_zip,
            country: data.address_country,
          },
        },
      })
      if (error) {
        toast.warning(error.message)
        ready()
        return
      }

      payload.payment_method_id = paymentMethod.id
    }

    await ajax({
      method: 'POST',
      endpoint: '/api-int/start-subscription',
      payload,
      onSuccess: async data => {
        let payment_method = cardElement
          ? {card: cardElement}
          : data.default_payment_method

        if (data.payment_intent && data.payment_intent.status !== 'succeeded') {
          await stripe
            .confirmCardPayment(data.payment_intent.client_secret, {
              payment_method: payment_method,
              setup_future_usage: 'off_session',
            })
            .then(async result => {
              if (result.error) {
                toast.error(result.error.message)
              } else {
                await subscribe()
                reloadAccount()
                closeCurrentModal()
              }
            })
        } else {
          reloadAccount()
          closeCurrentModal()
        }
      },
      encourageRetry: false,
      errorMsg: 'Failed to purchase',
    })
    ready()
  }

  let countryOptions = formspree.billing_countries.map(country => {
    return {label: country.name, value: country.code}
  })

  return (
    <form onSubmit={completeCheckout} id="payment-form">
      {step === CHECKOUT_STEPS.billing_details ? (
        <>
          <label>
            Name:{' '}
            <input
              autoComplete="name"
              name="name"
              autoFocus
              required
              value={data.name}
              onChange={e => {
                setData({...data, name: e.target.value})
              }}
            />
          </label>
          <label>
            Address:{' '}
            <input
              autoComplete="street-address"
              name="street-address"
              required
              value={data.address_line1}
              onChange={e => {
                setData({...data, address_line1: e.target.value})
              }}
            />
          </label>
          <div className={'flex space-x-2'}>
            <label className="flex-1">
              City:{' '}
              <input
                autoComplete="city"
                name="city"
                value={data.address_city}
                onChange={e => {
                  setData({...data, address_city: e.target.value})
                }}
              />
            </label>
            <label className="flex-1">
              State/Province:{' '}
              <input
                autoComplete="state"
                name="state"
                value={data.address_state}
                onChange={e => {
                  setData({...data, address_state: e.target.value})
                }}
              />
            </label>
          </div>
          <label className="flex-1">
            Country:{' '}
            <FormspreeSelect
              required
              options={countryOptions}
              onChange={country => {
                setData({...data, address_country: country.value})
              }}
              inputProps={{autoComplete: 'none'}}
            />
          </label>
          <div className={'mt-4 text-right'}>
            <button
              className="buttonPrimary"
              onClick={() => {
                setStep(CHECKOUT_STEPS.payment)
              }}
              disabled={!canContinue()}
            >
              Next &gt;
            </button>
          </div>
        </>
      ) : (
        <div className="grid grid-cols-1 gap-y-4">
          <Cards
            label="Choose a plan"
            options={productOptions}
            value={product}
            onChange={plan => {
              setProduct(plan)
            }}
          />
          <Switch.Group
            as="div"
            className="flex items-center justify-start gap-x-2"
          >
            <Switch
              checked={!data.monthly}
              onChange={checked => {
                setData({...data, monthly: !checked})
              }}
              className={clsx(
                !data.monthly ? 'bg-secondary' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
                props.className,
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden
                className={clsx(
                  !data.monthly ? 'translate-x-5' : 'translate-x-0',
                  'inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
            <div className="flex flex-col">
              <Switch.Label className={`text-sm text-gray-500`}>
                Save with annual billing
                <span className="ml-2 inline-flex rounded bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                  $
                  {selectedProduct.monthly.price * 12 -
                    selectedProduct.yearly.price}{' '}
                  off
                </span>
              </Switch.Label>
            </div>
          </Switch.Group>
          <div className="mt-4">
            <div className="inline-flex">
              Total amount to be charged today:&nbsp;<b>{estimate}</b>
            </div>
          </div>
          {card ? (
            <Alert type="info">
              <div className="text-sm text-blue-700">
                Your card on file will be charged
              </div>
            </Alert>
          ) : (
            <label>
              <div className="text-sm text-gray-500">Card Details</div>
              <div className="input">
                <CardElement
                  onChange={event => {
                    setElementsComplete(event.complete)
                    setData({...data, address_zip: event.value.postalCode})
                  }}
                  options={{
                    style: {
                      base: {
                        lineHeight: '28px',
                      },
                    },
                  }}
                />
              </div>
            </label>
          )}
          <div className={'text-sm text-gray-500'}>
            I authorize Formspree to send instructions to the financial
            institution that issued my card to take payments from my card
            account in accordance with the terms of my agreement with you.
          </div>
          <div className={'mt-4 text-right'}>
            <LoaderButton className="buttonPrimary" disabled={!canSubmit()}>
              Subscribe
            </LoaderButton>
          </div>
        </div>
      )}
    </form>
  )
}

function Cards({options, label, ...props}) {
  return (
    <RadioGroup {...props}>
      <RadioGroup.Label className="text-base font-medium text-gray-900">
        {props.label}
      </RadioGroup.Label>

      <div className="mt-4 flex gap-x-2 gap-y-6 sm:gap-x-4">
        {options.map(option => (
          <RadioGroup.Option
            key={option.id}
            value={option.id}
            className={({checked, active}) =>
              clsx(
                checked ? 'border-transparent bg-secondary' : 'border-gray-300',
                'relative flex flex-1 cursor-pointer rounded border p-4 shadow-sm focus:outline-none',
              )
            }
          >
            {({checked, active}) => (
              <>
                <div className="flex flex-1">
                  <div className="flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={clsx(
                        checked ? 'text-white' : 'text-gray-900',
                        'block font-medium',
                      )}
                    >
                      {option.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={clsx(
                        'mt-1 flex items-center text-sm',
                        checked ? 'text-white' : 'text-gray-500',
                      )}
                    >
                      {option.description}
                    </RadioGroup.Description>
                  </div>
                </div>
                <CheckIcon
                  className={clsx(
                    !checked ? 'invisible' : '',
                    'h-5 w-5 text-white',
                  )}
                  aria-hidden="true"
                />
                <div
                  className={clsx(
                    'border-2',
                    checked ? 'border-secondary' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded',
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
