/** @format */

import React from 'react'

import {useAccountContext} from '@src/contexts'
import {getByCardName} from '@src/images/cards'

export default function PaymentCard() {
  const {card} = useAccountContext()

  if (card) {
    return (
      <div className="mt-1 text-sm">
        <img
          alt={card.brand}
          className="inline-block h-5"
          src={getByCardName(card.css_name)}
        />
        <span> •••• {card.last4}</span>
        <div className="m-0 text-gray-500">
          <span>Expires </span>
          <span>
            {card.exp_month}/{card.exp_year}
          </span>
        </div>
      </div>
    )
  } else {
    return (
      <div className="text-sm text-gray-500">No payment method on file</div>
    )
  }
}
