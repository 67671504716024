/** @format */

import clsx from 'clsx'
import React, {useContext, useEffect, useState} from 'react'

import {Loading} from '@src/components/tailwind/Loading'

import ajax from '../../ajax'
import LoaderButton from '../../components/tailwind/LoaderButton'
import Modal from '../../components/tailwind/Modal'
import {
  ContextModal,
  ModalContext,
} from '../../components/tailwind/ModalContext'
import {AccountContext} from '../../contexts/AccountContext'
import {LoadingContext} from '../../contexts/LoadingContext'
import {ACCOUNT_MODAL_NAMES} from '../Account'

export default function TwoFactorDetailsModal() {
  const {updateAccount, profile} = useContext(AccountContext)
  const {closeCurrentModal} = useContext(ModalContext)

  const [isLoading, setIsLoading] = useState(true)
  const [backupCodes, setBackupCodes] = useState([])
  const [showBackupCodes, setShowBackupCodes] = useState(false)

  useEffect(() => {
    fetchTwoFactorInformation()
  }, [profile.two_factor_authentication_enabled])

  const fetchTwoFactorInformation = async () => {
    setIsLoading(true)
    ajax({
      method: 'GET',
      endpoint: '/api-int/account/two_factor_authentication',
      onSuccess: response => {
        setBackupCodes(response.codes || [])
      },
      errorMsg: 'Failed to retrieve 2 factor authentication codes',
    }).then(() => {
      setIsLoading(false)
    })
  }

  const disableTwoFactorAuth = async () => {
    await ajax({
      method: 'POST',
      endpoint: '/api-int/account/two_factor_authentication/disable',
      errorMsg: 'Failed to disable 2 factor authentication',
      successMsg: 'Successfully disabled 2 factor authentication',
      onSuccess: () => {
        updateAccount(acct => {
          acct.profile.two_factor_authentication_enabled = false
          return {...acct}
        })
        closeCurrentModal()
      },
    })
  }

  return (
    <ContextModal
      modal={ACCOUNT_MODAL_NAMES.TWO_FACTOR_DETAILS_MODAL}
      title={'Two Factor Authentication Details'}
      onClose={() => {
        setShowBackupCodes(false)
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <div className={'prose'}>
          <div className="space-y-1">
            <div>
              Two factor authentication is <strong>active</strong> for your
              account.
            </div>
            <div className="flex">
              <div className={'mt-0 grow'}>
                You have {backupCodes.filter(code => !code.used).length} unused
                backup codes
              </div>
              <a
                href="#"
                className={'shrink-0'}
                onClick={e => {
                  e.preventDefault()
                  setShowBackupCodes(!showBackupCodes)
                }}
              >
                {showBackupCodes ? 'Hide' : 'Show'}
              </a>
            </div>
          </div>
          <div className="grid grid-flow-col grid-cols-2 grid-rows-5 gap-x-4 gap-y-2 text-center">
            {backupCodes.map(code => {
              return (
                <code
                  key={code.code}
                  className={clsx({
                    'line-through': code.used,
                  })}
                >
                  {showBackupCodes ? code.code : <>••••••••••</>}
                </code>
              )
            })}
          </div>
        </div>
      )}
      <Modal.Footer>
        <button onClick={closeCurrentModal} className={'buttonPrimary'}>
          Close
        </button>
        <LoaderButton onClick={disableTwoFactorAuth} secondary>
          Disable 2 factor authentication
        </LoaderButton>
      </Modal.Footer>
    </ContextModal>
  )
}
