/** @format */

import React, {useEffect} from 'react'
import {ClipboardCopyIcon} from '@heroicons/react/outline'
import * as toast from '../../toast'
import LoaderButton from '../../components/tailwind/LoaderButton'

const ClipboardJS = require('clipboard')

export function TwoFactorBackupCodes(props) {
  const formattedBackupCodes = props.backupCodes.join('\n')
  let clipboard
  useEffect(() => {
    clipboard = new ClipboardJS('button')
    clipboard.on('success', () => {
      toast.success('Copied to clipboard')
    })

    return () => {
      clipboard.destroy()
    }
  }, [])

  return (
    <>
      <div>
        <div className="border-l-2 border-yellow-400">
          <div className="mt-3 sm:mt-5 ml-2">
            <p className="text-sm text-gray-500">
              If you lose access to your authenticator device, these backup
              codes can help you log in to your account. Each code can only be
              used once. Keep these codes in a safe place.
            </p>
            <p className="text-sm text-gray-500 font-semibold">
              If you lose these codes, our support team may be unable to help
              you log in to your account.
            </p>
          </div>
        </div>
        <div className="flex justify-end">
          <button data-clipboard-text={formattedBackupCodes}>
            <span className="sr-only">Copy codes</span>
            <ClipboardCopyIcon className="h-6 w-6 text-gray-400 hover:text-gray-700" />
          </button>
        </div>
        <div className="grid grid-flow-col grid-cols-2 grid-rows-5 gap-4 text-center">
          {props.backupCodes.map(code => {
            return <code>{code} </code>
          })}
        </div>
        <LoaderButton
          secondary
          onClick={props.nextStep}
          className="w-full mt-2"
        >
          Close
        </LoaderButton>
      </div>
    </>
  )
}
