/** @format */

import React from 'react'

import image from '@src/images/loading.svg'

export function Loading() {
  return <img src={image} id="loading" />
}

export function FullPageLoading() {
  return (
    <div className="center flex h-full items-center justify-center">
      <Loading />
    </div>
  )
}
