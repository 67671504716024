/** @format */

import React from 'react'

export const MISSING = (
  <>
    <svg
      className="h-5 w-5 text-gray-400"
      x-description="Heroicon name: minus"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
        clipRule="evenodd"
      ></path>
    </svg>
    <span className="sr-only">Not included</span>
  </>
)

export const CHECKBOX = (
  <>
    <svg
      className="h-5 w-5 text-green-500"
      x-description="Heroicon name: check"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      ></path>
    </svg>
    <span className="sr-only">Yes</span>
  </>
)

export const INFINITY = (
  <>
    <svg
      className="inline"
      enableBackground="new 0 0 100 47.084"
      viewBox="0 0 100 47.084"
      y="0px"
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      fill="#000000"
      width="18px"
      height="18px"
    >
      <path
        fill="#000000"
        d="M76.459,0C65.343,0,55.149,10.439,50,16.753C44.85,10.439,34.657,0,23.541,0C10.56,0,0,10.561,0,23.542
	s10.56,23.542,23.541,23.542C34.657,47.084,44.85,36.645,50,30.33c5.148,6.314,15.342,16.754,26.459,16.754
	c12.98,0,23.541-10.561,23.541-23.542S89.439,0,76.459,0z M23.541,38.909c-8.473,0-15.367-6.895-15.367-15.367
	c0-8.474,6.894-15.368,15.367-15.368c7.895,0,16.97,9.623,21.403,15.368C40.51,29.289,31.436,38.909,23.541,38.909z M76.459,38.909
	c-7.897,0-16.975-9.627-21.406-15.372c4.423-5.746,13.482-15.365,21.406-15.365c8.474,0,15.366,6.894,15.366,15.368
	C91.825,32.015,84.933,38.909,76.459,38.909z"
      ></path>
    </svg>
  </>
)

//TODO: for AB testing, check cookie to determine whether or not to show free column
export const SHOW_FREE = false
export const STEPS = {
  SALES: -2,
  CANCEL: -1,
  NOTHING: 0,
  REGISTER: 1,
  PERSONAL: 2,
  PAYMENT: 3
}
