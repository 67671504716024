/** @format */

import React from 'react'

export function RequiredFieldMarker() {
  return (
    <span aria-hidden="true" className="select-none text-red-600">
      &nbsp;*
    </span>
  )
}
