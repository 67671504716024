/** @format */

import {ShieldCheckIcon} from '@heroicons/react/outline'
import React from 'react'

export function TwoFactorDisabled(props) {
  return (
    <>
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
        <ShieldCheckIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
      </div>
      <div className="mt-3 sm:mt-5">
        <h3 className="text-lg leading-6 font-medium text-center text-gray-900">
          Verify Account Email
        </h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            2 factor authentication protects your Formspree account by requiring
            an additional one time code when you sign in.
          </p>
          <p className="text-sm text-gray-500">
            To set up 2 factor authentication, you must verify your account
            email.
          </p>
        </div>
      </div>
    </>
  )
}
