/** @format */

import * as Tooltip from '@radix-ui/react-tooltip'
import React from 'react'

export {Root, Trigger} from '@radix-ui/react-tooltip'

export function Content(props: Tooltip.TooltipContentProps) {
  const {
    align = 'start',
    className = 'z-50 max-w-sm overflow-hidden rounded-md bg-gray-900/80 px-3 py-1.5 text-xs text-white animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
    sideOffset = 5,
    ...contentProps
  } = props

  return (
    <Tooltip.Portal>
      <Tooltip.Content
        {...contentProps}
        align={align}
        className={className}
        sideOffset={sideOffset}
      />
    </Tooltip.Portal>
  )
}
