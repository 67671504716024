/** @format */
import React, {useContext, useState} from 'react'
import FormspreeSelect from '../../components/tailwind/FormspreeSelect'
import {AccountContext} from '../../contexts/AccountContext'
import {
  ModalContext,
  ContextModal
} from '../../components/tailwind/ModalContext'
import {LoadingContext} from '../../contexts/LoadingContext'
import Modal from '../../components/tailwind/Modal'
import LoaderButton from '../../components/tailwind/LoaderButton'

export default function ChangeEmailModal({modal}) {
  const {ready} = useContext(LoadingContext)
  const {profile, patchAccount} = useContext(AccountContext)
  const {closeCurrentModal} = useContext(ModalContext)
  const [newEmail, setNewEmail] = useState()
  let options = profile.emails.verified
    .map(e => {
      return {label: e, value: e}
    })
    .concat(
      profile.emails.pending.map(e => {
        return {label: e + ' (unverified)', value: e, isDisabled: true}
      })
    )

  return (
    <ContextModal modal={modal} title={'Update Account Email'}>
      <FormspreeSelect
        value={newEmail || profile.email}
        options={options}
        onChange={option => {
          setNewEmail(option.value)
        }}
      />
      <Modal.Footer>
        <LoaderButton
          onClick={() => {
            patchAccount({
              payload: {
                email: newEmail
              },
              profile: true,
              onSuccess: () => {
                closeCurrentModal()
                setNewEmail()
                ready()
              }
            })
          }}
          disabled={!newEmail || newEmail === profile.email}
        >
          Save
        </LoaderButton>
      </Modal.Footer>
    </ContextModal>
  )
}
