/** @format */

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react'

import {
  Feature,
  FeatureId,
  Product,
  ProductId,
  useFormspreeContext,
} from './FormspreeContext'

export type ProductsApi = {
  // getFeatureById returns Feature for id
  //
  // it *throws* if a feature for an id is not found, since that violates
  // the expectation that a lookup by any id should return a feature.
  getFeatureById(id: FeatureId): Feature

  // getFeatureById returns Product for id
  //
  // it *throws* if a product for an id is not found, since that violates
  // the expectation that a lookup by any id should return a product.
  getProductById(id: ProductId): Product

  // getProductsWithFeature returns a set of ProductId that has the feature.
  //
  // it excludes "custom" and legacy plans
  getProductsWithFeature(featureId: FeatureId): ProductId[]
}

const Context = createContext<ProductsApi | null>(null)

export function ProductsApiProvider(props: PropsWithChildren) {
  const {children} = props
  const {features, products} = useFormspreeContext()

  const getFeatureById = useMemo(() => {
    const byId = new Map(features.map(f => [f.id, f]))
    return function getFeatureById(id) {
      const f = byId.get(id)
      if (!f) {
        throw new Error(
          `Invariant violation: Feature is not found for id: ${id}`,
        )
      }
      return f
    } satisfies ProductsApi['getFeatureById']
  }, [features])

  const availableProducts = useMemo(
    () => products.filter(p => p.available && p.id !== 'custom'),
    [products],
  )

  const getProductById = useMemo(() => {
    const byId = new Map(products.map(p => [p.id, p]))
    return function getProductById(id) {
      const p = byId.get(id)
      if (!p) {
        throw new Error(
          `Invariant violation: Product is not found for id: ${id}`,
        )
      }
      return p
    } satisfies ProductsApi['getProductById']
  }, [products])

  const getProductsWithFeature = useMemo(() => {
    const byFeatureId = new Map<Feature['id'], Set<ProductId>>()

    for (const prod of availableProducts) {
      const pid = prod.id
      // features list is on the plan-level but it's the same
      // for monthly vs yearly on the same product. We just need to pick one.
      for (const f of prod.monthly.features) {
        let prodIds = byFeatureId.get(f.id)
        if (!prodIds) {
          prodIds = new Set()
          byFeatureId.set(f.id, prodIds)
        }
        prodIds.add(pid)
      }
    }

    return function getProductsWithFeature(featureId) {
      const prodIds = byFeatureId.get(featureId)
      return prodIds ? [...prodIds] : []
    } satisfies ProductsApi['getProductsWithFeature']
  }, [availableProducts])

  const api = {
    getFeatureById,
    getProductById,
    getProductsWithFeature,
  }
  return <Context.Provider value={api}>{children}</Context.Provider>
}

export function useProductsApi(): ProductsApi {
  const api = useContext(Context)
  if (!api) {
    throw new Error(
      'No ProductsApi found via context. Use ProductsApiProvider to provide one.',
    )
  }
  return api
}
