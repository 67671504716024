/** @format */

import React, {lazy} from 'react'
import {Navigate, type RouteObject} from 'react-router-dom'

import {accountsRouter} from '@src/accounts'
import {useAccountContext} from '@src/contexts'
import {formsRouter} from '@src/forms'
import {projectsRouter} from '@src/forms/ProjectPage/tailwind'

const Dashboard = lazy(() => import('./Dashboard'))

const Admin = lazy(() => import('@src/Admin'))
const SupportPage = lazy(() => import('@src/support'))

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: <Navigate replace to="/forms" />,
  },
  accountsRouter,
  {
    path: 'admin',
    element: <AdminRoute />,
  },
  formsRouter,
  projectsRouter,
  {
    path: 'support',
    element: <SupportRoute />,
  },
] satisfies RouteObject[]

export const dashboardRouter = {
  path: '*',
  element: <Dashboard />,
  children: dashboardRoutes,
} satisfies RouteObject

function AdminRoute() {
  const {account} = useAccountContext()
  return account.features.admin ? <Admin /> : <Navigate replace to="/forms" />
}

function SupportRoute() {
  const {account} = useAccountContext()
  return account.features.support ? <SupportPage /> : null
}
