/** @format */
import {ExternalLinkIcon} from '@heroicons/react/solid'
import React, {Fragment, useContext, useState} from 'react'

import ActionInput from '@src/components/tailwind/ActionInput'
import Badge from '@src/components/tailwind/Badge'
import ComponentContainer from '@src/components/tailwind/ComponentContainer'
import LoaderButton from '@src/components/tailwind/LoaderButton'
import Modal, {ModalFooter} from '@src/components/tailwind/Modal'
import {ContextModal, ModalContext} from '@src/components/tailwind/ModalContext'
import Spinner from '@src/components/tailwind/Spinner'
import {StatusIndicator} from '@src/components/tailwind/StatusIndicator'
import Table from '@src/components/tailwind/Table'
import {UpgradeMessage} from '@src/components/tailwind/UpgradeMessage'

import ajax from '@src/ajax'
import {AccountContext} from '@src/contexts/AccountContext'
import {LoadingContext} from '@src/contexts/LoadingContext'

import {addDomain} from './utils'

export default function Domains() {
  const {account, domains, reloadAccount} = useContext(AccountContext)
  const {openModal, closeCurrentModal} = useContext(ModalContext)
  const {ready} = useContext(LoadingContext)
  const [currentDomain, setCurrentDomain] = useState({id: null, records: null})
  const [newDomain, setNewDomain] = useState('')

  async function openDomainModal(e) {
    e.preventDefault()
    let domainId = e.currentTarget.dataset.domainId

    await setCurrentDomain({id: domainId})

    openModal('domain-modal')

    await ajax({
      method: 'GET',
      endpoint: `/api-int/account/domains/${domainId}`,
      errorMsg:
        'Unable to retrieve the DNS records. Please try again later or contact support',
      onSuccess: async r => {
        setCurrentDomain(s => ({
          ...s,
          records: r.records,
        }))
      },
    })
  }

  async function verifyDomain(e) {
    e.preventDefault()
    await ajax({
      method: 'POST',
      endpoint: `/api-int/account/domains/${currentDomain.id}/verify`,
      successMsg: 'Finished verifying DNS records',
      errorMsg: 'DNS records failed verification',
      onSuccess: async res => {
        let records = currentDomain.records
        Object.keys(records).map(key => {
          records[key].valid = true
          records[key].errors = null
        })
        setCurrentDomain(s => ({
          ...s,
          records: records,
        }))
      },
      onError: async res => {
        let records = currentDomain.records
        Object.keys(res.validation_results).map(key => {
          // Update all records
          records[key].valid = res.validation_results[key].valid
          records[key].errors = res.validation_results[key].reason
        })
        setCurrentDomain(s => ({
          ...s,
          records: records,
        }))
      },
    })
    ready()
  }

  async function deleteDomain(e) {
    e.preventDefault()
    await ajax({
      method: 'DELETE',
      endpoint: `/api-int/account/domains/${currentDomain.id}`,
      successMsg: `Deleted domain`,
      onSuccess: r => {
        closeCurrentModal()
        reloadAccount()
      },
    })
    ready()
  }

  function handleChangeNewDomain(e) {
    const newDomain = e.target.value
    setNewDomain(newDomain)
  }

  async function handleAddDomain() {
    await addDomain(newDomain, {
      onSuccess: () => reloadAccount(),
    })
    setNewDomain('')
    ready()
  }

  return (
    <ComponentContainer
      title={'Domain Management'}
      fullBleed={true}
      action={
        account.features.custom_domains && (
          <a
            className={'primaryLink cursor-pointer'}
            onClick={() => {
              openModal('add-domain')
            }}
          >
            + Add Domain{' '}
          </a>
        )
      }
    >
      {account.features.custom_domains ? (
        <>
          <ContextModal
            modal="domain-modal"
            title="Verify DNS Records"
            wide={true}
          >
            <DomainModal
              records={currentDomain.records}
              verify={verifyDomain}
              delete={deleteDomain}
            />
          </ContextModal>

          <ContextModal modal={'add-domain'} title={'Add Domain'}>
            <ActionInput
              disabled={!account.features.custom_domains}
              name="domains"
              placeholder={
                account.features.custom_domains
                  ? 'Add a domain to your account'
                  : 'Upgrade to add a domain'
              }
              value={newDomain}
              onChange={handleChangeNewDomain}
            />
            <Modal.Footer>
              <LoaderButton
                type="submit"
                disabled={newDomain === ''}
                onClick={handleAddDomain}
              >
                Add
              </LoaderButton>
            </Modal.Footer>
          </ContextModal>

          <Table
            dividers={false}
            columns={['Domain', 'Status']}
            rows={
              account.features.custom_domains &&
              domains.map(domain => [
                <div className="truncate">
                  <a
                    href=""
                    onClick={openDomainModal}
                    data-domain-id={domain.id}
                  >
                    {domain.domain}
                  </a>
                </div>,
                <a
                  onClick={openDomainModal}
                  data-domain-id={domain.id}
                  className={'cursor-pointer'}
                >
                  {domain.valid ? (
                    <Badge status={'ok'}>Verified</Badge>
                  ) : (
                    <Badge status={'warning'}>Not Verified</Badge>
                  )}
                </a>,
              ])
            }
            empty={
              <p>
                No domains have been added click <em>+&nbsp;Add&nbsp;Domain</em>{' '}
                above.
              </p>
            }
          />
        </>
      ) : (
        <div className="flex flex-col gap-y-4 px-8 py-6">
          <p>
            <span>
              Use your own domain when sending submission emails and
              autoresponses.{' '}
            </span>
            <a
              href="https://help.formspree.io/hc/en-us/articles/1500000941722-Setting-Email-Domain"
              target="_blank"
              className="primaryLink"
            >
              Learn more →
            </a>
          </p>
          <UpgradeMessage feature="custom_domains" />
        </div>
      )}
    </ComponentContainer>
  )
}

function DomainModal(props) {
  if (props.records === undefined) {
    return <Spinner className="h-10 w-10" />
  }
  return (
    <div className={'prose'}>
      <p>
        Add these DNS records to your DNS provider to verify domain ownership.{' '}
        <a
          href="https://help.formspree.io/hc/en-us/articles/1500000941722"
          target="_blank"
        >
          <span>Read more </span>
          <ExternalLinkIcon className="inline-block h-4 w-4" />
        </a>
      </p>
      <table className="block overflow-x-auto">
        <thead>
          <tr>
            <th>Valid</th>
            <th>Type</th>
            <th>Host</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(props.records).map((k, i) => (
            <Fragment key={'record_' + i}>
              <tr>
                <td>
                  <StatusIndicator
                    className="mx-auto h-4 w-4"
                    type={props.records[k].valid ? 'ok' : 'warning'}
                  />
                </td>
                <td>{props.records[k].type.toUpperCase()}</td>
                <td>{props.records[k].host}</td>
                <td>{props.records[k].data}</td>
              </tr>
              {props.records[k].errors && (
                <tr>
                  <td colSpan={4}>
                    <div className="rounded bg-red-100 p-1 text-red-700">
                      {props.records[k].errors}
                    </div>
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
      <ModalFooter>
        <LoaderButton onClick={props.verify} id="verify-domain-button">
          Verify
        </LoaderButton>
        <LoaderButton
          secondary={true}
          onClick={props.delete}
          id="delete-domain-button"
        >
          Delete
        </LoaderButton>
      </ModalFooter>
    </div>
  )
}
