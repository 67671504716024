/** @format */

/**
 * -------------
 * ! Attention !
 * -------------
 * When updating the survey questions, rename the file name with a new version
 * and make sure to update the `survey_version` that is sent with
 * the `subscription_canceled` event to match, since we track the performance
 * for each survey version.
 */

import {useSubmit} from '@formspree/react'
import * as RadioGroup from '@radix-ui/react-radio-group'
import clsx from 'clsx'
import React, {
  ComponentPropsWithoutRef,
  FormEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react'

import Button from '@src/components/tailwind/Button'
import LoaderButton from '@src/components/tailwind/LoaderButton'
import * as Modal from '@src/components/tailwind/ModalV2'

import {
  useAccountContext,
  useFormspreeContext,
  useLoadingContext,
} from '@src/contexts'
import {useFetch} from '@src/hooks/useFetch'
import {shuffle} from '@src/lib'
import * as toast from '@src/toast'
import {formbutton} from '@src/utils'

import formspreeStarSvg from './formspree-star.svg'

type Props = {
  isOpen: boolean
  onClose: () => void
}

type CancelResult = {
  at: string
  show_offer?: boolean
}

export function CancelSubscriptionModal(props: Props) {
  const {isOpen, onClose} = props
  const [cancelResult, setCancelResult] = useState<CancelResult>()
  const [reason, setReason] = useState<Reason>()
  const {reloadAccount} = useAccountContext()

  useEffect(() => {
    formbutton()('showButton', !isOpen)
  }, [isOpen])

  if (!cancelResult) {
    return (
      <Modal.Root isOpen={isOpen} onClose={onClose}>
        <SurveyStep
          onCancelSubscriptionConfirmed={(cancelResult, reason) => {
            setCancelResult(cancelResult)
            setReason(reason)
          }}
        />
      </Modal.Root>
    )
  }

  function handleCancelSuccessClose(): void {
    // kicks off without awaiting because it'll trigger
    // full-page loader anyway
    reloadAccount()
    onClose()
  }

  if (
    cancelResult.show_offer &&
    (reason === 'project-finished' || reason === 'project-terminated')
  ) {
    return (
      <Modal.Root isOpen={isOpen} onClose={handleCancelSuccessClose}>
        <CompletedProjectOfferStep
          reason={reason}
          onClose={handleCancelSuccessClose}
        />
      </Modal.Root>
    )
  }

  return (
    <Modal.Root isOpen={isOpen} onClose={handleCancelSuccessClose}>
      <CancelationSuccessStep
        cancelResult={cancelResult}
        onClose={handleCancelSuccessClose}
      />
    </Modal.Root>
  )
}

type SurveyStepProps = {
  onCancelSubscriptionConfirmed: (
    cancelResult: CancelResult,
    reason: Reason,
  ) => void
}

type Reason =
  | 'did-not-meet-my-needs'
  | 'other'
  | 'project-finished'
  | 'project-terminated'
  | 'switch-form-service'
  | 'temporary-pause'
  | 'too-expensive'
  | 'too-much-spam'

const surveyOptions: {
  extraInput?: {
    label: string
  }
  key: Reason
  label: string
}[] = shuffle([
  {
    extraInput: {
      label: 'Why not?',
    },
    key: 'did-not-meet-my-needs',
    label: "Formspree didn't meet my needs",
  },
  {
    extraInput: {
      label: 'What was the project?',
    },
    key: 'project-finished',
    label: 'The project is finished',
  },
  {
    extraInput: {
      label: 'Why?',
    },
    key: 'project-terminated',
    label: 'The project was terminated',
  },
  {
    extraInput: {
      label: 'To what?',
    },
    key: 'switch-form-service',
    label: 'We switched form services',
  },
  {
    extraInput: {
      label: 'Why? For how long?',
    },
    key: 'temporary-pause',
    label: "I'd like to pause temporarily",
  },
  {
    extraInput: {
      label: 'How much is reasonable?',
    },
    key: 'too-expensive',
    label: "It's too expensive",
  },
  {
    key: 'too-much-spam',
    label: 'I received too much spam',
  },
])

// Other is always the last option
surveyOptions.push({
  extraInput: {
    label: 'What happened?',
  },
  key: 'other' as const,
  label: 'Other',
})

const requiresExtraInput = new Set<Reason>(
  surveyOptions.filter(opt => opt.extraInput).map(opt => opt.key),
)

const RADIO_GROUP_NAVIGATION_KEYS = new Set([
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'Space',
  'Tab',
])

function SurveyStep(props: SurveyStepProps) {
  const {onCancelSubscriptionConfirmed} = props

  const {sub} = useAccountContext()
  const fetch = useFetch()
  const {ready} = useLoadingContext()

  const [extraInputAutoFocus, setExtraInputAutoFocus] = useState(true)

  const [reason, setReason] = useState<'' | Reason>('')
  const [reasonExtra, setReasonExtra] = useState<Map<Reason, string>>(new Map())

  const submitSurvey = useSubmitSurvey()
  const canSubmit =
    !!reason &&
    (!requiresExtraInput.has(reason) || reasonExtra.get(reason)?.trim())

  async function handleSubmit(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault()
    if (!canSubmit) {
      return
    }
    try {
      const cancelResult: CancelResult = await fetch('/api-int/cancel', {
        payload: {reason},
      })
      submitSurvey(reason, reasonExtra.get(reason)) // send it in the background
      onCancelSubscriptionConfirmed(cancelResult, reason)
    } catch {
      toast.error('Failed to cancel subscription.')
    } finally {
      ready()
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Modal.DefaultLayout>
        <Modal.Header title="Cancel Subscription" />
        <Modal.Content>
          <p className="rounded-lg bg-gray-100 p-4 text-sm text-gray-500">
            This will cancel your subscription at the end of your current
            billing cycle
            {sub && (
              <>
                <span> on </span>
                <span className="font-bold text-gray-900">
                  {sub.current_period_end}
                </span>
              </>
            )}
            . Your account will remain active until then.
          </p>
          <RadioGroup.Root
            aria-required="true"
            className="mt-4 flex flex-col gap-y-4"
            value={reason}
            onKeyDown={event => {
              if (RADIO_GROUP_NAVIGATION_KEYS.has(event.key)) {
                setExtraInputAutoFocus(false)
              }
            }}
            // Don't use onClick since it's fired for selection change even when
            // triggered by keyboard navigation.
            onPointerDown={() => setExtraInputAutoFocus(true)}
            // @ts-ignore: type issue in radix-ui
            // onValueChange is (value: string) => void instead of taking generic
            onValueChange={setReason}
          >
            <p>
              Please let us know why you cancel the plan. This will help us
              improve our service for you in the future should you decide to
              resume your subscription.
            </p>
            {surveyOptions.map(({extraInput, key, label}) => (
              <RadioOption
                checked={key === reason}
                key={key}
                label={label}
                value={key}
              >
                {extraInput && (
                  <ExtraInput
                    aria-required
                    autoFocus={extraInputAutoFocus}
                    label={extraInput.label}
                    value={reasonExtra.get(key) ?? ''}
                    onChange={event =>
                      setReasonExtra(
                        new Map(reasonExtra.set(key, event.target.value)),
                      )
                    }
                  />
                )}
              </RadioOption>
            ))}
          </RadioGroup.Root>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex flex-row-reverse">
            <LoaderButton disabled={!canSubmit}>
              Cancel Subscription
            </LoaderButton>
          </div>
        </Modal.Footer>
      </Modal.DefaultLayout>
    </form>
  )
}

function useSubmitSurvey() {
  const {account, profile} = useAccountContext()

  const {CANCEL_SUBSCRIPTPION_SURVEY_FORM_ID} = useFormspreeContext()
  if (!CANCEL_SUBSCRIPTPION_SURVEY_FORM_ID) {
    throw new Error('CANCEL_SUBSCRIPTPION_SURVEY_FORM_ID is not defined')
  }

  type SubmissionData = {
    account_id: string
    // email is required by the form's validation
    email: string
    profile_id: string
    reason: Reason
    subject: string
    tags: ['downgrade', Reason]
  } & Partial<Record<Reason, string>>

  // @ts-ignore: need to update the type in formspree react
  // to support array params
  const submitSurvey = useSubmit<SubmissionData>(
    CANCEL_SUBSCRIPTPION_SURVEY_FORM_ID,
    {
      onError(err) {
        toast.error(
          err
            .getFormErrors()
            .map(e => e.message)
            .join(', '),
        )
      },
    },
  )

  return (reason: Reason, reasonExtra?: string): void => {
    submitSurvey({
      account_id: account.id,
      email: profile.email,
      profile_id: profile.id,
      reason,
      subject: `${
        account.team_name || account.email
      } cancelled their subscription`,
      tags: ['downgrade', reason],
      ...(reasonExtra ? {[reason]: reasonExtra} : {}),
    })
  }
}

type CancelationSuccessStepProps = {
  cancelResult: CancelResult
  onClose: () => void
}

function CancelationSuccessStep(props: CancelationSuccessStepProps) {
  const {cancelResult, onClose} = props

  return (
    <Modal.DefaultLayout>
      <Modal.Content>
        <div className="mt-12 flex flex-col items-center gap-y-2">
          <h2 className="text-2xl font-bold">Your subscription is canceled.</h2>
          <p className="text-gray-700">
            We're sorry to see you go but we&apos;ll be here when you need us
            again.
          </p>
          <p className="text-gray-700">
            Your subscription will remain active until{' '}
            <span className="font-bold text-gray-900">{cancelResult.at}</span>.
          </p>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className="flex flex-row-reverse">
          <Button onClick={onClose} primary>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal.DefaultLayout>
  )
}

type CompletedProjectOfferStepProps = {
  onClose: () => void
  reason: 'project-finished' | 'project-terminated'
}

function CompletedProjectOfferStep(props: CompletedProjectOfferStepProps) {
  const {onClose, reason} = props
  const {title, description} =
    reason === 'project-finished'
      ? {
          title: 'You completed your project!',
          description: (
            <>
              Congratulations and well done! Here is{' '}
              <span className="font-bold text-primary">20% off</span> when you
              upgrade for your next project!
            </>
          ),
        }
      : {
          title: 'Get 20% off your next project!',
          description: (
            <>
              We're sorry your project didn't work out, but we&apos;ll be here
              when you need us again. Enjoy&nbsp;
              <span className="font-bold text-primary">20% off</span> when you
              return and upgrade for your next project.
            </>
          ),
        }
  return (
    <>
      <Modal.Content>
        <div className="mt-14 flex flex-col items-center gap-y-2 text-center">
          <img
            alt=""
            aria-hidden
            src={formspreeStarSvg}
            width={260}
            height={201}
          />
          <h2 className="mt-4 text-2xl font-bold">{title}</h2>
          <p className="text-gray-700">{description}</p>
          <p className="text-gray-700">
            This offer applies to one year of monthly or yearly subscriptions on
            the Personal, Professional or Business plans.
          </p>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className="flex flex-row-reverse">
          <Button onClick={onClose} primary>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </>
  )
}

type RadioOptionProps = {
  // need to pass checked because tailwind v2 doesn't support arbitrary value
  // (JIT is not stable) so we cannot style with data-[state=check]:...
  // and have to switch on the props in JS land instead
  checked: boolean
  children?: ReactElement<typeof ExtraInput>
  id?: string
  label: ReactNode
} & ComponentPropsWithoutRef<typeof RadioGroup.Item>

function RadioOption(props: RadioOptionProps) {
  const {checked, children, id = useId(), label, ...optionProps} = props
  return (
    <div>
      <div className="flex items-center gap-x-2 focus:outline-none">
        <RadioGroup.Item
          {...optionProps}
          className={clsx(
            'flex h-4 w-4 shrink-0 items-center justify-center rounded-full border focus:border-primary focus:outline-none',
            checked
              ? 'border-primary bg-primary focus-visible:ring focus-visible:ring-primary/50'
              : 'border-gray-300',
          )}
          id={id}
          role="radio"
          type="button"
        >
          <RadioGroup.Indicator>
            <i
              aria-hidden="true"
              className="block h-1.5 w-1.5 rounded-full bg-white shadow-sm"
            />
          </RadioGroup.Indicator>
        </RadioGroup.Item>
        <label htmlFor={id}>{label}</label>
      </div>
      {checked && children}
    </div>
  )
}

type ExtraInputProps = {
  autoFocus: boolean
  label: string
} & Omit<ComponentPropsWithoutRef<'input'>, 'autoFocus' | 'className' | 'id'>

function ExtraInput(props: ExtraInputProps) {
  const {autoFocus, label, ...inputProps} = props
  const id = useId()
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const {current: input} = ref
    if (!autoFocus || !input) {
      return
    }
    input.focus({preventScroll: true})
    input.scrollIntoView({behavior: 'smooth'})
  }, [autoFocus])

  return (
    <div className="mt-2 pl-6">
      <label className="text-sm font-medium text-gray-600" htmlFor={id}>
        {label}
      </label>
      <input
        {...inputProps}
        className="m-0 mt-1 px-2 py-1 text-sm"
        id={id}
        ref={ref}
      />
    </div>
  )
}
