/** @format */

import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import React, {StrictMode, Suspense, useEffect} from 'react'
import {createRoot} from 'react-dom/client'
import {RouterProvider} from 'react-router-dom'

import {FullPageLoading} from '@src/components/tailwind/Loading'

import {FormspreeContextProvider, ProductsApiProvider} from '@src/contexts'
import {AccountContextProvider} from '@src/contexts/AccountContext'
import {LoadingContextProvider} from '@src/contexts/LoadingContext'

import {logPageTransition, PageTransition} from './PageTransition'
import {router} from './RootRouter'

/** @format */

const amplitude = window.amplitude
const url = require('url')

Sentry.init({
  dsn: window.formspree.SENTRY_DSN_REACT,
  integrations: [new Integrations.BrowserTracing(), Sentry.replayIntegration()],
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  tracesSampleRate: window.formspree.SENTRY_SAMPLE_RATE_REACT,
  replaysOnErrorSampleRate: window.formspree.SENTRY_REPLAY_ERROR_RATE_REACT,
  replaysSessionSampleRate: window.formspree.SENTRY_REPLAY_SAMPLE_RATE_REACT,
})

function App() {
  useEffect(() => {
    const ampInst = amplitude && amplitude.getInstance()
    if (ampInst) {
      ampInst.init(window.formspree.AMPLITUDE_KEY, null, {
        // Configuration options. Do not change without consulting legal.
        includeReferrer: true,
        includeUtm: true,
        saveParamsReferrerOncePerSession: false,
        trackingOptions: {
          carrier: true,
          city: false,
          country: true,
          device_model: true,
          dma: false,
          ip_address: false,
          language: true,
          os_name: true,
          os_version: true,
          platform: true,
          region: false,
          version_name: true,
        },
      })
      logPageTransition(url.parse(window.location.href).pathname)
    }
  }, [])

  const isUserOptionalPage = window.location.pathname.match(
    /^\/(plans|login|register)\/?/,
  )

  return (
    <FormspreeContextProvider>
      <ProductsApiProvider>
        <LoadingContextProvider>
          <AccountContextProvider userOptional={isUserOptionalPage}>
            <Suspense fallback={<FullPageLoading />}>
              <RouterProvider router={router} />
            </Suspense>
          </AccountContextProvider>
        </LoadingContextProvider>
      </ProductsApiProvider>
    </FormspreeContextProvider>
  )
}

createRoot(document.getElementById('body')).render(
  <StrictMode>
    {window.formspree && window.formspree.SENTRY_DSN_REACT ? (
      <Sentry.ErrorBoundary fallback="An error has occurred" showDialog>
        <App />
      </Sentry.ErrorBoundary>
    ) : (
      <App />
    )}
  </StrictMode>,
)
