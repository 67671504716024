/** @format */

import {useDisclosure} from '@mantine/hooks'
import {PaymentMethod} from '@stripe/stripe-js'
import React from 'react'

import Button from '@src/components/tailwind/Button'
import {PromoBanner} from '@src/components/tailwind/PromoBanner'
import {StripeElementsProvider} from '@src/components/tailwind/StripeElementsProvider'
import {UpgradeSubscriptionModal} from '@src/components/tailwind/UpgradeSubscriptionModal'

import {Plan, useAccountContext} from '@src/contexts'
import {useChangePlan} from '@src/hooks/useChangePlan'
import {AccountState} from '@src/types/Account'

export function ProjectEndedPromoBanner() {
  const {account, sub} = useAccountContext()

  if (
    !account.flags.project_ended_promo_group ||
    account.flags.project_ended_promo_redeemed
  ) {
    return null
  }

  return (
    <div className="mx-2 my-8 sm:mx-8">
      {sub ? <CancelingPlanBanner sub={sub} /> : <RedeemablePromoBanner />}
    </div>
  )
}

type CancelingPlanBannerProps = {
  sub: NonNullable<AccountState['sub']>
}

function CancelingPlanBanner(props: CancelingPlanBannerProps) {
  const {sub} = props
  return (
    <PromoBanner
      description={
        <p>
          Your current subscription is still active and will cancel on{' '}
          <span className="font-bold">{sub.current_period_end}</span>. After
          that date you'll be eligible for{' '}
          <span className="font-bold text-primary">20% off</span> your next
          upgrade.
        </p>
      }
      title="20% off your next project"
    />
  )
}

function RedeemablePromoBanner() {
  const [isModalOpen, modal] = useDisclosure(false)
  return (
    <>
      <PromoBanner
        action={
          <Button primary onClick={() => modal.open()}>
            Redeem offer
          </Button>
        }
        description={
          <div>
            <p>
              Enjoy <span className="font-bold text-primary">20% off</span> when
              you upgrade your subscription!
            </p>
            <p>
              This offer applies to one year of monthly or yearly subscriptions
              on the Personal, Professional or Business plans.
            </p>
          </div>
        }
        title="20% off your next project"
      />

      <StripeElementsProvider>
        <UpgradeModal isOpen={isModalOpen} onClose={modal.close} />
      </StripeElementsProvider>
    </>
  )
}

type UpgradeModalProps = {
  isOpen: boolean
  onClose: () => void
}

function UpgradeModal(props: UpgradeModalProps) {
  const {isOpen, onClose} = props
  const changePlan = useChangePlan()

  async function handleConfirmUpgrade(
    plan: Plan,
    paymentMethod?: PaymentMethod,
  ): Promise<void> {
    await changePlan(plan, paymentMethod)
    onClose()
  }

  return (
    <UpgradeSubscriptionModal
      options={['personal', 'professional', 'business']}
      isOpen={isOpen}
      onClose={onClose}
      onConfirmUpgrade={handleConfirmUpgrade}
    />
  )
}
