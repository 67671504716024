/** @format */

import React from 'react'

type DiscountProps = {
  children: string
}

export function Discount(props: DiscountProps) {
  const {children} = props
  return (
    <p>
      <span className="text-sm text-gray-700">Discount applied: </span>
      <span className="rounded bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
        {children}
      </span>
    </p>
  )
}
