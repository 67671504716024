/** @format */

import {useMemo} from 'react'
import {useMatch} from 'react-router-dom'

import type {Form} from '@src/types/Form'

import {useAllFormsContext} from './AllFormsContext'

/**
 * `useCurrentForm` returns the current form from `useAllFormsContext`
 * based on the URL's `hashid` parameter.
 *
 * @returns {Form | undefined} The form object corresponding to
 * the current `hashid`, or `undefined` if no match is found.
 */
export function useCurrentForm(): Form | undefined {
  const {formByHashId} = useAllFormsContext()
  const match = useMatch('/forms/:hashid/*')
  const hashid = match?.params.hashid
  return useMemo(
    () => (hashid ? formByHashId.get(hashid) : undefined),
    [formByHashId, hashid],
  )
}
