/** @format */

import {MailOpenIcon} from '@heroicons/react/outline'
import React, {useContext} from 'react'

import LoaderButton from '@src/components/tailwind/LoaderButton'
import Modal from '@src/components/tailwind/Modal'
import {ContextModal, ModalContext} from '@src/components/tailwind/ModalContext'

import {AccountContext} from '@src/contexts/AccountContext'

import {ACCOUNT_MODAL_NAMES} from '../Account'
import weeklyEmailDigestScreenshot0 from './weekly-email-digest-ss-0.png'
import weeklyEmailDigestScreenshot1 from './weekly-email-digest-ss-1.png'

export function WeeklyEmailDigestModal() {
  const {profile, patchAccount, reloadAccount} = useContext(AccountContext)
  const {closeCurrentModal} = useContext(ModalContext)

  return (
    <ContextModal title="" modal={ACCOUNT_MODAL_NAMES.WEEKLY_DIGEST_MODAL}>
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
        <MailOpenIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
      </div>
      <div className="mt-3 sm:mt-5">
        <h3 className="text-center text-lg font-medium leading-6 text-gray-900">
          Weekly Email Digest
        </h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Receive a weekly summary email of form activity including total
            submission and spam counts, popular forms, and spam messages
            filtered.
          </p>
          <div className="flex flex-col items-center">
            <img
              className="w-3/4"
              src={weeklyEmailDigestScreenshot0}
              alt="Weekly Email Digest Ham and Spam"
            />
            <img
              className="w-3/4"
              src={weeklyEmailDigestScreenshot1}
              alt="Weekly Email Digest Forms and Spams"
            />
          </div>
        </div>
      </div>
      <Modal.Footer>
        <LoaderButton
          onClick={() => {
            patchAccount({
              payload: {
                weekly_email_digest_disabled:
                  !profile.flags.weekly_email_digest_disabled,
              },
              profile: true,
            }).then(() => {
              closeCurrentModal()
              reloadAccount()
            })
          }}
          className="buttonPrimary w-full"
        >
          {profile.flags.weekly_email_digest_disabled || false
            ? 'Enable'
            : 'Disable'}{' '}
          Weekly Digest
        </LoaderButton>
      </Modal.Footer>
    </ContextModal>
  )
}
