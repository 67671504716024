/** @format */

import {RadioGroup, Switch} from '@headlessui/react'
import {CheckIcon} from '@heroicons/react/solid'
import clsx from 'clsx'
import React, {useState} from 'react'

import {Plan, Product, useProductsApi} from '@src/contexts'

type Props = {
  products: Product[]
  selectedOptionIndex: number
  setSelectedOptionIndex: (idx: number) => void
  yearly: boolean
  setYearly: (yearly: boolean) => void
}

type State = Plan // the selected plan

export function SelectPlan(props: Props) {
  const {
    products,
    selectedOptionIndex,
    setSelectedOptionIndex,
    yearly,
    setYearly,
  } = props

  return (
    <>
      <p className="text-sm">
        See details and compare plans on the{' '}
        <a className="primaryLink" href="/plans">
          Plans page
        </a>
        .
      </p>
      <RadioGroup
        className="grid grid-cols-2 gap-4"
        value={selectedOptionIndex}
        onChange={setSelectedOptionIndex}
      >
        {products.map((product, idx) => (
          <PlanCardOption
            key={product.id}
            product={product}
            value={idx}
            yearly={yearly}
          />
        ))}
      </RadioGroup>
      <AnnualBillingSwitch checked={yearly} onChange={setYearly} />
    </>
  )
}

export function useSelectPlan(options: Product['id'][]): {
  props: Props
  state: State
} {
  const productsApi = useProductsApi()
  const products = options.map(productsApi.getProductById)

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0)
  const [yearly, setYearly] = useState(true)

  const selectedProduct = products[selectedOptionIndex]
  const selectedPlan = yearly ? selectedProduct.yearly : selectedProduct.monthly

  const props = {
    products,
    selectedOptionIndex,
    setSelectedOptionIndex,
    yearly,
    setYearly,
  }

  const state = selectedPlan

  return {props, state}
}

type PlanCardOptionProps = {
  product: Product
  value: number
  yearly: boolean
}

function PlanCardOption(props: PlanCardOptionProps) {
  const {product, value, yearly} = props
  const plan = yearly ? product.yearly : product.monthly
  const pricePerMonth = yearly ? Math.round(plan.price / 12) : plan.price

  return (
    <RadioGroup.Option className="focus:outline-none" value={value}>
      {({checked}) => (
        <article
          className={clsx(
            'grid rounded-lg border border-solid p-4',
            checked
              ? 'border-secondary bg-secondary'
              : 'cursor-pointer border-gray-300 bg-white',
          )}
        >
          <header
            className={clsx(
              'flex gap-2.5',
              checked ? 'text-white' : 'text-gray-900',
            )}
          >
            <h1 className="font-bold">{product.name}</h1>
            {checked && (
              <CheckIcon className="ml-auto h-6 w-6" aria-hidden="true" />
            )}
          </header>
          <p className="flex gap-2.5">
            <span className={checked ? 'text-white' : 'text-gray-900'}>
              {currencyCompact.format(pricePerMonth)}/mo
            </span>
          </p>
        </article>
      )}
    </RadioGroup.Option>
  )
}

const currencyCompact = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

type AnnualBillingSwitchProps = {
  checked: boolean
  onChange: (checked: boolean) => void
}

function AnnualBillingSwitch(props: AnnualBillingSwitchProps) {
  const {checked, onChange} = props
  return (
    <Switch.Group as="div" className="flex items-center gap-2">
      <Switch
        checked={checked}
        onChange={onChange}
        className={clsx(
          'focus-visible:ring-light-blue-500 relative inline-flex h-5 w-9 shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2',
          checked ? 'bg-primary' : 'bg-gray-200',
        )}
      >
        <span className="sr-only">Choose annual billing</span>
        <span
          aria-hidden
          className={clsx(
            checked ? 'translate-x-4' : 'translate-x-0',
            'inline-block h-4 w-4 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </Switch>
      <Switch.Label className="text-sm text-gray-700">
        <span className="font-semibold">Save an additional 20%</span>
        <span> with annual billing</span>
      </Switch.Label>
    </Switch.Group>
  )
}
