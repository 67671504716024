/** @format */

import * as toast from '../toast'
import ajax from '../ajax'

const isValidEmail = require('is-valid-email')

export async function addEmailAddress(address, {onSuccess = () => {}} = {}) {
  if (!isValidEmail(address)) {
    toast.warning(`"${address}" is not a valid email address.`)
    return new Promise(r => r())
  }

  await ajax({
    method: 'POST',
    endpoint: '/api-int/account/emails',
    payload: {address},
    errorMsg: 'Failed add email to your account',
    successMsg: r => r.message,
    onSuccess: async r => {
      if (!r.noop) {
        await onSuccess(r)
      }
    }
  })
}

export async function addDomain(domain, params) {
  await ajax({
    method: 'POST',
    endpoint: '/api-int/account/domains',
    payload: {domain},
    errorMsg: 'Failed add domain to your account',
    successMsg: r => r.message,
    onSuccess: async r => {
      if (!r.noop) {
        await params.onSuccess(r)
      }
    }
  })
}


export function getPermissions(memberships) {
  /**
   * Returns the permissions of the current membership
   */
  let membership = memberships?.find((m) => m.current === true);

  if (membership) {
    return membership.permissions;
  }
  return {};
}
