/** @format */

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/solid'
import clsx from 'clsx'
import React from 'react'

export type Props = {
  className?: string
  type: 'error' | 'ok' | 'warning'
}

export function StatusIndicator(props: Props) {
  const {className, type} = props
  switch (type) {
    case 'error':
      return <XCircleIcon className={clsx('text-red-600', className)} />
    case 'ok':
      return <CheckCircleIcon className={clsx('text-green-600', className)} />
    case 'warning':
      return (
        <ExclamationCircleIcon className={clsx('text-yellow-500', className)} />
      )
    default:
      const t: never = type
      throw new Error(`Unhandled status type: ${t}`)
  }
}
