/** @format */

import {InformationCircleIcon} from '@heroicons/react/outline'
import * as Progress from '@radix-ui/react-progress'
import React, {type ReactNode} from 'react'

import ComponentGroup from '@src/components/tailwind/ComponentGroup'
import * as Tooltip from '@src/components/tailwind/Tooltip'

import {GIGABYTE} from '@src/constants'
import {useAccountContext} from '@src/contexts'
import {toPercentage} from '@src/lib'

export function Usage() {
  const {account} = useAccountContext()
  return (
    <ComponentGroup fullBleed title="Usage">
      <div className="grid divide-y divide-gray-100 lg:grid-cols-2 lg:divide-y-0">
        <div className="px-8 py-4">
          <MonthlySubmissionsUsage />
        </div>
        {
          // Only show if File Upload is available on the current plan.
          account.limits.file_uploads.limit > 0 && (
            <div className="px-8 py-4">
              <StorageUsage />
            </div>
          )
        }
      </div>
    </ComponentGroup>
  )
}

function MonthlySubmissionsUsage() {
  const {account} = useAccountContext()
  const {infinite, limit} = account.limits.account_monthly_submissions
  return (
    <UsageStats
      formatQuataUsedMessage={percentage =>
        `${percentage} of monthly submissions quota used`
      }
      limit={infinite ? Number.POSITIVE_INFINITY : limit}
      title="Monthly Submissions"
      value={account.monthly_submissions}
    />
  )
}

function StorageUsage() {
  const {account} = useAccountContext()
  const {infinite, limit} = account.limits.file_uploads
  return (
    <UsageStats
      formatQuataUsedMessage={percentage =>
        `${percentage} of file upload quota used`
      }
      formatValue={formatStorageGB}
      limit={infinite ? Number.POSITIVE_INFINITY : limit}
      title="Storage Used"
      tooltip={
        <p>
          <span>
            Storage limit applies to files uploaded with submissions.{' '}
          </span>
          <a
            className="primaryLink whitespace-nowrap"
            href="https://help.formspree.io/hc/en-us/articles/115008380088-File-uploads"
          >
            Learn more
          </a>
        </p>
      }
      value={account.file_upload_storage_used / GIGABYTE}
    />
  )
}

type UsageStatsProps = {
  formatQuataUsedMessage?: (percentage: string) => string
  formatValue?: (value: number) => string
  limit: number
  title: string
  tooltip?: ReactNode
  value: number
}

function UsageStats(props: UsageStatsProps) {
  const {
    formatQuataUsedMessage = (percentage: string): string =>
      `${percentage} of the quata used.`,
    formatValue = defaultFormatValue,
    limit,
    title,
    tooltip,
    value: used,
  } = props
  return (
    <dl className="flex flex-col gap-y-2">
      <dt>
        {tooltip ? (
          <Tooltip.Root>
            <Tooltip.Trigger className="flex cursor-pointer items-center gap-x-1 rounded transition-colors hover:text-gray-700 focus:outline-none focus-visible:ring-2">
              <span className="font-bold">{title}</span>
              <InformationCircleIcon className="h-4 w-4" />
            </Tooltip.Trigger>
            <Tooltip.Content>{tooltip}</Tooltip.Content>
          </Tooltip.Root>
        ) : (
          <span className="font-bold">{title}</span>
        )}
      </dt>
      <dd className="flex items-baseline gap-x-2">
        <span className="text-2xl font-medium tracking-tight text-gray-900">
          {formatValue(used)}
        </span>
        {limit != null && (
          <span className="text-sm text-gray-500">
            / {Number.isFinite(limit) ? formatValue(limit) : '∞'}
          </span>
        )}
      </dd>
      {limit != null && Number.isFinite(limit) && (
        <dd>
          <Progress.Root
            className="h-2 overflow-hidden rounded-full bg-gray-200"
            getValueLabel={formatValue}
            max={limit}
            value={used}
          >
            <Progress.Indicator
              className="h-full w-full rounded-full bg-primary transition-transform"
              style={{
                transform: `translateX(${toPercentage(
                  Math.min(Math.max(used / limit - 1, -1), 0),
                )})`,
              }}
            />
          </Progress.Root>
          <p className="mt-1 text-xs text-gray-500">
            {formatQuataUsedMessage(toPercentage(used / limit))}
          </p>
        </dd>
      )}
    </dl>
  )
}

function defaultFormatValue(value: number): string {
  // passing `undefined` will use the host default language
  return value.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  })
}

function formatStorageGB(value: number): string {
  return `${defaultFormatValue(value)}GB`
}
