/** @format */

import React, {createContext, PropsWithChildren, useContext} from 'react'

import {RoutingRule} from '@src/types/Form'
import {PluginKind} from '@src/types/Plugin'

export interface FormspreeContextType {
  SERVICE_NAME?: string
  SERVICE_URL?: string
  STRIPE_PUBLISHABLE_KEY?: string
  AMPLITUDE_KEY?: string
  CANCEL_SUBSCRIPTPION_SURVEY_FORM_ID?: string
  CONTACT_EMAIL?: string
  S3_URL?: string
  FILE_UPLOADS_BUCKET_URL?: string
  EMAIL_DOMAIN?: string
  ONBOARDING_SURVEY_FORM_ID?: string
  SENTRY_DSN_REACT?: string
  SENTRY_SAMPLE_RATE_REACT?: string
  REQUEST_PLUGIN_URL?: string
  RECAPTCHA_V2_INVISIBLE_KEY?: string
  RECAPTCHA_ON_PLANS_PAGE_REGISTER?: string
  SUBMISSION_QUERY_LIMIT?: number
  TELEGRAM_BOT_NAME?: string
  WORKFLOW_REVERT_FORM_HASHID?: string
  ENABLE_EMAIL_DIGESTS?: string

  currencies: {
    [code: string]: {decimal: boolean; label: string}
  }

  features: Feature[]
  plugins: {kind: PluginKind; hasTestKey: boolean}[]
  products: Product[]
  rule_fns: RoutingRuleDefinition[]
  timezones: string[]
  webhook_protocols: {name: string; display: string; default: boolean}[]

  // TODO: Add other formspree context props as needed
  // "countries": [
  //     cr.split("/")[-1].split(".")[0].upper()
  //     for cr in glob.glob("formspree/static/img/countries/*.png")
  // ],
  // "billing_countries": BILLING_COUNTRIES,
  // "coupons": {},
}

export type Feature = {
  description: string
  featured?: boolean
  group: string
  // from feature_defs[].id in formspree/plans.py
  id: FeatureId
  limit?: 'Unlimited' | number
  name: string
  shortname?: string
  shortunits?: string
  units?: string
}

export type FeatureId =
  | 'account_monthly_submissions'
  | 'admin'
  | 'ajax'
  | 'api_access'
  | 'autoresponses'
  | 'custom_domains'
  | 'custom_emails'
  | 'custom_honeypot'
  | 'dashboard'
  | 'domain_whitelist'
  | 'export'
  | 'file_uploads'
  | 'forms'
  | 'linked_addresses'
  | 'multiple_to_addresses'
  | 'onboarding'
  | 'own_recaptcha'
  | 'phone_support'
  | 'plugins'
  | 'premium_plugins'
  | 'priority_support'
  | 'projects'
  | 'redshift'
  | 'retention'
  | 'search_submissions'
  | 'sla'
  | 'spam_rules'
  | 'spamfilter'
  | 'submission_routing'
  | 'team_members'
  | 'thankyou'
  | 'trusted'
  | 'whitelabel'
  // custom features
  | 'charts'
  | 'support'
  | 'workflow'

export type Product = {
  available: boolean
  description: string
  id: ProductId
  monthly: Plan
  name: string
  yearly: Plan
}

// keep in sync with Product in formspree/plans.py
export type ProductId =
  | 'free'
  | 'personal'
  | 'teams_trial'
  | 'professional'
  | 'business'
  | 'gold'
  | 'platinum'
  | 'custom'

export type Plan = {
  features: Feature[]
  limits: Record<string, number>
  price: number
  product: ProductId
  stripe_plan: PlanId
}

// exclude deprecated
type PlanId =
  | 'v2_free'
  | 'v0_personal'
  | 'v0_personal_yearly'
  | 'v0_professional'
  | 'v0_professional_yearly'
  | 'v0_business'
  | 'v0_business_yearly'
  | 'v0_teams_trial'

export type RoutingRuleDefinition = {
  doc: string
  name: RoutingRule['trigger']['fn']
  params: string[]
  uses_field: boolean
}

const EMPTY_CONTEXT = {
  plugins: [],
}

export const FormspreeContext = createContext<FormspreeContextType>({
  ...EMPTY_CONTEXT,
  ...(window as any)?.formspree,
})

export const FormspreeContextProvider = ({children}: PropsWithChildren) => {
  return (
    <FormspreeContext.Provider
      value={{
        ...EMPTY_CONTEXT,
        ...(window as any)?.formspree,
      }}
    >
      {children}
    </FormspreeContext.Provider>
  )
}

export function useFormspreeContext(): FormspreeContextType {
  return useContext(FormspreeContext)
}
