/** @format */

import {UserCircleIcon} from '@heroicons/react/solid'
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import {Link} from 'react-router-dom'

import {Loading} from '@src/components/tailwind/Loading'

import ajax from '../../ajax'
import Badge from '../../components/tailwind/Badge'
import ComponentContainer from '../../components/tailwind/ComponentContainer'
import {
  ContextModal,
  ModalContext,
} from '../../components/tailwind/ModalContext'
import Table from '../../components/tailwind/Table'
import {AccountContext} from '../../contexts/AccountContext'
import {LoadingContext} from '../../contexts/LoadingContext'
import {capitalize} from '../../utils'
import AddMemberModal from './AddMemberModal'
import {ConvertToTeam} from './ConvertToTeam'
import RemoveMemberModal from './RemoveMemberModal'
import UpgradeToTeam from './UpgradeToTeam'

const ADD_MEMBER_MODAL = 'ADD_MEMBER'
const REMOVE_MEMBER_MODAL = 'REMOVE_MEMBER'
const UPGRADE_TO_TEAM_MODAL = 'UPGRADE_TO_TEAM'

export const MembershipContext = createContext(null)

export default function Team() {
  const {openModal, closeCurrentModal} = useContext(ModalContext)
  const {ready} = useContext(LoadingContext)
  const {account} = useContext(AccountContext)
  const [membershipToRemove, setMembershipToRemove] = useState(null)

  const membershipReducer = (state, action) => {
    switch (action.type) {
      case 'add':
        return state.concat([action.payload])
      case 'set':
        return action.payload
      case 'remove':
        return state.filter(
          membership => membership.membership_id !== action.payload,
        )
      default:
        throw new Error()
    }
  }

  let [memberships, dispatchMemberships] = useReducer(membershipReducer, null)

  const loadTeamMembers = async () => {
    await ajax({
      method: 'GET',
      endpoint: `/api-int/team/members`,
      onSuccess: members => {
        dispatchMemberships({type: 'set', payload: members})
      },
      errorMsg: 'Error fetching team members',
    })
  }

  const openRemoveModal = membership_id => {
    // Find membership
    let member = memberships.find(
      membership => membership.membership_id === membership_id,
    )
    setMembershipToRemove(member)
    openModal(REMOVE_MEMBER_MODAL)
  }

  async function removeMember() {
    if (membershipToRemove) {
      await ajax({
        method: 'DELETE',
        endpoint: `/api-int/team/memberships/${membershipToRemove.membership_id}`,
        onSuccess: async () => {
          await dispatchMemberships({
            type: 'remove',
            payload: membershipToRemove.membership_id,
          })
          // special case to remove the overlimit banner due to react portals not re-rendering
          if (memberships.length - 1 === account.limits.team_members.limit) {
            location.reload()
          } else {
            closeCurrentModal()
            setMembershipToRemove(null)
            ready()
          }
        },
        onError: () => {
          ready()
        },
        successMsg: `Successfully removed member`,
        errorMsg: 'Failed to delete team member',
      })
    }
  }

  useEffect(() => {
    loadTeamMembers()
  }, [])

  if (account.kind === 'user') {
    return (
      <ComponentContainer fullBleed={true}>
        <UpgradeToTeam />
        <ConvertToTeam
          onConvertButtonClick={() => openModal(UPGRADE_TO_TEAM_MODAL)}
        />
      </ComponentContainer>
    )
  }

  return (
    <>
      <MembershipContext.Provider value={{memberships, dispatchMemberships}}>
        <ComponentContainer
          title="Members"
          fullBleed={true}
          action={
            <a
              className="primaryLink cursor-pointer hover:no-underline"
              disabled={
                account.member_count >= account.limits.team_members.limit
              }
              onClick={() => openModal(ADD_MEMBER_MODAL)}
            >
              + Add Member
            </a>
          }
        >
          <ContextModal modal={ADD_MEMBER_MODAL} title="Add Member">
            <AddMemberModal />
          </ContextModal>
          {memberships ? (
            <>
              <PeopleList
                members={memberships}
                openRemoveModal={openRemoveModal}
              />
              <div className="border-t border-solid border-gray-100 px-8 py-6 text-sm text-gray-500">
                <span className="font-semibold">{memberships.length}</span> of{' '}
                <span className="font-semibold">
                  {account.limits.team_members.infinite
                    ? '∞'
                    : account.limits.team_members.limit}
                </span>
                <span> members added</span>
                {account.limits.team_members
                  .infinite ? null : memberships.length >
                  account.limits.team_members.limit ? (
                  <>
                    <span>. To restore access, please </span>
                    <Link to="/plans" className="primaryLink">
                      upgrade your plan
                    </Link>
                    <span> or remove team members.</span>
                  </>
                ) : (
                  <>
                    <span>. </span>
                    <Link to="/plans" className="primaryLink">
                      Upgrade your plan
                    </Link>
                    <span> to increase limit.</span>
                  </>
                )}
              </div>
            </>
          ) : (
            <Loading />
          )}
          {membershipToRemove && (
            <ContextModal modal={REMOVE_MEMBER_MODAL} title="Confirm Removal">
              <RemoveMemberModal
                member={membershipToRemove}
                removeMember={removeMember}
              />
            </ContextModal>
          )}
        </ComponentContainer>
      </MembershipContext.Provider>
    </>
  )
}

function PeopleList(props) {
  const {profile} = useContext(AccountContext)
  return (
    <Table
      columns={[
        'Name',
        'Status',
        'Role',
        <span className="sr-only">Edit</span>,
      ]}
      rows={props.members.map(membership => [
        <div className={'flex items-center'}>
          {[membership.member.first_name, membership.member.last_name].some(
            name => Boolean(name),
          ) ? (
            <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
              <span className="text-sm font-medium uppercase leading-none text-white">
                {[membership.member.first_name, membership.member.last_name]
                  .map(name => (name ? name.charAt(0) : ''))
                  .join('')}
              </span>
              {/* Uncomment to add profile images */}
              {/*<div className="shrink-0 h-10 w-10">*/}
              {/*  <img className="h-10 w-10 rounded-full" src={member.image} alt="" />*/}
              {/*</div>*/}
            </span>
          ) : (
            <UserCircleIcon className="-m-1 h-10 w-10 rounded-full bg-white text-gray-500" />
          )}
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {[membership.member.first_name, membership.member.last_name].join(
                ' ',
              )}
            </div>
            <div className="text-sm text-gray-500">
              {membership.member.email}
            </div>
          </div>
        </div>,
        <Badge
          status={
            membership.status === 'active'
              ? 'ok'
              : membership.status === 'pending'
                ? 'warning'
                : 'error'
          }
        >
          {capitalize(membership.status)}
        </Badge>,
        membership.role,
        membership.member.id !== profile.id && (
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              props.openRemoveModal(membership.membership_id)
            }}
            className="text-primary hover:text-indigo-900"
          >
            Remove
          </a>
        ),
      ])}
    />
  )
}
