/** @format */

import React, {useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import ActionInput from '@src/components/tailwind/ActionInput'

import ajax from '../../ajax'
import Input from '../../components/tailwind/Input'
import LoaderButton from '../../components/tailwind/LoaderButton'
import {
  ContextModal,
  ModalContext,
} from '../../components/tailwind/ModalContext'
import {AccountContext} from '../../contexts/AccountContext'
import {LoadingContext} from '../../contexts/LoadingContext'

export default function UpgradeToTeam(props) {
  const {closeCurrentModal} = useContext(ModalContext)
  const {ready} = useContext(LoadingContext)
  const {account, reloadAccount} = useContext(AccountContext)
  const [teamName, setTeamName] = useState('')
  const navigate = useNavigate()

  async function handleCreate(e) {
    e.preventDefault()
    await ajax({
      method: 'POST',
      endpoint: `/api-int/teams/convert`,
      payload: {
        team_name: teamName,
      },
      onSuccess: () => {
        reloadAccount()
        closeCurrentModal()
        navigate('/account/team')
      },
      successMsg: `Successfully team created`,
      errorMsg: 'Failed to create team',
    })
    ready()
  }

  return (
    <>
      <ContextModal
        modal="UPGRADE_TO_TEAM"
        className="narrow"
        title="Convert to Team Account"
      >
        <div className="grid grid-cols-1 gap-4 text-sm text-gray-500">
          {['free'].includes(account.product) ? (
            <p>
              Inviting collaborators requires a team account on the{' '}
              <Link to={'/plans'} className={'primaryLink'}>
                Professional plan
              </Link>{' '}
              or higher. Upgrading will start a 14 day trial to access teams
              features.
            </p>
          ) : null}
          <p>
            The current account will be converted to a team account. The team
            account will have access to all existing projects, forms, and
            billing details.
          </p>
        </div>
        <ActionInput
          label="Team Name"
          value={teamName}
          onChange={e => setTeamName(e.target.value)}
        />
        <div className="flex justify-end">
          <LoaderButton disabled={!teamName.trim()} onClick={handleCreate}>
            Convert to Team
          </LoaderButton>
        </div>
      </ContextModal>
    </>
  )
}
