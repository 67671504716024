/** @format */

import React, {useContext, useState} from 'react'

import LoaderButton from '@src/components/tailwind/LoaderButton'

import ActionInput from '../../components/tailwind/ActionInput'
import Modal from '../../components/tailwind/Modal'
import {
  ContextModal,
  ModalContext,
} from '../../components/tailwind/ModalContext'
import {AccountContext} from '../../contexts/AccountContext'

const NOTHING_PENDING = {
  first_name: undefined,
  last_name: undefined,
}

export default function ChangeNameModal({modal}) {
  const {profile, patchAccount} = useContext(AccountContext)
  const [pending, setPending] = useState(NOTHING_PENDING)
  const {closeCurrentModal} = useContext(ModalContext)

  return (
    <ContextModal modal={modal} title={'Update Account Full Name'}>
      <ActionInput
        label="First Name"
        type="text"
        name="first_name"
        id="first_name"
        value={
          pending.first_name === undefined
            ? profile.first_name
            : pending.first_name
        }
        onChange={e =>
          setPending({
            ...pending,
            first_name: e.target.value,
          })
        }
        placeholder="Emily"
      />
      <ActionInput
        label="Last Name"
        type="text"
        name="last_name"
        id="last_name"
        value={
          pending.last_name === undefined
            ? profile.last_name
            : pending.last_name
        }
        onChange={e =>
          setPending({
            ...pending,
            last_name: e.target.value,
          })
        }
        placeholder="Smith"
      />
      <Modal.Footer>
        <LoaderButton
          className="buttonPrimary float-right"
          onClick={() => {
            // Only send an update to the server if the attributes have changed
            const sendUpdateToServer = Object.keys(pending).some(
              a => pending[a] !== undefined && pending[a] !== profile[a],
            )
            if (sendUpdateToServer) {
              patchAccount({
                payload: {
                  first_name: pending.first_name || profile.first_name,
                  last_name: pending.last_name || profile.last_name,
                },
                profile: true,
                onSuccess: () => {
                  closeCurrentModal()
                  setPending(NOTHING_PENDING)
                },
              })
            }
          }}
          disabled={
            (!pending.first_name ||
              pending.first_name === profile.first_name) &&
            (!pending.last_name || pending.last_name === profile.last_name)
          }
        >
          Save
        </LoaderButton>
      </Modal.Footer>
    </ContextModal>
  )
}
