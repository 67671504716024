/** @format */

import React, {useMemo} from 'react'

import ajax from '@src/ajax'
import {useAccountContext} from '@src/contexts'

export const ProjectsContext = React.createContext({})

export const ProjectsContextProvider = props => {
  const {projects, updateAccount} = useAccountContext()
  const byId = new Map(projects.map(p => [p.id, p]))

  function getProjectById(pid) {
    return byId.get(pid)
  }

  const dashboardProjects = useMemo(
    () => projects.filter(p => p.kind === 'dashboard'),
    [projects],
  )

  const reloadSpecificProject = async id => {
    await ajax({
      method: 'GET',
      endpoint: `/api-int/projects/${id}`,
      onSuccess: project => {
        updateAccount(acct => {
          for (let i = 0; i < acct.projects.length; i++) {
            if (acct.projects[i].id === project.id) {
              acct.projects[i] = project
              return {...acct}
            }
          }

          // if project wasn't in the list (is new), add it to the top
          acct.projects.unshift(project)
          return {...acct}
        })
      },
      errorMsg: `Error loading project '${id}'`,
    })
  }

  /**
   * Modify the projects array on AccountState.
   *
   * If a project matching the given pid exists in the array, it will be replaced
   * with the new project passed as an argument. If no matching project exists,
   * the new project will be added to the array.
   *
   * If the project argument is omitted, the project with the given pid will be
   * removed from the array.
   *
   * @param {string} pid - The id of the project to modify or remove.
   * @param {object} [project] - The project to add or replace. Omit to remove the project.
   */
  const updateProjectState = (pid, project) => {
    updateAccount(acct => {
      let projects
      if (project) {
        if (acct.projects.some(p => p.id === pid)) {
          // update
          projects = acct.projects.map(p => (p.id === pid ? project : p))
        } else {
          // add
          projects = [project, ...acct.projects]
        }
      } else {
        // delete
        projects = acct.projects.filter(p => p.id !== pid)
      }
      return {...acct, projects}
    })
  }

  return (
    <ProjectsContext.Provider
      value={{
        dashboardProjects,
        projects,
        getProjectById,
        reloadSpecificProject,
        updateProjectState,
      }}
    >
      {props.children}
    </ProjectsContext.Provider>
  )
}
