/** @format */

import React, {PropsWithChildren} from 'react'

import stripeLogoSVG from '@src/images/stripe-logo-purple.svg'

export function PaymentSection(props: PropsWithChildren) {
  const {children} = props
  return (
    <div className="grid gap-4">
      {children}
      <p className="flex justify-end gap-1 text-sm text-gray-700">
        <span>Payment handled by</span>
        <img
          className="-mb-0.5 inline-flex"
          src={stripeLogoSVG}
          alt="Stripe"
          width="38"
          height="16"
        />
      </p>
      <p className="text-sm text-gray-400">
        I authorize Formspree to send instructions to the financial institution
        that issued my card to take payments from my card account in accordance
        with the terms of my agreement with you.
      </p>
    </div>
  )
}
