/** @format */

import React, {ComponentPropsWithoutRef} from 'react'

import ComponentGroup from './ComponentGroup'

const ComponentContainer: React.FC<
  {
    fullBleed?: boolean
  } & ComponentPropsWithoutRef<typeof ComponentGroup>
> = ({fullBleed, children, ...rest}) => {
  return (
    <ComponentGroup fullBleed={true} {...rest}>
      <div className={fullBleed ? '' : 'mx-8 my-6'}>{children}</div>
    </ComponentGroup>
  )
}
export default ComponentContainer
