/** @format */

import {CHECKBOX, INFINITY} from './constants'
import React from 'react'

export function isPlanInProduct(plan_id, prod) {
  return (
    -1 !==
    [prod.monthly, prod.yearly]
      .filter(x => x)
      .map(x => x.stripe_plan)
      .indexOf(plan_id)
  )
}

export function getFeatureValueForProduct(featureName, product) {
  const i = product.monthly.features.map(f => f.name).indexOf(featureName)
  const feature = i >= 0 ? product.monthly.features[i] : null
  const limit =
    feature && feature.limit !== undefined
      ? feature.limit === 'Unlimited'
        ? INFINITY
        : feature.limit !== 0
        ? feature.limit.toLocaleString() + (feature.units || '')
        : ' '
      : null
  return feature ? limit || CHECKBOX : <>&nbsp;</>
}

export function thousandsWithOneDecimal(num) {
  if (num === 'Unlimited') {
    return INFINITY
  } else if (num > 999) {
    return (Math.round(num / 100) / 10).toString() + 'K'
  } else {
    return num
  }
}

function scrollTo(selector, offset = 0) {
  if (window.matchMedia('(max-width: 768px)').matches) {
    return
  }
  const el = document.querySelector(selector)
  const top = el.getBoundingClientRect().top + window.pageYOffset
  window.scroll({
    top: top + offset,
    left: 0,
    behavior: 'smooth'
  })
}
