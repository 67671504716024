/** @format */

import clsx from 'clsx'
import React, {type PropsWithChildren} from 'react'

type Props = PropsWithChildren<{
  className?: string
  type?: 'danger' | 'info' | 'secondary' | 'success' | 'warning'
}>

export function Alert(props: Props) {
  const {children, className, type} = props
  return (
    <div
      className={clsx(
        {
          'border-green-400 bg-green-50': type === 'success',
          'border-yellow-400 bg-yellow-50': type === 'warning',
          'border-red-400 bg-red-50': type === 'danger',
          'border-blue-400 bg-blue-50': type === 'info',
          'border-gray-400 bg-gray-50': type === 'secondary' || !type,
          'border-l-4 p-4': true,
        },
        className,
      )}
    >
      <div className="flex">{children}</div>
    </div>
  )
}
