/** @format */
import React, {useContext, useState} from 'react'
import {AccountContext} from '../../contexts/AccountContext'
import {
  ModalContext,
  ContextModal
} from '../../components/tailwind/ModalContext'
import {LoadingContext} from '../../contexts/LoadingContext'
import Modal from '../../components/tailwind/Modal'
import LoaderButton from '../../components/tailwind/LoaderButton'
import ActionInput from '../../components/tailwind/ActionInput'

export default function ChangeEmailModal({modal}) {
  const {ready} = useContext(LoadingContext)
  const {account, patchAccount} = useContext(AccountContext)
  const {closeCurrentModal} = useContext(ModalContext)
  const [pending, setPending] = useState()
  return (
    <ContextModal modal={modal} title={'Update Team Name'}>
      <ActionInput
        label="Team Name"
        value={pending !== undefined ? pending : account.team_name}
        onChange={e => {
          setPending(e.target.value)
        }}
      />
      <Modal.Footer>
        <LoaderButton
          onClick={() => {
            patchAccount({
              payload: {
                team_name: pending
              },
              onSuccess: () => {
                closeCurrentModal()
                setPending()
                ready()
              }
            })
          }}
          disabled={!pending || pending === account.team_name}
        >
          Save
        </LoaderButton>
      </Modal.Footer>
    </ContextModal>
  )
}
