/** @format */

import clsx from 'clsx'
import React, {ComponentPropsWithoutRef} from 'react'

type ButtonProps = {
  primary?: boolean
} & ComponentPropsWithoutRef<'button'>

export default function Button({primary, className, ...props}: ButtonProps) {
  return (
    <button
      className={clsx(primary ? 'buttonPrimary' : 'buttonSecondary', className)}
      {...props}
    />
  )
}
