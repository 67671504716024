/** @format */

import {useDisclosure} from '@mantine/hooks'
import React from 'react'
import {Link} from 'react-router-dom'

import Badge from '@src/components/tailwind/Badge'
import Button from '@src/components/tailwind/Button'
import ComponentContainer from '@src/components/tailwind/ComponentContainer'
import LoaderButton from '@src/components/tailwind/LoaderButton'
import {
  ContextModal,
  OpenModalButton,
  useModalContext,
} from '@src/components/tailwind/ModalContext'

import ajax from '@src/ajax'
import {AnalyticsEvent, sendAnalytics} from '@src/analytics'
import {useAccountContext, useLoadingContext} from '@src/contexts'
import {CancelSubscriptionModal} from '@src/dashboard/CancelSubscriptionModalV1'
import {
  featureTrialMinimumProduct,
  getProduct,
  isLegacyPlan,
  isTrialPlan,
} from '@src/utils'

import TrialPaymentForm from './TrialPaymentForm'
import UpgradeAccount from './UpgradeAccount'

const MODAL_START_SUBSCRIPTION = 'MODAL_START_SUBSCRIPTION'

export default function PlanStatus() {
  const {account, card, reloadAccount, sub} = useAccountContext()
  const {ready} = useLoadingContext()
  const {closeCurrentModal} = useModalContext()

  async function cancelTrial() {
    await ajax({
      endpoint: '/api-int/cancel-trial',
      method: 'POST',
      successMsg: 'Successfully cancelled trial',
      onSuccess: async () => {
        await reloadAccount()
      },
      errorMsg: 'Failed to cancel trial',
    })
    ready()
  }

  async function resubscribe() {
    await ajax({
      endpoint: '/api-int/resubscribe',
      onSuccess: async () => {
        closeCurrentModal()
        await reloadAccount()
      },
      errorMsg:
        'An error occurred and we were unable to resubscribe you. Please try ' +
        'again later or contact support for further assistance',
      successMsg: (r: {at: string}) => {
        return (
          'Glad to have you back! ' +
          `Your subscription will now automatically renew on ${r.at}`
        )
      },
    })
    ready()
  }

  if (account.product === 'free') {
    return (
      <ComponentContainer title={"You're on the Free plan"}>
        <UpgradeAccount />
      </ComponentContainer>
    )
  } else {
    let product = getProduct(account.product)
    let canceling = sub && sub.cancel_at_period_end
    let isOnFeatureTrial = isTrialPlan(account.plan)
    let minimumProduct = featureTrialMinimumProduct(account.product)

    return (
      <ComponentContainer title="Plan">
        <div className="mt-4 sm:flex sm:space-x-8">
          <div className="flex-auto">
            <div className="text-lg font-semibold">
              {product.name}
              {isLegacyPlan(account.plan) ? (
                <>
                  {' '}
                  <Badge status={''}>Legacy</Badge>
                </>
              ) : null}
            </div>
            <div className="text-md text-gray-700">
              {sub ? (
                <>
                  {canceling
                    ? 'Subscription ends'
                    : isOnFeatureTrial
                      ? 'Trial ends'
                      : 'Subscription renews'}{' '}
                  {sub.current_period_end}
                </>
              ) : (
                'Unknown billing period'
              )}
            </div>
            <div className="text-sm text-gray-500">
              {sub ? (
                <>
                  {isOnFeatureTrial ? (
                    <>
                      {card ? (
                        <>
                          You will automatically be switched to the{' '}
                          {minimumProduct.name} plan once your trial ends.{' '}
                        </>
                      ) : (
                        <>
                          To continue to access teams, please add a valid
                          payment method before the trial ends.{' '}
                        </>
                      )}
                      You can remove account limitations by starting your
                      subscription before the trial ends.
                    </>
                  ) : canceling ? (
                    'Your account will be downgraded to the Free plan once your subscription ends.'
                  ) : null}
                </>
              ) : (
                'Please contact support for more information about your billing'
              )}
            </div>
          </div>
          <div className="grid flex-none grid-cols-1 gap-4">
            {isOnFeatureTrial ? (
              <>
                <OpenModalButton
                  modal={MODAL_START_SUBSCRIPTION}
                  className="buttonPrimary"
                >
                  Start subscription
                </OpenModalButton>
                <ContextModal
                  modal={MODAL_START_SUBSCRIPTION}
                  title="Start subscription"
                >
                  <TrialPaymentForm />
                </ContextModal>
              </>
            ) : canceling ? (
              <LoaderButton onClick={resubscribe} className="h-12">
                Resume
              </LoaderButton>
            ) : (
              <ChangePlanButton />
            )}
            {!canceling &&
              (isOnFeatureTrial ? (
                <LoaderButton onClick={cancelTrial} secondary>
                  Cancel trial
                </LoaderButton>
              ) : (
                <CancelSubscriptionButton />
              ))}
          </div>
        </div>
      </ComponentContainer>
    )
  }
}

function CancelSubscriptionButton() {
  const [isModalOpen, modal] = useDisclosure(false)
  return (
    <>
      <CancelSubscriptionModal
        isOpen={isModalOpen}
        onClose={() => modal.close()}
      />

      <Button onClick={() => modal.open()} type="button">
        Cancel subscription
      </Button>
    </>
  )
}

function ChangePlanButton() {
  function handleClick() {
    sendAnalytics(AnalyticsEvent.upgrade_clicked, {
      feature: 'general_change_plan',
    })
  }
  return (
    <Link className="buttonPrimary" onClick={handleClick} to="/plans">
      Change plan
    </Link>
  )
}
