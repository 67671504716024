/** @format */

import clsx from 'clsx'
import React, {PropsWithChildren} from 'react'

/** @format */
export default function Badge({
  status = '',
  children,
}: PropsWithChildren<{
  status: string
}>) {
  return (
    <span
      className={clsx({
        'inline-flex rounded px-2 text-xs font-semibold leading-5': true,
        'bg-green-100 text-green-800': status.toLocaleLowerCase() === 'ok',
        'bg-yellow-100 text-yellow-800':
          status.toLocaleLowerCase() === 'warning',
        'bg-red-100 text-red-800': status.toLocaleLowerCase() === 'error',
        'bg-blue-100 text-blue-800': status.toLocaleLowerCase() === 'info',
        'bg-gray-100 text-gray-800': status === '',
      })}
    >
      <span className="uppercase tracking-wide">{children || status}</span>
    </span>
  )
}
