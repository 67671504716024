/** @format */

import React, {
  createContext,
  useCallback,
  useContext,
  type PropsWithChildren,
} from 'react'
import useSWR, {type KeyedMutator} from 'swr'
import useSWRMutation, {SWRMutationConfiguration} from 'swr/mutation'

import {FullPageLoading} from '@src/components/tailwind/Loading'

import {useFetch} from '@src/hooks/useFetch'
import * as toast from '@src/toast'
import type {DisplayConfig, FormDetailed} from '@src/types/Form'

import {useAllFormsContext} from './AllFormsContext'

export type FormApi = {
  current: FormDetailed
  delete: () => Promise<void>
  resetApiKey: () => Promise<void>
  revalidate: KeyedMutator<FormDetailed>
  update: (
    patch: Partial<FormDetailed>,
    options?: SWRMutationConfiguration<void, Error, Partial<FormDetailed>>,
  ) => Promise<void>
  updateDisplayConfig: (displayConfig: DisplayConfig) => Promise<void>
}

const Context = createContext<FormApi | null>(null)

type FormApiProviderProps = PropsWithChildren<{
  hashid: string
}>

export function FormApiProvider(props: FormApiProviderProps) {
  const {children, hashid} = props
  const {reloadAllForms} = useAllFormsContext()
  const fetch = useFetch()
  const {data, mutate} = useSWR(
    `/api-int/forms/${hashid}`,
    async (endpoint): Promise<FormDetailed> => fetch(endpoint, {method: 'GET'}),
    {
      onError() {
        toast.error(`Error loading form '${hashid}'`)
      },
      revalidateOnFocus: false,
    },
  )

  const {trigger: del} = useSWRMutation(
    `/api-int/forms/${hashid}`,
    async endpoint => {
      await fetch(endpoint, {method: 'DELETE'})
      // Sync all forms to keep sidebar, etc. up-to-date
      await reloadAllForms(forms => forms?.filter(f => f.hashid !== hashid), {
        revalidate: false,
      })
    },
    {
      onError() {
        toast.error('Failed to delete form')
      },
      onSuccess() {
        toast.success('Form successfully deleted.')
      },
      revalidate: false,
    },
  )

  const {trigger: resetApiKey} = useSWRMutation(
    `/api-int/forms/${hashid}/reset-apikey`,
    async endpoint => {
      await fetch(endpoint, {method: 'POST'})
      await mutate()
    },
    {
      onError() {
        toast.error('Failed to reset API key')
      },
      onSuccess() {
        toast.success('A new API key was generated and replaced the old one.')
      },
    },
  )

  const {trigger: update} = useSWRMutation(
    `/api-int/forms/${hashid}`,
    async (endpoint, {arg}: {arg: Partial<FormDetailed>}) => {
      const updated: FormDetailed = await fetch(endpoint, {
        method: 'PATCH',
        payload: arg,
      })

      await Promise.all([
        mutate(updated, {revalidate: false}),
        // Sync the updated form to keep details (e.g. form name)
        // in the sidebar (and other places that use AllFormsContext)
        // up-to-date.
        reloadAllForms(
          forms => forms?.map(f => (f.hashid === hashid ? updated : f)),
          {revalidate: false},
        ),
      ])
    },
    {
      onError() {
        toast.error('Failed to save settings')
      },
      onSuccess() {
        toast.success('Form updated.')
      },
    },
  )

  const updateDisplayConfig = useCallback(
    async displayConfig => update({display_config: displayConfig}),
    [update],
  ) satisfies FormApi['updateDisplayConfig']

  if (!data) {
    return <FullPageLoading />
  }

  const api = {
    current: data,
    delete: del,
    resetApiKey,
    revalidate: mutate,
    update,
    updateDisplayConfig,
  } satisfies FormApi

  return <Context.Provider value={api}>{children}</Context.Provider>
}

export function useFormApi(): FormApi {
  const ctx = useContext(Context)
  if (!ctx) {
    throw new Error('useFormApi must be used within FormApiProvider.')
  }
  return ctx
}

export const __testingOnly__ = {
  Context,
}
