/** @format */

import {useMemo} from 'react'

import {useAllFormsContext} from '@src/contexts'
import type {Form} from '@src/types/Form'

export function useDefaultForm(): Form | undefined {
  const {
    forms: [firstForm],
    formByHashId,
  } = useAllFormsContext()

  return useMemo(() => {
    // Some devices (e.g. Chrome Mobile WebView) don't have localStorage enabled
    // so we need to check if localStorage is available first.
    const savedHashId = localStorage?.getItem('currentHashid')
    const saved = savedHashId ? formByHashId.get(savedHashId) : undefined
    return saved ?? firstForm
  }, [firstForm, formByHashId])
}
