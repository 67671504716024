/** @format */

import clsx from 'clsx'
import QRCode from 'qrcode.react'
import React, {useState} from 'react'

import LoaderButton from '@src/components/tailwind/LoaderButton'
import {OtpInput} from '@src/components/tailwind/OtpInput'

export function TwoFactorQRCode(props) {
  const [showSecretKey, setShowSecretKey] = useState(false)
  const [otpVerification, setOtpVerification] = useState('')
  const [isVerifying, setIsVerifying] = useState(false)

  const handleOtpInput = async otp => {
    setOtpVerification(otp)
    if (otp.length === 6) {
      setIsVerifying(true)
      await props.nextStep({otpCode: otp})
      setIsVerifying(false)
    }
  }

  return (
    <>
      <div className="mt-3 sm:mt-5">
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Using your authenticator app, scan the barcode below. Once you've
            scanned the barcode, confirm the code you received.
          </p>
        </div>
        <div className="grid justify-items-center">
          <QRCode value={props.provisioningInfo.provisioning_uri} />
          <p className="text-sm text-gray-500">
            {showSecretKey ? (
              <>
                Enter the secret key{' '}
                <code>{props.provisioningInfo.totp_secret}</code>
              </>
            ) : (
              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                  setShowSecretKey(true)
                }}
              >
                Can't scan the code?
              </a>
            )}
          </p>
        </div>
        <div className="my-2">
          <label htmlFor="otp_code">
            Enter the code from your authenticator app
          </label>
          <OtpInput onChange={handleOtpInput} disabled={isVerifying} />
        </div>
        <LoaderButton
          onClick={() => {
            props.nextStep({otpCode: otpVerification})
          }}
          className={clsx({'buttonPrimary w-full': true, loading: isVerifying})}
          disabled={otpVerification.length !== 6 || isVerifying}
        >
          Verify
        </LoaderButton>
      </div>
    </>
  )
}
