/** @format */
import React, {ReactElement, ReactNode} from 'react'

export function isReactElement(x: any): x is ReactElement {
  return (x as ReactElement).type !== undefined
}

export function getChildrenAsElements(children: ReactNode): ReactElement[] {
  return React.Children.toArray(children)
    .filter(child => child && isReactElement(child))
    .map(child => child as ReactElement)
}

export type PassThroughFC = React.FC<{[x: string]: any}>

export type ValueOf<T extends {}> = T[keyof T]
