/** @format */
import React, {useEffect, useRef} from 'react'
import clsx from 'clsx'

const OTP_LENGTH = 6
export function OtpInput({
  allowedCharacters = '^[0-9]*$',
  onChange,
  disabled = false
}) {
  const inputsRef = useRef([])

  useEffect(() => {
    inputsRef.current[0].focus()
  }, [])

  const updateOnChange = () => {
    const res = inputsRef.current.map(input => input.value).join('')
    onChange(res)
  }

  const handleOnChange = e => {
    if (e.target.value.match(allowedCharacters)) {
      if (e.target.nextElementSibling !== null) {
        e.target.nextElementSibling.focus()
      }
    } else {
      e.target.value = ''
    }
    updateOnChange()
  }

  const handleOnKeyDown = e => {
    const {key} = e
    const target = e.target
    if (key === 'Backspace') {
      if (target.value === '' && target.previousElementSibling !== null) {
        target.previousElementSibling.value = ''
        target.previousElementSibling.focus()
        e.preventDefault()
      } else {
        target.value = ''
      }
      updateOnChange()
    }
  }

  const handleOnFocus = e => {
    e.target.select()
  }

  const handleOnPaste = e => {
    const value = e.clipboardData.getData('Text')
    if (value.match(allowedCharacters)) {
      for (let i = 0; i < OTP_LENGTH && i < value.length; i++) {
        inputsRef.current[i].value = value.charAt(i)
        if (inputsRef.current[i].nextElementSibling !== null) {
          inputsRef.current[i].nextElementSibling.focus()
        }
      }
      updateOnChange()
    }
    e.preventDefault()
  }

  const inputs = []
  for (let i = 0; i < OTP_LENGTH; i++) {
    inputs.push(
      <input
        key={i}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        onFocus={handleOnFocus}
        onPaste={handleOnPaste}
        disabled={disabled}
        type="text"
        ref={el => (inputsRef.current[i] = el)}
        className={clsx({
          'text-uppercase mr-1 w-10 shrink rounded border border-2 p-2 text-center text-xl text-secondary focus:ring-0 sm:mr-4 sm:w-12 sm:text-3xl': true,
          'border-green-400': Boolean(
            inputsRef.current[i] && inputsRef.current[i].value
          ),
          'focus:border-gray-400': !Boolean(
            inputsRef.current[i] && inputsRef.current[i].value
          )
        })}
      />
    )
  }

  return <div className="flex justify-center px-0 py-2 sm:p-8">{inputs}</div>
}
