/** @format */

import {useSubmit} from '@formspree/react'
import React, {useMemo} from 'react'
import {Link} from 'react-router-dom'
import useSWR from 'swr'

import LoaderButton from '@src/components/tailwind/LoaderButton'
import {Loading} from '@src/components/tailwind/Loading'
import {
  ContextModal,
  useModalContext,
} from '@src/components/tailwind/ModalContext'

import ajax from '@src/ajax'
import {
  useAccountContext,
  useFormspreeContext,
  useLoadingContext,
} from '@src/contexts'
import {useFetch} from '@src/hooks/useFetch'
import * as toast from '@src/toast'
import type {FormDetailed, Plugin} from '@src/types/Form'
import type {PluginKind} from '@src/types/Plugin'

import {ACCOUNT_MODAL_NAMES} from './Account'
import screenshot from './workflowshot.png'

const UNSUPPORTED_PLUGINS: PluginKind[] = ['github']

function EnableWorkflowBody() {
  const {closeCurrentModal} = useModalContext()
  const {reloadAccount} = useAccountContext()
  const enableWorkflow = async () => {
    await ajax({
      method: 'POST',
      endpoint: `/api-int/workflow/migrate`,
      errorMsg: 'Failed to enable forms workflow',
      successMsg: 'Workflow enabled successfully',
      onSuccess: () => {
        closeCurrentModal()
        reloadAccount()
      },
    })
  }
  return (
    <>
      <div className="prose mb-8">
        <div className="flex justify-center">
          <img src={screenshot} className="w-3/4" />
        </div>
        <p className="text-sm text-gray-600">
          Take control of your form's server-side{' '}
          <span className="font-bold">Validation</span> and{' '}
          <span className="font-bold">Actions</span>. Workflows replace the
          Plugins tab. You can switch back at any time.
        </p>
      </div>
      <LoaderButton onClick={enableWorkflow} className="w-full">
        Enable
      </LoaderButton>
    </>
  )
}

function DisableActionsBody({
  plugins,
}: {
  plugins: {form: FormDetailed; plugin: Plugin}[]
}) {
  const {closeCurrentModal} = useModalContext()
  return (
    <>
      <div className="prose mb-8">
        <p className="text-sm font-bold">Please remove unsupported actions.</p>
        <p className="text-sm text-gray-600">
          Some of your forms use actions that require the Workflows
          functionality:
        </p>
        <div className="grid grid-cols-5 space-y-0.5 text-sm text-gray-600">
          <span className={'col-span-2 font-bold'}>Form Name</span>
          <span className={'col-span-3 font-bold'}>Action</span>
          {plugins.map(({form, plugin}) => (
            <React.Fragment key={form.hashid + plugin.id}>
              <span className={'col-span-2'}>{form.name}</span>
              <span>
                {plugin.kind.toString().charAt(0).toUpperCase() +
                  plugin.kind.toString().slice(1)}
              </span>
              <Link
                className="primaryLink col-span-2 hover:no-underline focus:outline-none"
                to={`/forms/${form.hashid}/workflow`}
              >
                Go
              </Link>
            </React.Fragment>
          ))}
        </div>
        <p className="text-sm text-gray-600">
          Please remove the above actions before disabling the Workflows
          feature.
        </p>
      </div>
      <div className={'flex w-full justify-end'}>
        <LoaderButton onClick={closeCurrentModal} secondary>
          OK
        </LoaderButton>
      </div>
    </>
  )
}

function DisableWorkflowBody() {
  const {ready} = useLoadingContext()
  const {closeCurrentModal} = useModalContext()
  const {reloadAccount, profile} = useAccountContext()
  const formspree = useFormspreeContext()

  const [reason, setReason] = React.useState('')

  type SubmissionData = {
    message: string
    subject: string
    email?: string
    tags: string
  }

  const submitForm = useSubmit<SubmissionData>(
    formspree.WORKFLOW_REVERT_FORM_HASHID || 'UNDEFINED',
    {
      onError(err) {
        const errors = err.getFormErrors()
        if (errors.length > 0) {
          toast.error(errors[0].message)
        }
        ready()
      },
      onSuccess() {
        ajax({
          method: 'POST',
          endpoint: `/api-int/workflow/revert`,
          errorMsg: 'Failed to revert forms workflow',
          successMsg: 'Workflow feature disabled',
          onSuccess: () => {
            closeCurrentModal()
            reloadAccount()
          },
        })
      },
    },
  )

  const disableWorkflow = () => {
    submitForm({
      message: reason,
      subject: 'A user disabled Workflows',
      email: profile.email,
      tags: 'workflow_revert',
    })
  }

  return (
    <form onSubmit={disableWorkflow} method="POST">
      <div className="prose mb-4">
        <p className="text-sm font-bold">
          Are you sure you want to disable Workflows?
        </p>
        <p className="text-sm text-gray-600">
          This will disable the Workflow feature for all forms. Actions added to
          a workflow will be converted to plugins. You can re-enable Workflows
          at any time.
        </p>
        <p className="text-sm text-gray-600">
          <span className={'font-bold'}>
            Any validation rules you added to your forms will be removed. Only
            validation rules associated with a plugin will remain.
          </span>{' '}
          If you are using the CLI, please re-deploy your forms after disabling
          to restore validation rules from your <code>formspree.json</code>{' '}
          file.
        </p>
      </div>
      <div className="mb-4">
        <label className="mb-2 block text-sm font-bold" htmlFor="reason">
          Reason for disabling Workflows
        </label>
        <textarea
          id="message"
          name="message"
          value={reason}
          onChange={e => setReason(e.target.value)}
          placeholder="Please provide us with feedback or suggestions to improve Workflows."
          required
        />
      </div>
      <LoaderButton className="w-full" disabled={!reason}>
        Disable
      </LoaderButton>
    </form>
  )
}

export function WorkflowMigrationModal() {
  const {isOpen} = useModalContext()

  const fetch = useFetch()
  const {data, isLoading} = useSWR(
    isOpen(ACCOUNT_MODAL_NAMES.WORKFLOW_MIGRATION_MODAL)
      ? '/api-int/forms?detailed=true'
      : null,
    async endpoint => {
      type Response = {
        forms: FormDetailed[]
        ok: true
      }

      const {forms}: Response = await fetch(endpoint, {method: 'GET'})
      return forms
    },
    {
      onError() {
        toast.error('Failed to fetch forms data.')
      },
      revalidateOnFocus: false,
    },
  )

  return (
    <ContextModal
      modal={ACCOUNT_MODAL_NAMES.WORKFLOW_MIGRATION_MODAL}
      title="Workflow Beta"
    >
      {isLoading || !data ? (
        <div className="flex h-96 items-center justify-center">
          <Loading />
        </div>
      ) : (
        <WorkflowBody forms={data} />
      )}
    </ContextModal>
  )
}

type WorkflowBodyProps = {
  forms: FormDetailed[]
}

function WorkflowBody(props: WorkflowBodyProps) {
  const {forms} = props
  const {account} = useAccountContext()
  const unsupportedPlugins = useMemo(
    () =>
      forms
        .map(form => ({
          form,
          plugins: form.plugins.filter(p => {
            return UNSUPPORTED_PLUGINS.includes(p.kind)
          }),
        }))
        .reduce<{form: FormDetailed; plugin: Plugin}[]>(
          (acc, {form, plugins}) => {
            plugins.forEach(plugin => {
              acc.push({form, plugin})
            })
            return acc
          },
          [],
        ),
    [forms],
  )

  if (account.features.workflow && unsupportedPlugins.length) {
    return <DisableActionsBody plugins={unsupportedPlugins} />
  }

  if (account.features.workflow) {
    return <DisableWorkflowBody />
  }

  return <EnableWorkflowBody />
}
