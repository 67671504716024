/** @format */

import {useCallback} from 'react'

// useFetch returns a fetch function that wraps window.fetch with
// some sane defaults.
//
// The returned fetch function is similar to @src/ajax but it returns
// a Promise rather than using the callback-style API.
export function useFetch<T extends Record<string, unknown>>({
  json = true,
}: {
  json?: boolean
} = {}) {
  return useCallback(
    async function fetch(
      input: RequestInfo | URL,
      {payload, ...init}: RequestInit & {payload?: T} = {},
    ) {
      let body
      if (payload) {
        body = json ? JSON.stringify(payload) : encodeFormData(payload)
      }

      const headers: HeadersInit = json
        ? {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          }
        : {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          }

      const initDefaults: RequestInit = {
        // You can pass init.body (native fetch api) instead of payload
        // and the payload will be ignored.
        body,
        credentials: 'same-origin',
        method: 'POST',
      }

      const res = await window.fetch(input, {
        ...initDefaults,
        ...init,
        headers,
      })

      // We don't throw on !res.ok here so we can parse JSON on bad response
      // to get the `error` or `errors` if provided by the API.

      if (res.status === 204) {
        return null
      }

      if (json) {
        const result = await res.json()
        if (result.error || result.errors) {
          const err = new Error(result.error || result.errors)
          Object.assign(err, result)
          throw err
        }
        return result
      }

      return res.text()
    },
    [json],
  )
}

function encodeFormData(data: Record<string, unknown>): string {
  const params = new URLSearchParams()
  for (const [k, v] of Object.entries(data)) {
    if (v != null) {
      params.set(k, `${v}`)
    }
  }
  return `${params}`
}

function getCookie(name: string): string {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return v ? v[2] : ''
}
