/** @format */

import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, PropsWithChildren, ReactNode} from 'react'
import flattenChildren from 'react-keyed-flatten-children'

/** Modal
 * @param title : string or jsx prop. the modal title
 * @param isOpen : boolean prop. is the modal open
 * @param onClose : function prop. called before closed
 * @param noMobileScroll : disable scrolling for mobile view.
 * @param children : the content of the modal
 */

export type ModalProps = PropsWithChildren<{
  title: ReactNode
  isOpen?: boolean
  onClose?: () => void
  // call when the leave animation ends, use to wait for state reset
  onDidClose?: () => void
  noMobileScroll?: boolean
  wide?: boolean
  narrow?: boolean
  fullBleed?: boolean
}>

/** @deprecated use ModalV2 instead */
function Modal({
  title,
  isOpen,
  onClose,
  onDidClose,
  wide = false,
  narrow = false,
  fullBleed = false,
  children,
}: ModalProps) {
  const doClose = () => {
    onClose && onClose()
  }

  const CloseButton = () => (
    <div className="fixed right-0 top-0 block pr-4 pt-4 sm:absolute">
      <button
        className="rounded-lg bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
        onClick={doClose}
      >
        <span className="sr-only">Close</span>
        <svg
          className="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  )

  const footer = flattenChildren(children).filter(isModalFooter)
  const content = flattenChildren(children).filter(
    child => !isModalFooter(child),
  )

  return (
    <Transition show={isOpen} as={Fragment} afterLeave={onDidClose}>
      <Dialog className="fixed inset-0 z-40" onClose={() => doClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="translate-y-16 opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="ease-out duration-200"
          leaveFrom="translate-y-0 opacity-100"
          leaveTo="translate-y-16 opacity-0"
        >
          <div
            className={`fixed left-0 right-0 top-0 grid sm:top-[10vh] sm:mx-auto ${
              wide
                ? 'sm:max-w-xl lg:max-w-3xl'
                : narrow
                  ? 'sm:max-w-lg'
                  : 'sm:max-w-xl'
            } max-h-screen rounded-2xl bg-white text-left align-bottom shadow-xl sm:max-h-[80vh] sm:w-full`}
            style={{
              gridTemplateColumns: '1fr',
              gridTemplateRows: 'auto 1fr auto',
              gridTemplateAreas: `'header' 'main' 'footer'`,
            }}
          >
            <div className="p-4" style={{gridArea: 'header'}}>
              {typeof title === 'string' ? (
                <Dialog.Title
                  className={'px-px text-lg font-bold text-gray-800'}
                >
                  {title}
                </Dialog.Title>
              ) : (
                <Dialog.Title className={'px-px'}>{title}</Dialog.Title>
              )}
            </div>
            <div
              className={`${
                fullBleed ? 'border-t border-gray-200' : 'px-4 pb-4 pt-px'
              } overflow-y-auto`}
              style={{gridArea: 'main'}}
            >
              {content.map((child, i) => (
                <div key={i}>{child}</div>
              ))}
            </div>
            <div className="relative" style={{gridArea: 'footer'}}>
              {!fullBleed && (
                <div
                  className={`absolute -top-4 left-4 right-4 h-4`}
                  style={{
                    background:
                      'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))',
                  }}
                />
              )}
              {footer.length > 0 && (
                <div
                  className={`px-4 pb-4 ${
                    fullBleed ? 'border-t border-gray-200 pt-4' : ''
                  }`}
                >
                  {footer.map((child, i) => (
                    <div key={i}>{child}</div>
                  ))}
                </div>
              )}
              {fullBleed && footer.length === 0 && (
                <div className={`h-4`}></div>
              )}
            </div>
            <CloseButton />
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}

export function ModalFooter({children}: PropsWithChildren) {
  return <div className="flex flex-row-reverse justify-between">{children}</div>
}

function isModalFooter(node: ReactNode): boolean {
  return (
    // all these to make typescript happy :)
    typeof node === 'object' &&
    node !== null &&
    'type' in node &&
    node.type === ModalFooter
  )
}

Modal.Footer = ModalFooter

export default Modal
