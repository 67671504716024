/** @format */

import {Elements} from '@stripe/react-stripe-js'
import React, {useContext, useEffect, useState} from 'react'

import Badge from '@src/components/tailwind/Badge'
import ComponentContainer from '@src/components/tailwind/ComponentContainer'
import {Loading} from '@src/components/tailwind/Loading'
import {
  ContextModal,
  useModalContext,
} from '@src/components/tailwind/ModalContext'
import Table, {Truncate} from '@src/components/tailwind/Table'

import ajax from '@src/ajax'
import {useAccountContext} from '@src/contexts'
import {capitalize} from '@src/utils'

import PayInvoiceForm from './PayInvoiceForm'

const stripeInstance = Stripe(formspree.STRIPE_PUBLISHABLE_KEY)
const MODAL_PAY_INVOICE = 'MODAL_PAY_INVOICE'

export default function InvoiceList() {
  let [invoices, setInvoices] = useState(undefined)
  let [loading, setIsLoading] = useState(true)
  let [invoiceCurrentlyOpen, setInvoiceCurrentlyOpen] = useState(undefined)
  const {reloadAccount} = useAccountContext()
  const {openModal} = useModalContext()

  useEffect(() => {
    fetchInvoices()
  }, [])

  const fetchInvoices = async () => {
    setIsLoading(true)
    ajax({
      method: 'GET',
      endpoint: `/api-int/account/invoices`,
      onSuccess: response => {
        setInvoices(response.invoices)
      },
      errorMsg: 'Failed to load invoices',
    }).then(() => {
      setIsLoading(false)
    })
  }

  const renderInvoiceStatus = invoice => {
    return invoice.status === 'uncollectible' ? (
      <Badge status={'error'}>Unpaid</Badge>
    ) : invoice.status === 'void' ? (
      <Badge status={'info'}>Void</Badge>
    ) : invoice.status === 'paid' ? (
      <Badge status={'ok'}>Paid</Badge>
    ) : (
      <Badge status={'warning'}>{capitalize(invoice.status)}</Badge>
    )
  }

  const openPaymentModal = invoiceId => {
    openModal(MODAL_PAY_INVOICE)
    setInvoiceCurrentlyOpen(invoiceId)
  }

  if (loading) {
    return (
      <div className={'m-8'}>
        <Loading />
      </div>
    )
  }

  async function handlePaymentSuccess() {
    await ajax({
      method: 'POST',
      endpoint: '/api-int/subscribe',
      errorMsg: 'Failed to update subscription',
      encourageRetry: false,
    })
    await Promise.all([fetchInvoices(), reloadAccount(null, false)])
  }

  return (
    <ComponentContainer title="Past Invoices" fullBleed={true}>
      <ContextModal
        modal={MODAL_PAY_INVOICE}
        title="Pay Invoice"
        className="narrow"
      >
        <Elements stripe={stripeInstance}>
          <PayInvoiceForm
            invoiceId={invoiceCurrentlyOpen}
            onPaymentSuccess={handlePaymentSuccess}
          />
        </Elements>
      </ContextModal>

      {invoices !== undefined ? (
        invoices.length ? (
          <Table
            dividers={false}
            columns={['Date', 'Invoice', 'Amount', 'Status', '']}
            rows={invoices
              .filter(invoice => invoice.attempted)
              .map(invoice => [
                <Truncate>{invoice.date}</Truncate>,
                <Truncate>{invoice.number}</Truncate>,
                `$${(invoice.total / 100).toFixed(2)}`,
                renderInvoiceStatus(invoice),
                <div className="flex items-center justify-end gap-x-4">
                  <a
                    href={`/account/billing/invoice/${invoice.id.slice(3)}`}
                    className="primaryLink"
                    target="_blank"
                  >
                    Print
                  </a>
                  {['open', 'uncollectible'].includes(invoice.status) && (
                    <button
                      className="buttonPrimary text-sm leading-none"
                      onClick={() => openPaymentModal(invoice.id)}
                    >
                      Pay
                    </button>
                  )}
                </div>,
              ])}
          />
        ) : (
          <p className="mx-8 my-6 text-sm text-gray-500">No invoices found</p>
        )
      ) : (
        <p className="mx-8 my-6 text-sm text-gray-500">
          Unable to load invoices
        </p>
      )}
    </ComponentContainer>
  )
}
