/** @format */

export const amplitude =
  process.env.NODE_ENV === 'development'
    ? {
        logEvent(name: string, properties?: Record<string, unknown>): void {
          console.log(`Amplitude log event: ${name}`)
          if (properties) {
            console.log('Properties:', properties)
          }
        },
      }
    : window.amplitude?.getInstance()
