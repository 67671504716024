/** @format */

import React from 'react'
import useSWR from 'swr'

import Spinner from '@src/components/tailwind/Spinner'

import {Plan} from '@src/contexts'
import {useFetch} from '@src/hooks/useFetch'

type EstimateProps = {
  isActive: boolean
  params:
    | {
        plan: Plan['stripe_plan']
      }
    | {
        plan: Plan['stripe_plan']
        postalCode: string
        country: string
      }
}

export function Estimate(props: EstimateProps) {
  const {isActive, params} = props

  const fetch = useFetch()
  const query = useSWR(
    isActive ? `/api-int/estimate?${new URLSearchParams(params)}` : null,
    url => fetch(url, {method: 'GET'}),
    {
      revalidateOnFocus: false,
    },
  )

  return (
    <div className="flex items-center text-sm">
      <span className="text-gray-700">
        Total amount to be charged today:&nbsp;
      </span>
      {query.isLoading ? (
        <Spinner type="circle" className="h-4 w-4 text-gray-900" />
      ) : query.data ? (
        <span className="font-bold">{query.data.amount}</span>
      ) : null}
    </div>
  )
}
