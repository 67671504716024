/** @format */

import * as Sentry from '@sentry/react'
import {
  AddressElement,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {PaymentMethod} from '@stripe/stripe-js'
import React, {ReactNode, useState} from 'react'

import LoaderButton from '@src/components/tailwind/LoaderButton'
import * as Modal from '@src/components/tailwind/ModalV2'

import {amplitude} from '@src/amplitude'
import {
  Plan,
  Product,
  useAccountContext,
  useLoadingContext,
} from '@src/contexts'
import * as toast from '@src/toast'

import {
  Discount,
  Estimate,
  PaymentSection,
  SelectPlan,
  useSelectPlan,
} from './common'

type WithNewCardProps = {
  description?: ReactNode
  discount?: string
  isOpen: boolean

  onConfirmUpgrade: (
    plan: Plan,
    paymentMethod?: PaymentMethod,
  ) => Promise<void> | void

  options: Product['id'][]
  title?: string
}

type Billing = {
  name: string
  address: {
    line1: string
    line2: string | null
    city: string
    state: string
    postal_code: string
    country: string
  }
}

export function WithNewCard(props: WithNewCardProps) {
  const {description, discount, isOpen, onConfirmUpgrade, options, title} =
    props
  const selectPlan = useSelectPlan(options)

  const {profile} = useAccountContext()

  const stripe = useStripe()
  const elements = useElements()

  const [billing, setBilling] = useState<Billing>()
  const [addressCompleted, setAddressCompleted] = useState(false)
  const [elementsCompleted, setElementsCompleted] = useState(false)

  const {ready} = useLoadingContext()
  async function handleConfirmUpgrade() {
    if (!stripe) {
      throw new Error('Cannot create payment method without Stripe instance')
    }

    if (!elements) {
      throw new Error('Cannot create payment method without Elements instance')
    }

    if (!billing) {
      throw new Error(
        'Cannot create payment method without billing information',
      )
    }

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          address: {
            ...billing.address,
            // inconsistent type in stripe-js lib between Address element's
            // event.value and BillingDetails.Address
            line2: billing.address.line2 ?? undefined,
          },
          email: profile.email,
          name: billing.name,
        },
      },
    })

    if (error) {
      toast.error(
        error.message
          ? `Failed to create payment method: ${error.message}`
          : 'Failed to create payment method',
      )
      reportUpgradeErrorToSentry(error)
      ready()
      return
    }

    try {
      await onConfirmUpgrade(selectPlan.state, paymentMethod)
      amplitude.logEvent('Upgrade Plan Completed', {
        product: selectPlan.state.product,
        stripe_plan: selectPlan.state.stripe_plan,
      })
    } catch (err) {
      toast.error(
        err instanceof Error
          ? err.message
          : 'Could not upgrade plan. Please reach out to support.',
      )
      reportUpgradeErrorToSentry(err)
    } finally {
      ready()
    }
  }

  return (
    <Modal.DefaultLayout>
      <Modal.Header title={title} />
      <Modal.Content>
        <div className="grid gap-6">
          <div className="grid gap-4">
            {description && <p className="text-sm">{description}</p>}
            <SelectPlan {...selectPlan.props} />
          </div>
          <PaymentSection>
            <div className="grid gap-2">
              {discount && <Discount>{discount}</Discount>}
              <Estimate
                isActive={isOpen}
                params={
                  billing
                    ? {
                        plan: selectPlan.state.stripe_plan,
                        postalCode: billing.address.postal_code,
                        country: billing.address.country,
                      }
                    : {
                        plan: selectPlan.state.stripe_plan,
                      }
                }
              />
            </div>
            <div className="pb-2">
              <AddressElement
                onChange={event => {
                  setBilling(event.value)
                  setAddressCompleted(event.complete)
                }}
                options={{mode: 'billing'}}
              />
            </div>
            <div className="rounded bg-gray-100 px-4">
              <CardElement
                onChange={event => setElementsCompleted(event.complete)}
                options={{
                  hidePostalCode: true,
                  style: {base: {lineHeight: '44px'}},
                }}
              />
            </div>
          </PaymentSection>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className="flex flex-row-reverse">
          <LoaderButton
            disabled={!addressCompleted || !elementsCompleted}
            onClick={handleConfirmUpgrade}
          >
            Confirm Upgrade
          </LoaderButton>
        </div>
      </Modal.Footer>
    </Modal.DefaultLayout>
  )
}

function reportUpgradeErrorToSentry(err: unknown): void {
  Sentry.withScope(scope => {
    scope.setTag('location', 'UpgradeSubscriptionModal.confirmUpgradeHandler')
    scope.setTag('paymentMethod', 'new-card')
    Sentry.captureException(err)
  })
}
