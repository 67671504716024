/** @format */

import amex from './cc-amex.svg'
import diners from './cc-diners-club.svg'
import discover from './cc-discover.svg'
import jcb from './cc-jcb.svg'
import mastercard from './cc-mastercard.svg'
import unionpay from './cc-unionpay.svg'
import visa from './cc-visa.svg'
import unknown from './credit-card.svg'

const byCardName: Record<string, string> = Object.freeze({
  'cc-amex': amex,
  'cc-diners-club': diners,
  'cc-discover': discover,
  'cc-jcb': jcb,
  'cc-mastercard': mastercard,
  'cc-unionpay': unionpay,
  'cc-visa': visa,
  'credit-card': unknown,
})

export function getByCardName(name: string): string {
  const src = byCardName[name]
  return src ?? unknown
}
