/** @format */

import React from 'react'
import {Link} from 'react-router-dom'

import {AnalyticsEvent, sendAnalytics} from '@src/analytics'

export default function UpgradeAccount() {
  return (
    <div className="flex gap-x-4">
      <div>
        <h3 className="text-xl font-medium">Upgrade your account for:</h3>
        <ol className="m-4 list-inside list-decimal">
          <li>File upload</li>
          <li>More submissions</li>
          <li>Custom "thank you" redirect</li>
          <li>Plugins like Google Sheets, Mailchimp and Stripe</li>
          <li>Improved spam filtering</li>
          <li>API access</li>
        </ol>
        ... and more!
      </div>

      <div className="ml-auto shrink-0">
        <Link
          className="buttonPrimary"
          onClick={() =>
            sendAnalytics(AnalyticsEvent.upgrade_clicked, {
              feature: 'general_upgrade',
            })
          }
          to="/plans"
        >
          See Plans
        </Link>
      </div>
    </div>
  )
}
