/** @format */

import React, {lazy, useEffect} from 'react'
import {Navigate, type RouteObject} from 'react-router-dom'

import IFrameWrapper from '@src/dashboard/IFrameWrapper'
import {formPageRouter} from '@src/forms/FormPage'
import {useDefaultForm} from '@src/hooks/useDefaultForm'
import {getDefaultFormPageUrl} from '@src/utils'

const FormList = lazy(() => import('@src/forms/FormList'))

export const formsRouter = {
  path: 'forms',
  children: [
    {
      index: true,
      element: <FormsIndexRoute />,
    },
    {
      path: ':hashid',
      children: [
        {
          path: 'whitelabel',
          element: <IFrameWrapper />,
        },
        formPageRouter,
      ],
    },
  ],
} satisfies RouteObject

function FormsIndexRoute() {
  const defaultForm = useDefaultForm()

  useEffect(() => {
    if (defaultForm) {
      localStorage?.setItem('currentHashid', defaultForm.hashid)
    } else {
      localStorage?.removeItem('currentHashid')
    }
  }, [defaultForm])

  return defaultForm ? (
    <Navigate replace to={getDefaultFormPageUrl(defaultForm)} />
  ) : (
    <FormList />
  )
}
