/** @format */

import React, {type PropsWithChildren} from 'react'

import logo from '@src/images/formspree-corp-mark.svg'

const Splash = ({
  title,
  description,
  children,
}: PropsWithChildren<{title: string; description: string}>) => {
  return (
    <>
      <div className="m-auto flex flex-col items-center justify-center bg-watermark bg-auto bg-center bg-no-repeat">
        <div className="mb-6 mt-12 flex max-w-4xl flex-col items-center justify-center sm:flex-row">
          <img
            alt="Formspree logo"
            src={logo}
            title="Formspree logo"
            className="h-32 w-32"
          />
          <h1 className="mx-6 text-5xl font-bold">{title}</h1>
        </div>
        {description ? (
          <p className="mx-8 mb-12 text-center text-xl font-medium leading-6 text-gray-500">
            {description}
          </p>
        ) : null}
        {children ? (
          <div className={'mb-24 text-center'}>{children}</div>
        ) : (
          <div className={'h-8'} />
        )}
      </div>
    </>
  )
}

export default Splash
