/** @format */

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react'
import useSWR, {mutate} from 'swr'

import {useAccountContext} from '@src/contexts'

import type {FieldValidation} from './FieldValidation'
import type {Api, ValidationRepository} from './ValidationRepository'

type ValidationApi = {
  listValidations: {
    isLoading: boolean
    data: FieldValidation[]
    error?: Error
    // use to imperatively cause listValidations to revalidate (re-fetch data)
    // The promise can be awaited for revalidation completed.
    revalidate: () => Promise<Api.ValidationRulesResponse | undefined>
  }

  add: (
    fieldName: string,
    input: Api.UpdateValidationRulePayload,
  ) => Promise<void>

  update: <T extends Api.UpdateValidationRulePayload>(
    fieldName: string,
    input: Partial<T>,
  ) => Promise<void>

  renameField: (from: string, to: string) => Promise<void>

  delete: (fieldName: string) => Promise<void>
}

const Context = createContext<ValidationApi | null>(null)

type ValidationApiProviderProps = PropsWithChildren<{
  initialData?: Api.ValidationRulesResponse
  repo: ValidationRepository
}>

export function ValidationApiProvider(props: ValidationApiProviderProps) {
  const {children, initialData = {}, repo} = props
  const {account} = useAccountContext()
  const listValidationsQuery = useSWR(
    // This is shared between Workflow and legacy Plugins. Users without
    // the `workflow` feature will not have access to list validations API,
    // so we want to make it a no-op for plugins users.
    account.features.workflow ? `${repo.formId}.listValidations` : null,
    () => repo.getAll(),
    {
      fallbackData: initialData,
      revalidateOnFocus: false,
    },
  )

  const fieldValidations = useMemo(
    () => decodeApiResponse(listValidationsQuery.data),
    [listValidationsQuery.data],
  )

  // getExplicitRulesByFieldName prepare a data structure representing
  // the explicit rules for conveninently read/write to the database.
  //
  // Note: ValidationApi can only modify explicit rules, implicit rules
  // are added Actions.
  function getExplicitRulesByFieldName() {
    const m = new CompactMap<string, Api.UpdateValidationRulePayload>()
    const res = listValidationsQuery.data
    for (const [fieldName, rules] of Object.entries(res)) {
      // Filter out implicit rules since we only save explicit rules to DB.
      const explicitRules = rules.filter(r => !r.plugin)
      if (explicitRules.length > 0) {
        m.set(fieldName, encodeApiPayload(explicitRules))
      }
    }
    return m
  }

  async function saveUpdateToRepo(
    input: Api.UpdateValidationPayloads,
  ): Promise<void> {
    const updateResults = await repo.updateValidations(input)
    await listValidationsQuery.mutate(updateResults, {revalidate: false})
  }

  const api: ValidationApi = {
    listValidations: {
      isLoading: listValidationsQuery.isLoading,
      error: listValidationsQuery.error,
      data: fieldValidations,
      revalidate: () => listValidationsQuery.mutate(),
    },

    async add(fieldName, input) {
      if (listValidationsQuery.data[fieldName]) {
        throw new AlreadyExistsError(fieldName)
      }

      const toSave = getExplicitRulesByFieldName()

      toSave.set(fieldName, input)

      await saveUpdateToRepo(Object.fromEntries(toSave))
    },

    async update(fieldName, input) {
      const res = listValidationsQuery.data[fieldName]

      if (!res) {
        throw new NotFoundError(fieldName)
      }

      const toSave = getExplicitRulesByFieldName()

      const current = toSave.get(fieldName)
      if (current) {
        if (input.type && input.type !== current.type) {
          throw new Error('Cannot change the type of an existing field.')
        }
        toSave.set(fieldName, {...current, ...input})
      } else {
        // If there are no "explicit" rules previously saved,
        // updating will make a new entry, so we require a field type.

        const type: Api.UpdateValidationRulePayload['type'] | undefined =
          input.type ??
          res.find(
            (r): r is Extract<Api.ValidationRule, {key: 'type'}> =>
              r.key === 'type',
          )?.value

        if (!type) {
          throw new Error(`Cannot update ${fieldName} without the field type.`)
        }

        // @ts-ignore: TO FIX error from upgrading typescript 5.3.3 -> 5.4.5
        // Argument of type 'Partial<T> & { type: "email" | "text" | "numeric" | "url" | "datetime-local" | "file"; }' is not assignable to parameter of type 'UpdateValidationRulePayload'.
        // Type 'Partial<T> & { type: "email" | "text" | "numeric" | "url" | "datetime-local" | "file"; }' is not assignable to type '{ type: "file"; required?: boolean | undefined; accept?: string[] | undefined; }'.
        toSave.set(fieldName, {...input, type})
      }

      await saveUpdateToRepo(Object.fromEntries(toSave))
    },

    // We implement renameField as a separate method from `update` since
    // the saved validation use field names as ids.
    async renameField(from, to) {
      if (!listValidationsQuery.data[from]) {
        throw new NotFoundError(from)
      }

      // cannot rename to a field name that is currently used
      if (listValidationsQuery.data[to]) {
        throw new AlreadyExistsError(to)
      }

      const toSave = getExplicitRulesByFieldName()

      const current = toSave.get(from)
      if (!current) {
        throw new Error(`No saved explicit rules for "${from}" field`)
      }

      // renaming a field name is moving the explicit record
      // from the previous field name to the new field name
      toSave.delete(from)
      toSave.set(to, current)

      await saveUpdateToRepo(Object.fromEntries(toSave))
    },

    async delete(fieldName) {
      if (listValidationsQuery.data[fieldName]?.some(r => r.plugin)) {
        throw new Error(
          'Cannot delete field validation containing some rules added by actions.',
        )
      }

      const toSave = getExplicitRulesByFieldName()

      if (!toSave.get(fieldName)) {
        throw new NotFoundError(fieldName)
      }

      toSave.delete(fieldName)

      await saveUpdateToRepo(Object.fromEntries(toSave))
    },
  }
  return <Context.Provider value={api}>{children}</Context.Provider>
}

export function useValidationApi(): ValidationApi {
  const api = useContext(Context)
  if (!api) {
    throw new Error(
      'No ValidationApi found via context. Use ValidationApiProvider to provide one.',
    )
  }
  return api
}

// CompactMap is like a Map but it (shallow) removes keys with undefined values
// from the value that's being set. We use it to clean up the payload object
// before sending it to the repository.
class CompactMap<K, V extends {}> extends Map<K, V> {
  set(key: K, value: V): this {
    const o: Record<string, unknown> = {}
    for (const [k, v] of Object.entries(value)) {
      if (typeof v !== 'undefined') {
        o[k] = v
      }
    }
    super.set(key, o as V)
    return this
  }
}

// encodeApiPayload transform Api.ValidationRule[] to
// Api.UpdateValidationRulePayload (per field) suitable for ValidationRepository.
function encodeApiPayload(
  rules: Api.ValidationRule[],
): Api.UpdateValidationRulePayload {
  const m = new Map()
  for (const r of rules) {
    if (r.plugin) {
      continue
    }
    m.set(r.key, r.value)
  }
  return Object.fromEntries(m)
}

function decodeApiResponse(
  res: Api.ValidationRulesResponse,
): FieldValidation[] {
  const validations: Set<FieldValidation> = new Set()

  for (const [fieldName, rules] of Object.entries(res)) {
    // per field
    const m = new Map()
    // per field
    const plugins = new Set()

    for (const r of rules) {
      // per rule
      const rulePlugins = m.get(r.key)?.plugins ?? new Set()
      if (r.plugin) {
        plugins.add(r.plugin)
        rulePlugins.add(r.plugin)
      }
      if (rulePlugins.size > 0) {
        m.set(r.key, {value: r.value, plugins: rulePlugins})
      } else {
        m.set(r.key, {value: r.value})
      }
    }

    m.set('fieldName', fieldName)
    if (plugins.size > 0) {
      m.set('plugins', plugins)
    }
    validations.add(Object.fromEntries(m))
  }

  return [...validations]
}

export function revalidateListValidationsQuery(formId: string) {
  return mutate<Api.ValidationRulesResponse>(`${formId}.listValidations`)
}

class AlreadyExistsError extends Error {
  constructor(fieldName: string) {
    super(`Validation for field "${fieldName}" already exists.`)
  }
}

class NotFoundError extends Error {
  constructor(fieldName: string) {
    super(`Validation for field "${fieldName}" does not exist.`)
  }
}
