/** @format */

import {ShieldCheckIcon} from '@heroicons/react/outline'
import React from 'react'

import LoaderButton from '@src/components/tailwind/LoaderButton'

export function TwoFactorInfo(props) {
  return (
    <>
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
        <ShieldCheckIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
      </div>
      <div className="mt-3 sm:mt-5">
        <h3 className="text-center text-lg font-medium leading-6 text-gray-900">
          Setup 2 Factor Authentication
        </h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            2 factor authentication protects your Formspree account by requiring
            an additional one time code when you sign in.
          </p>
          <p className="text-sm text-gray-500">
            To set up 2 factor authentication, you must have a device that can
            generate TOTP codes (usually a smartphone or desktop application).
            Some common options include Google Authenticator (
            <a
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              target="_blank"
            >
              iOS
            </a>{' '}
            or{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              target="_blank"
            >
              Android
            </a>
            ),{' '}
            <a href="https://authy.com/download/" target="_blank">
              Authy
            </a>
            , or{' '}
            <a href="https://1password.com/downloads" target="_blank">
              1Password
            </a>
            .
          </p>
        </div>
        <LoaderButton onClick={props.nextStep} className="buttonPrimary w-full">
          Next &gt;
        </LoaderButton>
      </div>
    </>
  )
}
