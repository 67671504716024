/** @format */

import React, {ReactNode} from 'react'

import bgFormspreeLogoSVG from './bg-formspree-logo.svg'

type PromoBannerProps = {
  action?: ReactNode
  title: string
  description: ReactNode
}

export function PromoBanner(props: PromoBannerProps) {
  const {action, description, title} = props
  return (
    <section
      className="flex flex-col gap-4 rounded-lg bg-secondary p-8 text-white lg:flex-row"
      style={{
        backgroundImage: `url(${bgFormspreeLogoSVG})`,
        backgroundPositionX: 'calc(100% + 74px)',
        backgroundPositionY: 'calc(100% + 193px)',
        backgroundSize: '558px 697px',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="flex grow flex-col gap-y-3">
        <h2 className="text-xl font-bold md:text-2xl">{title}</h2>
        {description}
      </div>
      {action && <div className="flex shrink-0 flex-col-reverse">{action}</div>}
    </section>
  )
}
