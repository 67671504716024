/** @format */

import React, {createContext, useState} from 'react'

export const LoadingContext = createContext({})

export const LoadingContextProvider = props => {
  const [waitingButton, waitButton] = useState(null)

  const ready = () => {
    waitButton(null)
  }

  return (
    <LoadingContext.Provider
      value={{
        waitingButton,
        waitButton,
        ready
      }}
    >
      {props.children}
    </LoadingContext.Provider>
  )
}
