/** @format */

// shuffle randomizes elements in xs in-place (mutates the input)
// using a version of Fisher-Yates algorithm.
export function shuffle<T>(xs: T[]): T[] {
  for (let i = xs.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (xs.length - 1))
    // swap elements between i and j
    // leading semi-colon is needed
    ;[xs[i], xs[j]] = [xs[j], xs[i]]
  }
  return xs
}

// Convert a decimal (e.g. 0.65) to a percentage string
// with a maximum of 2 digits (e.g. 65%)
export function toPercentage(decimal: number): string {
  // Use this method instead of .toFixed() to display decimals only when needed.
  // We don't want to use .toLocaleString() in this case because we don't want
  // thousand separator.
  //
  // Number.EPSILON is used to handle rounding errors in Javascript.
  // https://stackoverflow.com/a/12830454
  return `${Math.round((decimal + Number.EPSILON) * 100 ** 2) / 100}%`
}
