/** @format */
import React from 'react'
import {useLocation} from 'react-router-dom'

export default function IFrameWrapper() {
  const {pathname} = useLocation()
  return (
    <div className="ml-0 mt-4 sm:ml-8 sm:mt-8">
      <iframe
        className="h-screen w-full overflow-hidden"
        data-testid="iframe-route"
        src={`/iframe${pathname}`}
      />
    </div>
  )
}
