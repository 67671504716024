/** @format */
import React, {useContext, useState} from 'react'
import ActionInput from '../../components/tailwind/ActionInput'
import {AccountContext} from '../../contexts/AccountContext'
import {
  ModalContext,
  ContextModal
} from '../../components/tailwind/ModalContext'
import {LoadingContext} from '../../contexts/LoadingContext'
import Modal from '../../components/tailwind/Modal'
import LoaderButton from '../../components/tailwind/LoaderButton'

export default function ChangePasswordModal({modal}) {
  const {ready} = useContext(LoadingContext)
  const {patchAccount} = useContext(AccountContext)
  const {closeCurrentModal} = useContext(ModalContext)
  const [newPassword, setNewPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')

  return (
    <ContextModal modal={modal} title={'Update Account Password'}>
      <ActionInput
        label={'Old Password'}
        value={oldPassword}
        type={'password'}
        onChange={e => {
          setOldPassword(e.target.value)
        }}
      />
      <ActionInput
        label={'New Password'}
        value={newPassword}
        type={'password'}
        onChange={e => {
          e.preventDefault()
          setNewPassword(e.target.value)
        }}
      />
      <Modal.Footer>
        <LoaderButton
          onClick={() => {
            patchAccount({
              payload: {
                password: newPassword,
                old_password: oldPassword
              },
              profile: true
            }).then(() => {
              closeCurrentModal()
              setOldPassword('')
              setNewPassword('')
              ready()
            })
          }}
          disabled={!newPassword || !oldPassword}
        >
          Save
        </LoaderButton>
      </Modal.Footer>
    </ContextModal>
  )
}
