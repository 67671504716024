/** @format */

import React from 'react'

import {StripeElementsProvider} from '@src/components/tailwind/StripeElementsProvider'

import InvoiceList from './InvoiceList'
import Payment from './Payment'
import PlanStatus from './PlanStatus'
import {ProjectEndedPromoBanner} from './ProjectEndedPromoBanner'

export default function Billing() {
  return (
    <StripeElementsProvider>
      <ProjectEndedPromoBanner />
      <PlanStatus />
      <Payment />
      <InvoiceList />
    </StripeElementsProvider>
  )
}
