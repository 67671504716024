/** @format */

import React, {lazy} from 'react'
import {Navigate, type RouteObject} from 'react-router-dom'

const Overview = lazy(() => import('./Overview'))
const Settings = lazy(() => import('./Settings'))
const Templates = lazy(() => import('./Templates'))

export const projectsRouter = {
  path: 'projects/:pid',
  children: [
    {
      index: true,
      element: <Navigate replace to="overview" />,
    },
    {
      path: 'overview',
      element: <Overview />,
    },
    {
      path: 'settings',
      element: <Settings />,
    },
    {
      path: 'templates/*',
      element: <Templates />,
    },
  ],
} satisfies RouteObject
