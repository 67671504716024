/** @format */

import {ExclamationCircleIcon} from '@heroicons/react/outline'
import React, {useContext, useState} from 'react'

import ActionInput from '@src/components/tailwind/ActionInput'
import {Alert} from '@src/components/tailwind/Alert'
import LoaderButton from '@src/components/tailwind/LoaderButton'
import Modal from '@src/components/tailwind/Modal'
import {ContextModal, ModalContext} from '@src/components/tailwind/ModalContext'
import {OtpInput} from '@src/components/tailwind/OtpInput'

import ajax from '@src/ajax'
import {AccountContext} from '@src/contexts/AccountContext'
import {LoadingContext} from '@src/contexts/LoadingContext'
import {formbutton} from '@src/utils'

export default function DeleteAccountModal({modal}) {
  const {profile, account} = useContext(AccountContext)
  const {ready} = useContext(LoadingContext)
  const {closeCurrentModal} = useContext(ModalContext)
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')

  const deleteAccount = async () => {
    const payload = {password, otp}
    await ajax({
      method: 'DELETE',
      endpoint: `/api-int/account/`,
      payload,
      errorMsg: 'Failed to delete account',
      onSuccess: response => {
        window.location = response.next
      },
      onError: () => {
        ready()
      },
    })
  }

  return (account && account.kind === 'team') ||
    (profile.kind === 'user' && !profile.is_team_primary_admin) ? (
    <ContextModal modal={modal} title={'Delete Account'}>
      <Alert type="danger">
        <div className="shrink-0">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 text-sm text-red-700">
          <p className="">
            Deleting your account will delete all forms and submissions.
          </p>
          <p className="font-bold">This action cannot be undone.</p>
        </div>
      </Alert>
      <ActionInput
        label={'Enter your password'}
        value={password}
        type={'password'}
        onChange={e => setPassword(e.target.value)}
      />
      {profile.two_factor_authentication_enabled && (
        <div className="my-2">
          <label htmlFor="otp_code">
            Enter the code from your authenticator app
          </label>
          <OtpInput onChange={setOtp} />
        </div>
      )}
      <Modal.Footer>
        <LoaderButton
          className="buttonPrimary float-right"
          onClick={deleteAccount}
        >
          Delete
        </LoaderButton>
      </Modal.Footer>
    </ContextModal>
  ) : (
    <ContextModal modal={modal} title={'Delete Account'}>
      <Alert type="danger">
        <div className="shrink-0">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 text-sm text-red-700">
          <p>
            You can't delete your account because you are the primary admin for
            a team. Please delete those teams or{' '}
            <a
              href="#"
              onClick={event => {
                event.preventDefault()
                closeCurrentModal()
                formbutton()('showButton', true)
                formbutton()('showForm', true)
              }}
              className="primaryLink"
            >
              contact support
            </a>{' '}
            to transfer this role prior to deleting your account
          </p>
        </div>
      </Alert>
    </ContextModal>
  )
}
