/** @format */

import React, {useContext, useEffect, useRef, useMemo} from 'react'

import {LoadingContext} from '../../contexts/LoadingContext'

export default function LoaderButton({secondary, id, ...props}) {
  const {waitButton, waitingButton} = useContext(LoadingContext)
  let buttonRef = useRef()

  const loading = waitingButton === buttonRef

  const startWaiting = e => {
    e.preventDefault()
    waitButton(buttonRef)
  }

  useEffect(() => {
    if (!props.onClick) {
      // if there's no onClick it means the button is submitting
      // a form that is his ancestor.
      let parentForm = document.getElementById(
        buttonRef.current.getAttribute('form')
      )
      if (!parentForm) {
        parentForm = buttonRef.current.parentNode
        for (let i = 0; i < 6; i++) {
          if (parentForm.tagName === 'FORM') {
            break
          }
          parentForm = parentForm.parentNode
        }
      }
      if (parentForm.tagName === 'FORM') {
        parentForm.addEventListener('submit', startWaiting)
        return () => {
          parentForm.removeEventListener('submit', startWaiting)
        }
      }
    }
  }, [])

  return (
    <button
      {...props}
      className={
        (props.className ? props.className : '') +
        (secondary ? ' buttonSecondary ' : ' buttonPrimary ') +
        (loading ? 'loading' : '')
      }
      disabled={loading || props.disabled}
      onClick={e => {
        // march 2022: I tried to remove useEffect dom traversal above by simply
        // setting the waiting button state here onClick, however that prevents
        // form submission. I don't know why.
        if (props.onClick) {
          startWaiting(e)
          props.onClick(e)
        }
      }}
      ref={buttonRef}
    />
  )
}
