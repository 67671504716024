/** @format */

import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js'
import * as toast from '../toast'
import React, {useContext, useEffect, useState} from 'react'
import {LoadingContext} from '../contexts/LoadingContext'
import ajax from '../ajax'
import FormspreeSelect from '../components/tailwind/FormspreeSelect'
import LoaderButton from '../components/tailwind/LoaderButton'
import Modal from '../components/tailwind/Modal'
import {ContextModal} from '../components/tailwind/ModalContext'

export default function UpdateCardModal({addCard, modal}) {
  const stripe = useStripe()
  const elements = useElements()
  const {ready} = useContext(LoadingContext)

  let [data, setData] = useState({
    name: undefined,
    address_line1: undefined,
    address_city: undefined,
    address_state: undefined,
    address_country: undefined
  })
  let [elementsComplete, setElementsComplete] = useState(false)
  let [setupIntentSecret, setSetupIntentSecret] = useState(undefined)

  useEffect(() => {
    ajax({
      method: 'GET',
      endpoint: `/api-int/account/setup-intent`,
      onSuccess: response => {
        setSetupIntentSecret(response.secret)
      },
      errorMsg:
        "Failed to communicate with billing provider. If you're using a VPN, please try disabling it",
      encourageRetry: true
    })
  }, [])

  const canSubmit = () => {
    return (
      elementsComplete &&
      [data.name, data.address_line1, data.address_country].every(field =>
        Boolean(field)
      )
    )
  }

  const completeCheckout = async e => {
    e.preventDefault()
    const cardElement = elements.getElement(CardElement)
    const {setupIntent, error} = await stripe.confirmCardSetup(
      setupIntentSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            address: {
              city: data.address_city,
              country: data.address_country,
              line1: data.address_line1,
              state: data.address_state
            },
            name: data.name
          }
        }
      }
    )

    if (error) {
      toast.warning(error.message)
      ready()
      return
    } else {
      if (setupIntent.status === 'succeeded') {
        // The setup has succeeded. Display a success message. Send
        // setupIntent.payment_method to your server to save the card to a Customer
        addCard(setupIntent.payment_method)
      }
    }
    if (error) {
      toast.warning(error.message)
      ready()
      return
    }
  }

  let countryOptions = formspree.billing_countries.map(country => {
    return {label: country.name, value: country.code}
  })

  return (
    <ContextModal modal={modal} title="Update Card">
      <form onSubmit={completeCheckout} id="payment-form">
        <label>
          Name:{' '}
          <input
            autoComplete="name"
            name="name"
            autoFocus
            required
            value={data.name}
            onChange={e => {
              setData({...data, name: e.target.value})
            }}
          />
        </label>
        <label>
          Address:{' '}
          <input
            autoComplete="street-address"
            name="street-address"
            required
            value={data.address_line1}
            onChange={e => {
              setData({...data, address_line1: e.target.value})
            }}
          />
        </label>
        <div className={'flex space-x-2'}>
          <label className="flex-1">
            City:{' '}
            <input
              autoComplete="city"
              name="city"
              value={data.address_city}
              onChange={e => {
                setData({...data, address_city: e.target.value})
              }}
            />
          </label>
          <label className="flex-1">
            State/Province:{' '}
            <input
              autoComplete="state"
              name="state"
              value={data.address_state}
              onChange={e => {
                setData({...data, address_state: e.target.value})
              }}
            />
          </label>
          <label className="flex-1">
            Country:{' '}
            <FormspreeSelect
              required
              options={countryOptions}
              onChange={country => {
                setData({...data, address_country: country.value})
              }}
              inputProps={{autoComplete: 'none'}}
            />
          </label>
        </div>
        <label>
          Card Details:{' '}
          <div className="input">
            <CardElement
              onChange={event => setElementsComplete(event.complete)}
              options={{
                style: {
                  base: {
                    lineHeight: '28px'
                  }
                }
              }}
            />
          </div>
        </label>
        <p className={'mt-4 text-sm text-gray-500'}>
          I authorize Formspree to send instructions to the financial
          institution that issued my card to take payments from my card account
          in accordance with the terms of my agreement with you.
        </p>
      </form>
      <Modal.Footer>
        <LoaderButton
          className="buttonPrimary"
          type="submit"
          form="payment-form"
          disabled={!canSubmit()}
        >
          Update Card
        </LoaderButton>
      </Modal.Footer>
    </ContextModal>
  )
}
