/** @format */

import React, {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

import {formbutton} from '../../utils'
import Modal, {ModalProps} from './Modal'

const NO_CURRENT_MODAL = '__NONE__'

type ModalContextType = {
  openModal: (modal: string) => void
  isOpen: (modal: string) => boolean
  closeCurrentModal: () => void
}

const ModalContext = React.createContext<ModalContextType>({
  openModal: () => {},
  isOpen: () => false,
  closeCurrentModal: () => {},
})

/** A modal context provider with a default implementation for modal
 * state management. Can be overridden by passing in props for
 * openModal, isOpen and closeCurrentModal */
function ModalContextProvider(
  props: PropsWithChildren<Partial<ModalContextType>>,
) {
  const [currentModal, setCurrentModal] = useState(NO_CURRENT_MODAL)

  const {
    openModal = modal => {
      setCurrentModal(modal)
      formbutton()('showButton', false)
    },
    isOpen = modal => modal === currentModal,
    closeCurrentModal = () => {
      setCurrentModal(NO_CURRENT_MODAL)
      formbutton()('showButton', true)
    },
  } = props

  return (
    <ModalContext.Provider
      value={{
        openModal,
        isOpen,
        closeCurrentModal,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  )
}

function useModalContext(): ModalContextType {
  return useContext(ModalContext)
}

/** A modal wrapper that conditionally renders a modal when the isOpen
 * context function returns true. Also closes the current modal using the
 * closeCurrentModal context function */
function ContextModal({
  modal,
  isOpen: isOpenProps,
  onClose,
  children,
  ...rest
}: {modal: string} & ModalProps) {
  const {isOpen, closeCurrentModal} = useContext(ModalContext)
  return (
    <Modal
      isOpen={isOpenProps ?? isOpen(modal)}
      onClose={() => {
        if (onClose) {
          onClose()
        }
        closeCurrentModal()
      }}
      {...rest}
    >
      {children}
    </Modal>
  )
}

type OpenModalProps = PropsWithChildren<{
  modal: string
  onClick?: () => void
}>

function OpenModalButton({
  modal,
  onClick,
  children,
  ...rest
}: OpenModalProps &
  Omit<ComponentPropsWithoutRef<'button'>, keyof OpenModalProps>) {
  const {openModal} = useContext(ModalContext)
  return (
    <button
      onClick={e => {
        e.preventDefault()
        if (onClick) onClick()
        openModal(modal)
      }}
      {...rest}
    >
      {children}
    </button>
  )
}

function OpenModalDiv({
  modal,
  onClick,
  children,
  ...rest
}: OpenModalProps &
  Omit<ComponentPropsWithoutRef<'div'>, keyof OpenModalProps>) {
  const {openModal} = useContext(ModalContext)
  return (
    <div
      onClick={e => {
        e.preventDefault()
        if (onClick) onClick()
        openModal(modal)
      }}
      {...rest}
    >
      {children}
    </div>
  )
}

function OpenModalLink({
  modal,
  onClick,
  children,
  ...rest
}: OpenModalProps & Omit<ComponentPropsWithoutRef<'a'>, keyof OpenModalProps>) {
  const {openModal} = useContext(ModalContext)
  return (
    <a
      href=""
      onClick={e => {
        e.preventDefault()
        if (onClick) onClick()
        openModal(modal)
      }}
      {...rest}
    >
      {children}
    </a>
  )
}

type CloseModalProps = PropsWithChildren<{
  onClick?: () => void
}>

function CloseModalButton({
  onClick,
  children,
  ...rest
}: CloseModalProps &
  Omit<ComponentPropsWithoutRef<'button'>, keyof CloseModalProps>) {
  const {closeCurrentModal} = useContext(ModalContext)
  return (
    <button
      onClick={e => {
        e.preventDefault()
        closeCurrentModal()
        if (onClick) onClick()
      }}
      {...rest}
    >
      {children || 'Close'}
    </button>
  )
}

function CloseModalLink({
  onClick,
  children,
  ...rest
}: CloseModalProps &
  Omit<ComponentPropsWithoutRef<'a'>, keyof CloseModalProps>) {
  const {closeCurrentModal} = useContext(ModalContext)
  return (
    <a
      href=""
      onClick={e => {
        e.preventDefault()
        closeCurrentModal()
        if (onClick) onClick()
      }}
      {...rest}
    >
      {children}
    </a>
  )
}

export {
  CloseModalButton,
  CloseModalLink,
  ContextModal,
  ModalContext,
  ModalContextProvider,
  ModalContextType,
  NO_CURRENT_MODAL,
  OpenModalButton,
  OpenModalDiv,
  OpenModalLink,
  useModalContext,
}
