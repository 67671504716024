/** @format */
import isEqual from 'lodash.isequal'
import React from 'react'
import {useLocation} from 'react-router-dom'

import ajax from './ajax'
import {formbutton} from './utils'

export function logPageTransition(path) {
  let props,
    page,
    match = /\/forms\/(\w+)\/(\w+)/.exec(path)
  if (match) {
    page = 'Form Details'
    props = {form: match[1], tab: match[2]}
  } else {
    page = 'Page'
    props = {path: path}
  }
  amplitude.logEvent('Viewed ' + page, props)
  ajax({
    method: 'POST',
    endpoint: '/api-int/page-views',
    payload: {path: path},
  })

  formbutton()('set', {'Current Page': path})
}

class _PageTransition extends React.Component {
  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.location, prevProps.location)) {
      if (!this.props.location.state?.skipScrollTop) {
        window.scrollTo(0, 0)
      }
      logPageTransition(this.props.location.pathname)
    }
  }

  render() {
    return this.props.children
  }
}

export function PageTransition(props) {
  const location = useLocation()
  return <_PageTransition {...props} location={location} />
}
