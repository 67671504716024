/** @format */
import React, {ChangeEvent, useState} from 'react'

import ActionInput from '@src/components/tailwind/ActionInput'
import LoaderButton from '@src/components/tailwind/LoaderButton'
import Modal from '@src/components/tailwind/Modal'
import {
  ContextModal,
  useModalContext,
} from '@src/components/tailwind/ModalContext'

import {useAccountContext, useLoadingContext} from '@src/contexts'

import {addEmailAddress} from '../utils'

type Props = {
  modal: string
}

export default function AddEmailModal(props: Props) {
  const {modal} = props
  const {ready} = useLoadingContext()
  const {account, reloadAccount} = useAccountContext()
  const {closeCurrentModal} = useModalContext()
  const [newEmail, setNewEmail] = useState('')

  async function handleAddEmail() {
    await addEmailAddress(newEmail, {
      onSuccess: () => {
        closeCurrentModal()
        reloadAccount()
      },
    })
    ready()
  }

  return (
    <ContextModal
      modal={modal}
      title={'Add Linked Email'}
      onClose={() => {
        setNewEmail('')
      }}
    >
      {account.verified ? (
        <>
          <ActionInput
            label={'New Email Address'}
            value={newEmail}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setNewEmail(e.target.value)
            }}
          />
          <Modal.Footer>
            <LoaderButton onClick={handleAddEmail} disabled={!newEmail}>
              Add Email
            </LoaderButton>
          </Modal.Footer>
        </>
      ) : (
        <p className="text-red-500">
          The email address associated with this account must be verified before
          linking additional emails.
        </p>
      )}
    </ContextModal>
  )
}
