/** @format */
import React from 'react'
import type {RouteObject} from 'react-router-dom'

import Account from './Account'
import Billing from './Billing'
import Domains from './Domains'
import Team from './teams/Team'

export const accountsRouter = {
  path: 'account',
  children: [
    {
      index: true,
      element: <Account />,
    },
    {
      path: 'billing',
      element: <Billing />,
    },
    {
      path: 'domains',
      element: <Domains />,
    },
    {
      path: 'team',
      element: <Team />,
    },
  ],
} satisfies RouteObject
