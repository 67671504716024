/** @format */
import React from 'react'

import Button from '../../components/tailwind/Button'
import Splash from '../../components/tailwind/Splash'

export function ConvertToTeam({onConvertButtonClick}) {
  return (
    <Splash
      title={'Teams'}
      description={'Collaborate with teammates to create and manage forms'}
    >
      <Button primary onClick={onConvertButtonClick}>
        Convert to Team
      </Button>
    </Splash>
  )
}
