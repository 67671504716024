/** @format */
import React from 'react'

export default function Input(props) {
  return (
    <input
      className="shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-400 block w-full sm:text-sm"
      {...props}
    />
  )
}
