/** @format */

import {Dialog, Transition} from '@headlessui/react'
import {XIcon} from '@heroicons/react/solid'
import clsx from 'clsx'
import React, {
  createContext,
  Fragment,
  PropsWithChildren,
  useContext,
} from 'react'

export const Root = Modal
export const Description = Dialog.Description

export type ModalProps = PropsWithChildren<{
  config?: Config
  isOpen?: boolean
  onClose?: () => void
  // call when the leave animation ends, use to wait for state reset
  onDidClose?: () => void
}>

export function Modal(props: ModalProps) {
  const {
    children,
    config = defaultConfig,
    // isOpen cannot be undefined since Transition will throw
    // if the `show` props is undefined.
    isOpen = false,
    // Dialog requires onClose props, so we default it to a no-op.
    onClose = () => {},
    onDidClose,
  } = props

  return (
    <ConfigContext.Provider value={{...config, closeModal: onClose}}>
      <Transition show={isOpen} as={Fragment} afterLeave={onDidClose}>
        <Dialog className="fixed inset-0 z-40" onClose={onClose}>
          {/* Backdrop */}
          <Transition.Child
            aria-hidden="true"
            as="div"
            className="absolute inset-0 bg-black/50"
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="translate-y-16 opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="ease-out duration-200"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="translate-y-16 opacity-0"
          >
            <Dialog.Panel as="div" className="ModalV2_Box">
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </ConfigContext.Provider>
  )
}

/** Default Layout */

type DefaultLayoutProps = PropsWithChildren

export function DefaultLayout(props: DefaultLayoutProps) {
  const {children} = props
  // Maybe there's a better way to do this.
  //
  // We need to redeclare max-height to match Modal panel because
  // in flexbox, it seems like the max-height is ignored when you have
  // nested layout.
  //
  // Ideally, we want the height (limited by max-height) on Modal panel
  // to limit the height of all its decendents.
  return (
    <div className="md:flex md:max-h-[--modal-max-height] md:flex-col">
      {children}
    </div>
  )
}

/** Config */

// pass down as context that would affect styles e.g. padding of Header, Content, Footer
type Config = {
  closeModal?: () => void
  noCloseButton?: boolean
  variant: 'default' | 'full-bleed'
}

const defaultConfig: Config = {variant: 'default'}
const ConfigContext = createContext<Config>(defaultConfig)

function useConfig(): Config {
  return useContext(ConfigContext)
}

/** Header */

type HeaderProps = PropsWithChildren<{
  title?: string
}>

export function Header(props: HeaderProps) {
  const {children, title} = props
  const {closeModal, noCloseButton, variant} = useConfig()
  const padding = variant === 'default' ? 'p-4' : ''
  return (
    <div className={clsx('flex shrink-0 items-center gap-x-4', padding)}>
      {title ? <Title>{title}</Title> : children}
      {!noCloseButton && <CloseButton onClick={closeModal} />}
    </div>
  )
}

type TitleProps = {
  children: string
  className?: string
}

export function Title(props: TitleProps) {
  const {children, className = 'font-bold text-xl'} = props
  return <Dialog.Title className={className}>{children}</Dialog.Title>
}

type CloseButtonProps = {
  onClick?: () => void
}

function CloseButton(props: CloseButtonProps) {
  const {onClick} = props
  return (
    <button
      className="ml-auto shrink-0 rounded-lg text-gray-400 hover:text-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
      onClick={onClick}
      // prevent the button from trigger form submit event when wrapped inside a form
      type="button"
    >
      <span className="sr-only">Close</span>
      <XIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  )
}

/** Content */

type ContentProps = PropsWithChildren

export function Content(props: ContentProps) {
  const {children} = props
  const {variant} = useConfig()
  const padding = variant === 'default' ? 'p-4 pt-0' : ''
  return (
    <div className={clsx('flex-1 overflow-y-auto', padding)}>{children}</div>
  )
}

/** Footer */

type FooterProps = PropsWithChildren

export function Footer(props: FooterProps) {
  const {children} = props
  const {variant} = useConfig()
  const padding = variant === 'default' ? 'p-4 pt-0' : ''
  return (
    <div className={clsx('relative shrink-0', padding)}>
      <MoreContentGradiant />
      {children}
    </div>
  )
}

// gradiant at the bottom of the content to indicate content is scrollable.
//
// need to render in the Footer instead of Content since Content has overflow-y
// and absolute positioning is relative to the scroll viewport not bounding box
function MoreContentGradiant() {
  return (
    <div
      className="absolute -top-4 left-0 right-0 h-4 transform-gpu"
      style={{
        background: 'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))',
      }}
    />
  )
}
