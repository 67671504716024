/** @format */

import {ArrowCircleUpIcon} from '@heroicons/react/solid'
import React, {ComponentPropsWithoutRef, PropsWithChildren} from 'react'

import {UpgradeLink} from './UpgradeLink'

type UpgradeMessageProps = PropsWithChildren<{
  // legacy: to display after "Requires Upgrade" message
  plan?: string
  title?: string
}> &
  Omit<ComponentPropsWithoutRef<typeof UpgradeLink>, 'className'>

export function UpgradeMessage(props: UpgradeMessageProps) {
  const {children, plan, title, ...upgradeLinkProps} = props
  return (
    <div className="flex flex-col items-start gap-y-4">
      {title && <h3>{title}</h3>}
      <p className="flex items-center gap-x-1 font-semibold text-gray-700">
        <ArrowCircleUpIcon className="h-5 w-5 text-blue-700" />
        <span>Requires Upgrade</span>
        {plan && <span> to {plan}</span>}
      </p>
      {children && <p>{children}</p>}
      <UpgradeLink {...upgradeLinkProps} className="buttonPrimary">
        Upgrade
      </UpgradeLink>
    </div>
  )
}
