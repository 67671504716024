/** @format */

import {CheckIcon} from '@heroicons/react/outline'
import React, {useContext} from 'react'

import ComponentContainer from '@src/components/tailwind/ComponentContainer'
import ComponentGroup from '@src/components/tailwind/ComponentGroup'
import CompoundInput from '@src/components/tailwind/CompoundInput'
import {ModalContext} from '@src/components/tailwind/ModalContext'

import {WorkflowMigrationModal} from '@src/accounts/WorkflowMigrationModal'
import {useAccountContext, useFormspreeContext} from '@src/contexts'

import ChangeEmailModal from './AccountSettings/ChangeEmailModal'
import ChangeNameModal from './AccountSettings/ChangeNameModal'
import ChangePasswordModal from './AccountSettings/ChangePasswordModal'
import DeleteAccountModal from './AccountSettings/DeleteAccountModal'
import {WeeklyEmailDigestModal} from './AccountSettings/WeeklyEmailDigestModal'
import LinkedEmails from './LinkedEmails'
import {ProjectEndedPromoBanner} from './ProjectEndedPromoBanner'
import ChangeTeamNameModal from './teams/ChangeNameModal'
import {TwoFactorAuthenticationModal} from './TwoFactorAuthentication/TwoFactorAuthenticationModal'
import TwoFactorDetailsModal from './TwoFactorAuthentication/TwoFactorDetailsModal'
import UpgradeAccount from './UpgradeAccount'
import {Usage} from './Usage'

export const ACCOUNT_MODAL_NAMES = {
  FULL_NAME_MODAL: 'FULL_NAME_MODAL',
  EMAIL_MODAL: 'EMAIL_MODAL',
  PASSWORD_MODAL: 'PASSWORD_MODAL',
  TWO_FACTOR_AUTH_MODAL: 'TWO_FACTOR_AUTH_MODAL',
  WORKFLOW_MIGRATION_MODAL: 'WORKFLOW_MIGRATION_MODAL',
  TWO_FACTOR_DETAILS_MODAL: 'TWO_FACTOR_DETAILS_MODAL',
  TEAM_NAME_MODAL: 'TEAM_NAME_MODAL',
  ADD_EMAIL_MODAL: 'ADD_EMAIL_MODAL',
  DELETE_ACCOUNT_MODAL: 'DELETE_ACCOUNT_MODAL',
  WEEKLY_DIGEST_MODAL: 'WEEKLY_DIGEST_MODAL',
}

export default function Account() {
  const {account, emails, profile} = useAccountContext()
  const formspree = useFormspreeContext()
  const {openModal} = useContext(ModalContext)
  const allEmails = emails.verified.concat(emails.pending)

  return (
    <>
      <ChangeNameModal modal={ACCOUNT_MODAL_NAMES.FULL_NAME_MODAL} />
      <ChangeEmailModal modal={ACCOUNT_MODAL_NAMES.EMAIL_MODAL} />
      <ChangePasswordModal modal={ACCOUNT_MODAL_NAMES.PASSWORD_MODAL} />
      <ChangeTeamNameModal modal={ACCOUNT_MODAL_NAMES.TEAM_NAME_MODAL} />
      <DeleteAccountModal modal={ACCOUNT_MODAL_NAMES.DELETE_ACCOUNT_MODAL} />
      <TwoFactorAuthenticationModal />
      <TwoFactorDetailsModal />
      <WorkflowMigrationModal />
      <WeeklyEmailDigestModal modal={ACCOUNT_MODAL_NAMES.WEEKLY_DIGEST_MODAL} />

      <ProjectEndedPromoBanner />

      {account.kind === 'user' ? (
        <ComponentGroup title={'Account'} dividers={true}>
          {account.email && !account.has_password && (
            <div>
              <strong>YOU HAVE NO PASSWORD.</strong> Please{' '}
              <a
                className={'primaryLink'}
                href={`/login/reset?email=${account.email}`}
              >
                click here
              </a>{' '}
              to set one up.
            </div>
          )}
          {!account.email && (
            <div>
              <p>
                <strong>YOU HAVE NO LOGIN EMAIL ADDRESS.</strong>
              </p>
              <p className={'mt-2'}>
                Please edit your Account Email below to set one up.
              </p>
            </div>
          )}
          <CompoundInput
            modal={ACCOUNT_MODAL_NAMES.FULL_NAME_MODAL}
            label="Full Name"
            value={[profile.first_name, profile.last_name]
              .filter(k => k)
              .join(' ')}
          />
          <CompoundInput
            modal={ACCOUNT_MODAL_NAMES.EMAIL_MODAL}
            label="Account Email"
            value={profile.email}
          />
          <CompoundInput
            modal={ACCOUNT_MODAL_NAMES.PASSWORD_MODAL}
            label="Password"
            value="••••••••••••"
          />
          <CompoundInput
            action={
              profile.two_factor_authentication_enabled ? 'View' : 'Enable'
            }
            onClick={() => {
              if (profile.two_factor_authentication_enabled) {
                openModal(ACCOUNT_MODAL_NAMES.TWO_FACTOR_DETAILS_MODAL)
              } else {
                openModal(ACCOUNT_MODAL_NAMES.TWO_FACTOR_AUTH_MODAL)
              }
            }}
            label="Two Factor Authentication"
          >
            {profile.two_factor_authentication_enabled ? (
              <span className="mt-1 inline-flex items-center rounded bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800">
                Enabled <CheckIcon className={'ml-1 h-4 w-4'} />
              </span>
            ) : (
              <span className="mt-1 inline-flex items-center rounded bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800">
                Disabled
              </span>
            )}
          </CompoundInput>
          {formspree.ENABLE_EMAIL_DIGESTS &&
            account.flags.email_digest_group && (
              <div id="weekly-email-digest-option">
                <CompoundInput
                  action={
                    profile.flags.weekly_email_digest_disabled || false
                      ? 'Enable'
                      : 'Disable'
                  }
                  onClick={() => {
                    openModal(ACCOUNT_MODAL_NAMES.WEEKLY_DIGEST_MODAL)
                  }}
                  label="Weekly Email Digest"
                >
                  {profile.flags.weekly_email_digest_disabled || false ? (
                    <span className="mt-1 inline-flex items-center rounded bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800">
                      Disabled
                    </span>
                  ) : (
                    <span className="mt-1 inline-flex items-center rounded bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800">
                      Enabled <CheckIcon className={'ml-1 h-4 w-4'} />
                    </span>
                  )}
                </CompoundInput>
              </div>
            )}

          <CompoundInput
            action="Delete account"
            onClick={() => {
              openModal(ACCOUNT_MODAL_NAMES.DELETE_ACCOUNT_MODAL)
            }}
            label="Registered on "
            value={account.registered_on}
          />
        </ComponentGroup>
      ) : (
        <ComponentGroup title={'Team Account'} dividers={true}>
          <CompoundInput
            modal={ACCOUNT_MODAL_NAMES.TEAM_NAME_MODAL}
            label="Team Name"
            value={account.team_name}
          />
          <CompoundInput
            action="Delete account"
            onClick={() => {
              openModal(ACCOUNT_MODAL_NAMES.DELETE_ACCOUNT_MODAL)
            }}
            label="Team created "
            value={account.registered_on}
          />
        </ComponentGroup>
      )}

      <Usage />

      <ComponentContainer
        title="Linked Emails"
        action={
          allEmails.length < account.limits.linked_addresses.limit && (
            <button
              className="font-bold text-primary"
              onClick={() => openModal(ACCOUNT_MODAL_NAMES.ADD_EMAIL_MODAL)}
              type="button"
            >
              + Add Email
            </button>
          )
        }
        fullBleed={true}
      >
        <LinkedEmails />
      </ComponentContainer>
      {!account.flags.workflow_enabled_group && (
        <ComponentGroup id={'labs'} title={'Labs'} dividers={true}>
          <CompoundInput
            action={account.features.workflow ? 'Disable' : 'Enable'}
            onClick={() => {
              openModal(ACCOUNT_MODAL_NAMES.WORKFLOW_MIGRATION_MODAL)
            }}
            label="Workflow Beta"
          >
            {account.features.workflow ? (
              <span className="mt-1 inline-flex items-center rounded bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800">
                Enabled <CheckIcon className={'ml-1 h-4 w-4'} />
              </span>
            ) : (
              <span className="mt-1 inline-flex items-center rounded bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800">
                Disabled
              </span>
            )}
          </CompoundInput>
        </ComponentGroup>
      )}
      {account.product === 'free' && (
        <ComponentContainer>
          <UpgradeAccount />
        </ComponentContainer>
      )}
    </>
  )
}
