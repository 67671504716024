/** @format */

import React, {PropsWithChildren} from 'react'

export default function Table({
  columns,
  rows,
  dividers = true,
  fullBleed = false,
  empty,
}: {
  flex: string | string[]
  columns: string[]
  rows: (string | React.ReactNode)[][]
  dividers: boolean
  fullBleed: boolean
  chrome?: string
  empty?: React.ReactNode
}) {
  return (
    <table className="w-full">
      <thead className={'border-b border-gray-100'}>
        <tr>
          {columns.map((c, i) => (
            <th
              key={`th-${i}`}
              className={`py-2 pl-1 text-left text-xs font-medium uppercase tracking-wider text-gray-400 last:text-right ${
                fullBleed ? 'first:pl-0' : 'first:pl-8 last:pr-8'
              }`}
            >
              {c}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={`${dividers ? 'divide-y divide-gray-100' : ''}`}>
        {rows.length > 0 ? (
          rows.map((row, j) => (
            <tr key={`tr-${j}`}>
              {row.map((col, i) => (
                <td
                  key={`td-${i}`}
                  className={`pl-1 last:text-right ${
                    fullBleed ? 'first:pl-0' : 'first:pl-8 last:pr-8'
                  } ${dividers ? 'py-4' : j === 0 ? 'py-4' : 'pb-4'}`}
                >
                  {col}
                </td>
              ))}
            </tr>
          ))
        ) : empty ? (
          <tr>
            <td className={`${fullBleed ? '' : 'px-8'} py-4`}>{empty}</td>
          </tr>
        ) : null}
      </tbody>
    </table>
  )
}

export const Truncate = ({children}: PropsWithChildren) => {
  return (
    <div className={'relative'}>
      <div className={'absolute max-w-full truncate'}>{children}</div>
      <div className={'h-0 w-full overflow-hidden break-all'}>{children}</div>
      <span>&nbsp;</span>
    </div>
  )
}
