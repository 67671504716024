/** @format */

import ClipboardJS from 'clipboard'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'

import FormspreeSelect from '@src/components/tailwind/FormspreeSelect'

import * as toast from '@src/toast'

import {RequiredFieldMarker} from './RequiredFieldMarker'

export default function ActionInput({
  id,
  label,
  labelVariant = 'default',
  showRequiredMarker,
  description,
  value,
  codeValue,
  options,
  prefix,
  suffix,
  copyButton,
  goButton,
  children,
  readOnly,
  rowOnly,
  colSpan = 1,
  className,
  inputElement = 'input',
  noMargin = false,
  upgrade,
  ...attrs
}) {
  let clipboard
  const isReadOnly = copyButton || goButton || readOnly || upgrade
  useEffect(() => {
    if (copyButton) {
      clipboard = new ClipboardJS(
        `button.copyButton[data-key="${attrs.name || label}"]`
      )
      clipboard.on('success', function (e) {
        toast.success('Copied to clipboard.')
      })
    }

    return () => {
      if (copyButton) {
        clipboard.destroy()
      }
    }
  }, [])

  function renderInput() {
    const Input = inputElement
    return (
      <div className="flex rounded focus-within:ring-1 focus-within:ring-gray-400">
        {prefix && (
          <span className="inline-flex items-center rounded-l bg-gray-200 px-3 text-gray-500 sm:text-sm">
            {prefix}
          </span>
        )}
        <Input
          id={id}
          value={value || ''}
          readOnly={isReadOnly}
          className={clsx(
            'block w-full min-w-0 flex-1 px-3 py-2',
            'bg-gray-100 text-secondary placeholder-gray-400',
            // clear global style, we set focus state on the wrapper
            'focus:ring-0',
            // clear global style on input
            'mb-0',
            // a hack to elevate the input so the focus outline
            // is not clipped by suffix
            'z-0',
            {
              // need rounded-none to overwrite global in either case (or both)
              'rounded-none': prefix || suffix,
              'rounded-r': prefix && !suffix,
              'rounded-l': !prefix && suffix
            },
            codeValue && 'font-mono',
            isReadOnly && 'focus:outline-none focus:ring-0',
            className
          )}
          {...attrs}
        />
        {suffix && (
          <span className="inline-flex items-center rounded-r bg-gray-200 px-3 text-gray-500 sm:text-sm">
            {suffix}
          </span>
        )}
      </div>
    )
  }

  function renderSelect() {
    return (
      <FormspreeSelect
        id={id}
        disabled={readOnly || upgrade}
        options={options}
        value={value}
        {...attrs}
      />
    )
  }

  function renderRow() {
    return (
      <div className="flex items-start gap-1">
        <div className="grow" colSpan={colSpan}>
          {options ? renderSelect() : renderInput()}
        </div>
        <div className="flex shrink-0 gap-1">
          {copyButton && (
            <button
              className="copyButton buttonSecondary"
              data-key={attrs.name || label}
              data-clipboard-text={value}
            >
              Copy
            </button>
          )}
          {children}
          {goButton && (
            <button
              className="buttonPrimary"
              onClick={e => {
                e.preventDefault
                window.open(value, '_blank')
              }}
            >
              Go
            </button>
          )}
        </div>
      </div>
    )
  }

  function renderLabel() {
    return (
      <>
        {label &&
          (typeof label === 'string' ? (
            <div
              className={clsx(
                'my-2 w-full',
                labelVariant === 'subtle'
                  ? 'font-medium text-gray-600'
                  : 'font-bold'
              )}
            >
              <label htmlFor={id}>{label}</label>
              {showRequiredMarker && <RequiredFieldMarker />}
            </div>
          ) : (
            label
          ))}
      </>
    )
  }

  return (
    <>
      {rowOnly ? (
        renderRow()
      ) : (
        <div className={noMargin ? '' : 'my-4'}>
          {renderLabel()}
          {renderRow()}

          {description || upgrade ? (
            <div className="mt-2 text-sm text-gray-500">
              {description && <span>{description}</span>}
              {upgrade && (
                // keep a space between the upgrade link and the description
                <> {upgrade}</>
              )}
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}

ActionInput.propTypes = {
  inputElement: PropTypes.oneOf(['input', 'textarea'])
}
