/** @format */

import {RefreshIcon, TrashIcon} from '@heroicons/react/outline/esm'
import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'

import {AnalyticsEvent, sendAnalytics} from '@src/analytics'

import {AccountContext} from '../contexts/AccountContext'
import {LoadingContext} from '../contexts/LoadingContext'
import ajax from '../ajax'
import {ContextModal, ModalContext} from '../components/tailwind/ModalContext'
import Table, {Truncate} from '../components/tailwind/Table'
import Badge from '../components/tailwind/Badge'
import {addEmailAddress} from './utils'
import AddEmailModal from './AccountSettings/AddEmailModal'
import {ACCOUNT_MODAL_NAMES} from './Account'

export default function LinkedEmails() {
  const {account, emails, reloadAccount} = useContext(AccountContext)
  const {openModal, closeCurrentModal} = useContext(ModalContext)
  const {ready} = useContext(LoadingContext)
  let allEmails = emails.verified.concat(emails.pending)

  const [state, setState] = useState({
    modalTitle: null,
    modalBody: null
  })

  function showModal(modalTitle, modalBody) {
    setState(state => {
      openModal('EMAIL_WARNING_MODAL')
      return {...state, modalBody, modalTitle}
    })
  }

  async function handleDeleteEmail(e) {
    e.preventDefault()

    let address = e.currentTarget.dataset.address
    let force = e.currentTarget.dataset.force

    await ajax({
      method: 'DELETE',
      endpoint: `/api-int/account/emails/${address}${
        force ? '?force=true' : ''
      }`,
      successMsg: `${address} deleted successfully from your account.`,
      onSuccess: async r => {
        await reloadAccount()
      },
      onError: async r => {
        const forms = r.forms || r.rule_forms
        const body = (
          <>
            <p>{r.error}</p>
            {forms && (
              <div className={'mt-2'}>
                <strong>Affected forms:</strong>
                <ul className="delete-emails">
                  {forms.map((f, i) => (
                    <li key={i}>
                      {f.name} (
                      <a
                        className={'primaryLink'}
                        href={`/forms/${f.hashid}/${
                          r.rule_forms ? 'routing' : 'settings'
                        }`}
                      >
                        {r.rule_forms ? 'view rules' : 'view'}
                      </a>
                      )
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )
        if (r.needs_force) {
          showModal(
            'Warning',
            <div>
              {body}
              <div className="text-right">
                <button
                  data-address={address}
                  data-force={true}
                  onClick={handleDeleteEmail}
                  className="buttonPrimary"
                >
                  Continue
                </button>
              </div>
            </div>
          )
        } else {
          showModal(
            "Error: Couldn't delete email",
            <div>
              {body}
              <div className="text-right">
                <button
                  className="buttonSecondary"
                  onClick={() => {
                    closeCurrentModal()
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          )
        }
      }
    })

    ready()
  }

  return (
    <>
      <AddEmailModal modal={ACCOUNT_MODAL_NAMES.ADD_EMAIL_MODAL} />
      <ContextModal modal="EMAIL_WARNING_MODAL" title={state.modalTitle}>
        {state.modalBody}
      </ContextModal>
      <Table
        dividers={false}
        columns={['Address', 'Status', 'Actions']}
        rows={emails.pending
          .map(email => [
            <Truncate>{email}</Truncate>,
            <Badge status="warning">Pending</Badge>,
            <>
              <a
                onClick={() => {
                  addEmailAddress(email)
                }}
              >
                <RefreshIcon
                  className={'w-5 inline-block primaryLink cursor-pointer'}
                />
              </a>{' '}
              {email !== account.email && (
                <a data-address={email} onClick={handleDeleteEmail}>
                  <TrashIcon
                    className={'w-5 inline-block primaryLink cursor-pointer'}
                  />
                </a>
              )}
            </>
          ])
          .concat(
            emails.verified.map(email => [
              <Truncate>{email}</Truncate>,
              <Badge status="ok">Verified</Badge>,
              email !== account.email && (
                <a data-address={email} onClick={handleDeleteEmail}>
                  <TrashIcon
                    className={'w-5 inline-block primaryLink cursor-pointer'}
                  />
                </a>
              )
            ])
          )}
      />
      {account.limits.linked_addresses.infinite || allEmails.length === 0 ? (
        <p className={'mx-8 my-6'}>
          Add an email address to send form submission notifications.{' '}
          <a
            href="https://help.formspree.io/hc/en-us/articles/115008379348-Changing-a-form-email-address"
            target="_blank"
            className="primaryLink"
          >
            Learn more →
          </a>
        </p>
      ) : (
        <p className={'mx-8 my-6'}>
          <span>
            {allEmails.length} of {account.limits.linked_addresses.limit} email
            addresses added.{' '}
          </span>
          <Link
            className="primaryLink"
            onClick={() =>
              sendAnalytics(AnalyticsEvent.upgrade_clicked, {
                feature: 'linked_addresses'
              })
            }
            to="/plans"
          >
            Upgrade
          </Link>
          <span>&nbsp;to add unlimited addresses.</span>
        </p>
      )}
    </>
  )
}
