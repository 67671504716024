/** @format */

import {PaymentMethod} from '@stripe/stripe-js'
import React, {ReactNode} from 'react'

import * as Modal from '@src/components/tailwind/ModalV2'

import {Plan, Product, useAccountContext} from '@src/contexts'

import {WithNewCard} from './WithNewCard'
import {WithSavedCard} from './WithSavedCard'

type UpgradeSubscriptionModalProps = {
  description?: ReactNode
  isOpen: boolean
  onClose: () => void

  onConfirmUpgrade: (
    plan: Plan,
    paymentMethod?: PaymentMethod,
  ) => Promise<void> | void

  options: Product['id'][]
  title?: string
}

export function UpgradeSubscriptionModal(props: UpgradeSubscriptionModalProps) {
  const {
    isOpen,
    onClose,
    title = 'Upgrade Subscription',
    ...passthroughProps
  } = props

  const {account, card} = useAccountContext()
  const discount =
    account.product === 'free' &&
    account.flags.project_ended_promo_group &&
    !account.flags.project_ended_promo_redeemed
      ? 'Project ended promo 20% off'
      : undefined

  return (
    <Modal.Root isOpen={isOpen} onClose={onClose}>
      {card ? (
        <WithSavedCard
          {...passthroughProps}
          card={card}
          discount={discount}
          isOpen={isOpen}
          title={title}
        />
      ) : (
        <WithNewCard
          {...passthroughProps}
          discount={discount}
          isOpen={isOpen}
          title={title}
        />
      )}
    </Modal.Root>
  )
}
