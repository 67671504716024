/** @format */

import {useContext} from 'react'

import {LoadingContextType, ProjectsContextType} from '@src/types/contexts'

import {LoadingContext} from './LoadingContext'
import {ProjectsContext} from './ProjectsContext'

export function useLoadingContext(): LoadingContextType {
  return useContext(LoadingContext)
}

export function useProjectsContext(): ProjectsContextType {
  return useContext(ProjectsContext)
}

export {useAllFormsContext} from './AllFormsContext'
export {useFormApi} from './FormApi'

export * from './FormspreeContext'
export * from './PluginContext'
export * from './ProductsContext'

export {useAccountContext} from './useAccountContext'
export {useCurrentForm} from './useCurrentForm'
