/** @format */

import React, {Fragment, PropsWithChildren} from 'react'

import {getChildrenAsElements} from '../../typeUtils'

const ComponentGroup: React.FC<
  PropsWithChildren<{
    title?: string
    id?: string
    action?: React.ReactNode
    fullBleed?: boolean
    dividers?: boolean
    chrome?: string
  }>
> & {
  Header: React.FC<PropsWithChildren>
  Footer: React.FC<PropsWithChildren>
} = ({
  title,
  id,
  action,
  fullBleed = false,
  dividers = false,
  chrome = 'rounded-lg mx-2 sm:mx-8 my-8',
  children,
}) => {
  const headerChildren = getChildrenAsElements(children)
    .filter(child => child.type === ComponentGroup.Header)
    .map((child, i) => <Fragment key={i}>{child}</Fragment>)

  const footerChildren = getChildrenAsElements(children)
    .filter(child => child.type === ComponentGroup.Footer)
    .map((child, i) => <Fragment key={i}>{child}</Fragment>)

  const bodyChildren = getChildrenAsElements(children)
    .filter(
      child =>
        child.type !== ComponentGroup.Header &&
        child.type !== ComponentGroup.Footer,
    )
    .map((child, i) => (
      <div key={i} className={`${fullBleed ? '' : 'my-4 px-8'}`}>
        {child}
      </div>
    ))

  return (
    <>
      <section id={id} className={`flex flex-col bg-white ${chrome}`}>
        {title && (
          <div
            className={`flex w-full items-center justify-between border-b border-gray-100 px-8 py-4`}
          >
            <h1 className="text-xs font-bold uppercase tracking-wider text-gray-500">
              {title}
            </h1>
            {action}
          </div>
        )}
        {headerChildren}
        <div className={`${dividers ? 'divide-y divide-gray-100' : ''}`}>
          {bodyChildren}
        </div>
      </section>
      {footerChildren}
    </>
  )
}

ComponentGroup.Header = ({children}) => <>{children}</>
ComponentGroup.Footer = ({children}) => <>{children}</>

export default ComponentGroup
