/** @format */

import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'

import {Alert} from '@src/components/tailwind/Alert'
import LoaderButton from '@src/components/tailwind/LoaderButton'

import ajax from '../ajax'
import ComponentContainer from '../components/tailwind/ComponentContainer'
import ComponentGroup from '../components/tailwind/ComponentGroup'
import CompoundInput from '../components/tailwind/CompoundInput'
import Modal from '../components/tailwind/Modal'
import {ContextModal, ModalContext} from '../components/tailwind/ModalContext'
import {AccountContext} from '../contexts/AccountContext'
import {LoadingContext} from '../contexts/LoadingContext'
import PaymentCard from './PaymentCard'
import UpdateCardModal from './UpdateCardModal'

const stripeInstance = Stripe(formspree.STRIPE_PUBLISHABLE_KEY)
const MODAL_EDIT_INVOICE = 'MODAL_EDIT_INVOICE'
const MODAL_UPDATE_CARD = 'MODAL_UPDATE_CARD'

export default function Payment() {
  const {account, sub, card, updateAccount, reloadAccount} =
    useContext(AccountContext)
  const {ready} = useContext(LoadingContext)
  const {closeCurrentModal} = useContext(ModalContext)
  let [invoiceAddress, setInvoiceAddress] = useState(
    account.invoice_address || '',
  )

  async function addCard(paymentMethod) {
    await ajax({
      endpoint: '/api-int/account/cards',
      payload: {paymentMethod: paymentMethod},
      onSuccess: async () => {
        closeCurrentModal()
        await reloadAccount()
      },
      errorMsg:
        'An error occurred and we were unable to add your card. Please ' +
        'try again later or contact support for further assistance',
      successMsg: 'New card added successfully.',
    })

    ready()
  }

  const updateInvoiceAddress = async changes => {
    await ajax({
      method: 'PATCH',
      endpoint: '/api-int/account',
      payload: {invoice_address: invoiceAddress},
      onSuccess: () => {
        updateAccount(acct => {
          acct.account = {...acct.account, ...{invoice_address: invoiceAddress}}
          return {...acct}
        })
        closeCurrentModal()
      },
      errorMsg:
        'We were unable to change your invoice address. Please try again ' +
        'later or contact support for further assistance',
      successMsg: 'Billing address changed',
    })
    ready()
  }

  return account.emails.verified.includes(account.email) ||
    account.kind === 'team' ? (
    <ComponentGroup title="Payment" dividers={true}>
      <ComponentGroup.Header>
        <UpdateCardModal modal={MODAL_UPDATE_CARD} addCard={addCard} />
        <ContextModal
          modal={MODAL_EDIT_INVOICE}
          title="Edit Invoice Address"
          className="narrow"
        >
          <textarea
            rows="4"
            name="invoice-address"
            value={invoiceAddress}
            onChange={e => setInvoiceAddress(e.target.value)}
            placeholder={`Company Inc.
711-2880 Nulla St.
Mankato Mississippi 96522`}
          />
          <Modal.Footer>
            <LoaderButton
              onClick={updateInvoiceAddress}
              className="buttonPrimary card"
            >
              Update Invoice Address
            </LoaderButton>
          </Modal.Footer>
        </ContextModal>

        {sub && !sub.cancel_at_period_end && !card ? (
          <Alert type="warning">
            <div className="text-sm text-yellow-700">
              In order to renew your subscription, please make sure to add a
              payment method by {sub.current_period_end}.
            </div>
          </Alert>
        ) : null}
      </ComponentGroup.Header>

      <CompoundInput label="Payment Method" modal={MODAL_UPDATE_CARD}>
        <PaymentCard />
      </CompoundInput>

      <CompoundInput label={'Billing Address'} modal={MODAL_UPDATE_CARD}>
        {card ? (
          <div className={'whitespace-pre-line'}>
            {[
              card.name,
              card.address_line1,
              [
                card.address_city,
                card.address_state,
                card.address_zip,
                card.address_country,
              ]
                .filter(part => part)
                .join(', '),
            ]
              .filter(part => part)
              .join('\n')}
          </div>
        ) : (
          <div className="text-gray-500">No payment method on file</div>
        )}
      </CompoundInput>

      <CompoundInput label={'Invoice Address'} modal={MODAL_EDIT_INVOICE}>
        {account.invoice_address ? (
          <div className="whitespace-pre-line">{account.invoice_address}</div>
        ) : (
          <div className="text-gray-500">No invoice address on file</div>
        )}
      </CompoundInput>
    </ComponentGroup>
  ) : (
    <ComponentContainer title="Payment">
      Please <Link to="/account">verify your account email</Link> to update your
      card
    </ComponentContainer>
  )
}
