/** @format */

import * as Sentry from '@sentry/react'

import ajax from '@src/ajax'

export const AnalyticsEvent = Object.freeze({
  upgrade_clicked: 'Upgrade Clicked',
})

export function sendAnalytics(
  event_type: (typeof AnalyticsEvent)[keyof typeof AnalyticsEvent],
  event_properties?: Record<string, unknown>,
): void {
  // fire and forget
  ajax({
    method: 'POST',
    endpoint: '/api-int/analytics-events',
    onError(err: Error) {
      Sentry.captureException(err, {
        tags: {location: 'send-analytics'},
      })
    },
    payload: {
      event_type,
      event_properties,
    },
  })
}
