/** @format */

import {useContext} from 'react'

import {AccountContextType} from '@src/types/contexts'

import {AccountContext} from './AccountContext'

export function useAccountContext(): AccountContextType {
  return useContext(AccountContext)
}
