/** @format */

import React from 'react'

import {isPlanInProduct} from '@src/plans/utils'

/** @format */

export function get_csrf_token() {
  const cookie_name = 'csrf_access_token'
  const value = document.cookie.match('(^|;) ?' + cookie_name + '=([^;]*)(;|$)')
  return value ? value[2] : null
}

export function getLimit(account, limit) {
  if (!account.limits[limit]) return undefined
  return account.limits[limit].infinite ? Infinity : account.limits[limit].limit
}

export function formbutton() {
  return window.formbutton || function () {}
}

export function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export function getProduct(id) {
  const products = window.formspree.products.filter(prod => prod.id === id)
  return products.length > 0 ? products[0] : undefined
}

export function getFormName(form) {
  return form.name ? (
    form.name
  ) : (
    <>
      <span className="code">
        {!form.legacy ? (
          form.hashid
        ) : form.host ? (
          <>
            {form.host}
            {form.sitewide ? (form.host.slice(-1)[0] === '/' ? '*' : '/*') : ''}
          </>
        ) : (
          form.email
        )}
      </span>
    </>
  )
}

export function getDefaultFormPageUrl(form) {
  const {counter, hashid} = form
  return counter > 0
    ? `/forms/${hashid}/overview`
    : `/forms/${hashid}/integration`
}

export function isTrialPlan(id) {
  return id.endsWith('_trial')
}

export function isLegacyPlan(plan) {
  const products = formspree.products.filter(i => i.available)
  return (
    plan &&
    0 === products.filter(prod => isPlanInProduct(plan, prod)).length &&
    !isTrialPlan(plan)
  )
}

export function featureTrialMinimumProduct(productId) {
  if (!isTrialPlan(productId)) {
    return undefined
  }

  return formspree.products.find(
    product =>
      product.id ===
      formspree.products.find(product => product.id === productId)
        .available_products[0],
  )
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function isValidEmail(email) {
  let emailRegex =
    /^(?!\.)((?!.*\.{2})[a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~\-\d]+)@(?!\.)([a-zA-Z0-9\-.\d]+)((\.([a-zA-Z]){2,63})+)$/g
  return emailRegex.test(email)
}

export function isValidURL(url) {
  let pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator
  return !!pattern.test(url)
}
