/** @format */

import {Elements} from '@stripe/react-stripe-js'
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js'
import React, {PropsWithChildren, useRef} from 'react'

import {useFormspreeContext} from '@src/contexts'

export function StripeElementsProvider(props: PropsWithChildren) {
  const {children} = props
  const formspree = useFormspreeContext()
  const stripePK = formspree.STRIPE_PUBLISHABLE_KEY
  if (!stripePK) {
    throw new Error('Cannot load Stripe: missing publishable key')
  }
  const stripe = useRef(loadStripe(stripePK))
  return (
    <Elements options={options} stripe={stripe.current}>
      {children}
    </Elements>
  )
}

const options: StripeElementsOptions = {
  appearance: {
    rules: {
      // styles to match formspree input
      '.Input': {
        backgroundColor: '#f3f4f6', // gray-100
        border: 'none',
        boxShadow: 'none',
        fontFamily:
          "'Inter', system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, sans-serif",
        lineHeight: '1.25',
        padding: '0.75rem 1rem',
        transition: 'none',
      },
      '.Input:focus': {
        boxShadow: '0 0 0 1px #9ca3af inset', // ring-1 ring-gray-400 ring-inset
      },
    },
  },
}
