/** @format */

import React, {createContext, useEffect, useState} from 'react'

import {FullPageLoading} from '@src/components/tailwind/Loading'

import ajax from '@src/ajax'
import {formbutton} from '@src/utils'

const amplitude = window.amplitude

export const AccountContext = createContext({})

export const AccountContextProvider = props => {
  const [accountState, updateAccountState] = useState({})
  const [ready, setReady] = useState(false)

  /* TODO: Why do we filter the payload in doUpdate?
           If there is a good reason, then shouldn't we be using this
           function throughout rather than exposing updateAccountState directly
           to AccountContext consumers? */

  const doUpdate = attrs => {
    updateAccountState(prev => {
      let result = prev ? {...prev} : {}
      ;[
        'account',
        'profile',
        'emails',
        'sub',
        'projects',
        'domains',
        'card',
        'memberships',
        'notifications',
      ].forEach(key => {
        if (key in attrs) {
          result = {...result, [key]: attrs[key]}
        }
      })
      return result
    })
  }

  const reloadAccount = async (opts = {}, showLoading = true) => {
    if (showLoading) {
      setReady(false)
    }
    return ajax({
      method: 'GET',
      endpoint: '/api-int/account',
      errorMsg: 'Failed to fetch your account data',
      onSuccess: r => {
        doUpdate(r)
        formbutton()('set', {
          email: r.profile.email
            ? r.profile.email
            : r.emails.verified.length
              ? r.emails.verified[0]
              : undefined,
          Plan: r.account.plan,
          'User Since': r.account.registered_on,
          'Verified Emails': r.emails.verified.join(' '),
          'Pending emails': r.emails.pending.join(' '),
        })
        const ampInst = amplitude && amplitude.getInstance()
        if (ampInst) {
          ampInst.setUserId(r.profile.id)
          if (r.profile.email) {
            const identify = new amplitude.Identify()
            identify.set('e', r.profile.email)
            amplitude.identify(identify)
          }
        }
        if (showLoading) {
          setReady(true)
        }
      },
      ...opts,
    })
  }

  const patchAccount = async ({
    onSuccess,
    successMsg = 'Successfully updated account information',
    profile = false,
    payload,
  }) => {
    await ajax({
      method: 'PATCH',
      endpoint: `/api-int/account/`,
      payload,
      errorMsg: 'Failed to update account information',
      successMsg,
      onSuccess: result => {
        doUpdate({[profile ? 'profile' : 'account']: result.user})
        onSuccess && onSuccess()
      },
    })
  }

  useEffect(() => {
    reloadAccount({errorMsg: ''})
  }, [])

  if (!ready && !props.userOptional) {
    return <FullPageLoading />
  }

  return (
    <AccountContext.Provider
      value={{
        ...accountState,
        updateAccount: updateAccountState, // TODO: see comment above about doUpdate
        reloadAccount,
        patchAccount,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  )
}
