/** @format */

import React, {useContext, useState} from 'react'
import ajax from '../../ajax'
import {
  ContextModal,
  ModalContext
} from '../../components/tailwind/ModalContext'
import {ACCOUNT_MODAL_NAMES} from '../Account'
import {LoadingContext} from '../../contexts/LoadingContext'
import {TwoFactorInfo} from './TwoFactorInfo'
import {TwoFactorQRCode} from './TwoFactorQRCode'
import {TwoFactorBackupCodes} from './TwoFactorBackupCodes'
import {AccountContext} from '../../contexts/AccountContext'
import {TwoFactorDisabled} from './TwoFactorDisabled'

const SETUP_STEPS = {
  INFORMATION: 0,
  SCAN_CODE: 1,
  VERIFICATION: 2
}
export function TwoFactorAuthenticationModal() {
  const {ready} = useContext(LoadingContext)
  const {profile, updateAccount} = useContext(AccountContext)
  const {closeCurrentModal} = useContext(ModalContext)
  let [step, setStep] = useState(SETUP_STEPS.INFORMATION)
  let [provisioningInfo, setProvisioningInfo] = useState({})

  const nextStep = async data => {
    switch (step) {
      case SETUP_STEPS.INFORMATION:
        await requestTwoFactorAuthSetupCode()
        setStep(SETUP_STEPS.SCAN_CODE)
        ready()
        break
      case SETUP_STEPS.SCAN_CODE:
        let codeVerified = await verifyTwoFactorCode(data.otpCode)
        ready()
        if (codeVerified) {
          setStep(SETUP_STEPS.VERIFICATION)
        }
        break
      case SETUP_STEPS.VERIFICATION:
        closeCurrentModal()
    }
  }

  const requestTwoFactorAuthSetupCode = async () => {
    await ajax({
      method: 'POST',
      endpoint: `/api-int/account/two_factor_authentication/setup`,
      errorMsg: 'Failed to set up 2 factor authentication',
      onSuccess: result => {
        setProvisioningInfo({
          ...provisioningInfo,
          provisioning_uri: result.provisioning_uri,
          totp_secret: result.totp_secret,
          backup_codes: result.backup_codes
        })
      },
      onError: () => {}
    })
    ready()
  }

  const verifyTwoFactorCode = async otpCode => {
    let codeVerified = false
    await ajax({
      method: 'POST',
      endpoint: `/api-int/account/two_factor_authentication/verify`,
      errorMsg: 'Failed to verify 2 factor code',
      payload: {
        code: otpCode
      },
      onSuccess: () => {
        codeVerified = true
        updateAccount(acct => {
          acct.profile.two_factor_authentication_enabled = true
          return {...acct}
        })
      }
    })
    return codeVerified
  }

  let modalContent
  let modalTitle

  switch (step) {
    case SETUP_STEPS.INFORMATION:
      modalContent = profile.verified ? (
        <TwoFactorInfo nextStep={nextStep} />
      ) : (
        <TwoFactorDisabled />
      )
      break
    case SETUP_STEPS.SCAN_CODE:
      modalContent = (
        <TwoFactorQRCode
          provisioningInfo={provisioningInfo}
          nextStep={nextStep}
        />
      )
      modalTitle = 'Add QR Code'
      break
    case SETUP_STEPS.VERIFICATION:
      modalContent = (
        <TwoFactorBackupCodes
          backupCodes={provisioningInfo.backup_codes}
          nextStep={nextStep}
        />
      )
      modalTitle = 'Save Backup Codes'
      break
  }

  return (
    <ContextModal
      modal={ACCOUNT_MODAL_NAMES.TWO_FACTOR_AUTH_MODAL}
      title={modalTitle}
    >
      <div className="prose">{modalContent}</div>
    </ContextModal>
  )
}
