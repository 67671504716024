/** @format */

import {LockClosedIcon} from '@heroicons/react/solid'
import React, {PropsWithChildren} from 'react'

import {OpenModalLink} from './ModalContext'

export default function CompoundInput({
  label,
  modal,
  description,
  value,
  editable = true,
  action,
  onClick,
  children,
  showUpgrade,
  fullWidth = false,
}: PropsWithChildren<{
  label: string
  modal?: string
  description?: string
  value?: string
  editable?: boolean
  action?: string
  onClick?: React.ReactEventHandler
  showUpgrade?: boolean
  rest?: any
  fullWidth?: boolean
}>) {
  function renderLabel() {
    return (
      <>
        {label && (
          <div className="font-bold">
            <h4>{label}</h4>
          </div>
        )}
      </>
    )
  }

  function renderValue() {
    return (
      <>
        {value && (
          <span className={'mt-2 font-medium text-gray-900'}>{value}</span>
        )}
        {children && <span className={'mt-2'}>{children}</span>}
      </>
    )
  }

  function renderDescription() {
    return (
      <>
        {description && (
          <div className="mt-2 text-sm text-gray-500">
            <span>{description}</span>
            {showUpgrade && (
              <a href="/plans" className="primaryLink ml-1">
                <LockClosedIcon className="inline-block h-4 w-4 align-text-bottom" />
                <span>&nbsp;Upgrade to unlock</span>
              </a>
            )}
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <div className="my-4 flex w-full items-center justify-between">
        <div className={`${fullWidth ? 'w-full' : ''}`}>
          {renderLabel()}
          {renderValue()}
          {renderDescription()}
        </div>
        {editable && !action && modal && (
          <div>
            <OpenModalLink modal={modal} className={'font-bold text-primary'}>
              Edit
            </OpenModalLink>
          </div>
        )}
        {action && (
          <div>
            <a
              onClick={onClick}
              className={'primaryLink cursor-pointer hover:no-underline'}
            >
              {action}
            </a>
          </div>
        )}
      </div>
    </>
  )
}
