/** @format */

import React, {lazy, useEffect} from 'react'
import {Navigate, Outlet, type RouteObject} from 'react-router-dom'

import {useCurrentForm} from '@src/contexts'
import {FormApiProvider} from '@src/contexts/FormApi'
import {useDefaultForm} from '@src/hooks/useDefaultForm'
import {getDefaultFormPageUrl} from '@src/utils'

const Analyze = lazy(() => import('./Analyze'))
const Integration = lazy(() => import('./tailwind/Integration'))
const Overview = lazy(() => import('./tailwind/Overview'))
const Plugins = lazy(() => import('./tailwind/Plugins'))
const Rules = lazy(() => import('./tailwind/Rules'))
const Settings = lazy(() => import('./Settings'))
const Submissions = lazy(() => import('./tailwind/Submissions'))
const Workflow = lazy(() => import('./Workflow'))

export const formPageRouter = {
  path: '*',
  element: <FormPageProvider />,
  children: [
    {
      index: true,
      element: <FormPageIndex />,
    },
    {
      path: 'analyze',
      element: <Analyze />,
    },
    {
      path: 'integration',
      element: <Integration />,
    },
    {
      path: 'overview',
      element: <Overview />,
    },
    {
      path: 'plugins',
      element: <Plugins />,
    },
    {
      path: 'rules',
      element: <Rules />,
    },
    {
      path: 'settings',
      element: <Settings />,
    },
    {
      path: 'submissions',
      element: <Submissions />,
    },
    {
      path: 'workflow',
      element: <Workflow />,
    },
  ],
} satisfies RouteObject

function FormPageProvider() {
  const currentForm = useCurrentForm()
  const defaultForm = useDefaultForm()

  useEffect(() => {
    if (currentForm) {
      localStorage?.setItem('currentHashid', currentForm.hashid)
    } else if (defaultForm) {
      localStorage?.setItem('currentHashid', defaultForm.hashid)
    } else {
      localStorage?.removeItem('currentHashid')
    }
  }, [currentForm, defaultForm])

  if (!currentForm) {
    const to = defaultForm ? `/forms/${defaultForm.hashid}` : '/forms'
    return <Navigate replace to={to} />
  }

  return (
    <FormApiProvider hashid={currentForm.hashid}>
      <Outlet />
    </FormApiProvider>
  )
}

function FormPageIndex() {
  const currentForm = useCurrentForm()
  return <Navigate replace to={getDefaultFormPageUrl(currentForm)} />
}
