/** @format */

import {useStripe} from '@stripe/react-stripe-js'
import React, {useContext, useEffect, useMemo, useState} from 'react'

import {Alert} from '@src/components/tailwind/Alert'
import LoaderButton from '@src/components/tailwind/LoaderButton'
import {Loading} from '@src/components/tailwind/Loading'
import {useModalContext} from '@src/components/tailwind/ModalContext'

import ajax from '@src/ajax'
import {useAccountContext, useLoadingContext} from '@src/contexts'
import * as toast from '@src/toast'

import PaymentCard from './PaymentCard'

export default function PayInvoiceForm(props) {
  let [invoice, setInvoice] = useState()
  let [showExpandedCharges, setShowExpandedCharges] = useState(false)
  const {card} = useAccountContext()
  const {ready} = useLoadingContext()
  const {closeCurrentModal} = useModalContext()
  const stripe = useStripe()

  const dateFormat = useMemo(
    () =>
      new Intl.DateTimeFormat(
        undefined,
        {
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          month: 'short',
          year: 'numeric',
        },
        [],
      ),
  )

  useEffect(() => {
    fetchInvoice()
  }, [props.invoiceId])

  const fetchInvoice = async () => {
    await ajax({
      method: 'GET',
      endpoint: `/api-int/account/invoices/${props.invoiceId}`,
      onSuccess: response => {
        setInvoice(response)
      },
      errorMsg: 'Failed to retrieve invoice',
      encourageRetry: true,
    })
  }

  const payInvoice = async () => {
    await ajax({
      method: 'POST',
      endpoint: `/api-int/account/invoices/${props.invoiceId}/pay`,
      errorMsg: 'Failed to pay invoice',
      onSuccess: async data => {
        if (data.payment_intent && data.payment_intent.status !== 'succeeded') {
          await stripe
            .confirmCardPayment(data.payment_intent.client_secret, {
              payment_method: data.payment_method,
              setup_future_usage: 'off_session',
            })
            .then(async result => {
              if (result.error) {
                toast.error(result.error.message)
                await fetchInvoice()
              } else {
                toast.success('Successfully paid invoice')
                await props.onPaymentSuccess()
                closeCurrentModal()
              }
            })
        } else {
          await props.onPaymentSuccess()
          ready()
          closeCurrentModal()
        }
      },
      onError: async () => {
        await ajax({
          method: 'GET',
          endpoint: `/api-int/account/invoices/${props.invoiceId}`,
          onSuccess: response => {
            setInvoice(response)
          },
          errorMsg: 'Failed to retrieve invoice',
          encourageRetry: true,
        })
      },
    })
    ready()
  }

  const renderTimeline = () => {
    let shownCharges
    if (invoice.charges) {
      if (invoice.charges.length > 2 && !showExpandedCharges) {
        shownCharges = invoice.charges.slice(0, 2)
      } else {
        shownCharges = invoice.charges
      }
    }
    return (
      <>
        {invoice.next_payment_attempt && (
          <div className="mt-2">
            <h5 className="font-semibold text-gray-700">
              {dateFormat.format(invoice.next_payment_attempt * 1000)}
            </h5>
            <div className="text-sm text-gray-500">
              Invoice will be reattempted for payment
            </div>
          </div>
        )}
        {shownCharges &&
          shownCharges.map(charge => {
            return (
              <div className="mt-2" key={charge.created}>
                <h5 className="font-semibold text-gray-700">
                  {dateFormat.format(charge.created * 1000)}
                </h5>
                <div className="text-sm text-gray-500">
                  {charge.failure_message}
                </div>
              </div>
            )
          })}
        {invoice.charges &&
          invoice.charges.length > 2 &&
          !showExpandedCharges && (
            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                setShowExpandedCharges(true)
              }}
            >
              Show more
            </a>
          )}
      </>
    )
  }

  if (invoice) {
    return (
      <>
        {!card ? (
          <Alert type="danger">
            <div className="text-sm text-red-700">
              Please add a payment method to pay this invoice.
            </div>
          </Alert>
        ) : null}
        <div className="grid grid-cols-2">
          <div className="col-span-1">
            <h4>Amount Due</h4>
            <div>${(invoice.amount_due / 100).toFixed(2)} USD</div>
          </div>
          <div className="col-span-1">
            <h4>Payment Method</h4>
            <PaymentCard />
          </div>
        </div>

        <h4>Timeline</h4>
        {renderTimeline()}
        <div className="right mt-4">
          <LoaderButton disabled={!card} onClick={() => payInvoice()}>
            Pay
          </LoaderButton>
        </div>
      </>
    )
  } else {
    return <Loading />
  }
}
