/** @format */

import {useStripe} from '@stripe/react-stripe-js'
import {PaymentIntent, PaymentMethod} from '@stripe/stripe-js'

import {Plan, useAccountContext} from '@src/contexts'
import {useFetch} from '@src/hooks/useFetch'

type ChangePlan = (plan: Plan, paymentMethod?: PaymentMethod) => Promise<void>

export function useChangePlan(): ChangePlan {
  const {reloadAccount} = useAccountContext()
  const fetch = useFetch()
  const stripe = useStripe()

  // If paymentMethod is not passed in, the card on file will be used.
  return async function changePlan(plan, paymentMethod): Promise<void> {
    const stripeSub:
      | {
          default_payment_method?: string
          payment_intent: PaymentIntent | null
        }
      | {
          error: string
        } = await fetch('/api-int/stripe-subscription', {
      method: 'POST',
      payload: {
        payment_method_id: paymentMethod?.id,
        plan: plan.stripe_plan,
      },
    })

    if ('error' in stripeSub) {
      throw new Error(stripeSub.error)
    }

    if (
      stripeSub.payment_intent != null &&
      stripeSub.payment_intent.status !== 'succeeded'
    ) {
      if (stripe == null) {
        throw new Error(
          'Unable to complete payment due to missing Stripe instance',
        )
      }

      const clientSecret = stripeSub.payment_intent.client_secret
      if (clientSecret == null) {
        throw new Error(
          'Unable to complete payment due to invalid payment intent',
        )
      }

      const confirmPayment = await stripe.confirmCardPayment(clientSecret, {
        payment_method: stripeSub.default_payment_method,
        setup_future_usage: 'off_session',
      })

      if (confirmPayment.error) {
        throw confirmPayment.error
      }
    }

    await fetch('/api-int/subscribe', {
      method: 'POST',
    })

    await reloadAccount()
  }
}
