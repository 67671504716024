/** @format */

const React = require('react')
import Select from 'react-select'

export default function FormspreeSelect(props) {
  const {
    isMulti,
    options,
    value,
    onChange,
    placeholder,
    defaultValue,
    disabled,
    ...attrs
  } = props

  // find the default value in the options list
  let defaultValueInOptions
  if (Array.isArray(defaultValue)) {
    defaultValueInOptions = options.filter(option =>
      defaultValue.includes(option.value)
    )
  } else {
    defaultValueInOptions = options.find(
      option => option.value === defaultValue
    )
  }

  let valueInOptions
  if (Array.isArray(value)) {
    valueInOptions = options.filter(option => value.includes(option.value))
  } else {
    valueInOptions = options.find(option => option.value === value)
  }

  const customStyles = {
    menu: provided => ({
      ...provided,
      zIndex: '1000'
    }),
    menuPortal: provided => ({...provided, zIndex: 1000}),
    control: provided => ({
      ...provided,
      minHeight: '44px',
      borderStyle: 'none',
      borderWidth: '0px',
      borderRadius: '4px',
      padding: '0'
    }),
    input: provided => ({...provided, boxShadow: 'none'})
  }

  return (
    <div className="formspree-select-wrapper">
      <Select
        isMulti={isMulti}
        isDisabled={disabled}
        options={options}
        value={valueInOptions}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        defaultValue={defaultValueInOptions}
        onChange={onChange}
        placeholder={placeholder}
        styles={customStyles}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#aaa',
            primary25: '#ddd',
            primary50: '#ddd',
            danger: theme.colors.neutral80,
            dangerLight: '#ddd',
            neutral0: 'rgb(243, 244, 246)'
          }
        })}
        {...attrs}
      />
    </div>
  )
}
