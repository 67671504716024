/** @format */

import React, {lazy} from 'react'
import {createBrowserRouter, Outlet} from 'react-router-dom'

import {dashboardRouter} from '@src/dashboard'
import {iframeRouter} from '@src/dashboard/IFrameRouter'

import {PageTransition} from './PageTransition'

const AcceptTeamInvite = lazy(
  () => import('@src/accounts/teams/TeamInvitations/AcceptTeamInvite'),
)
const LoginPage = lazy(() => import('@src/login/LoginPage'))
const PlansPage = lazy(() => import('@src/plans/PlansPage'))
const Register = lazy(() => import('@src/register/Register'))

export const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <PageTransition>
        <Outlet />
      </PageTransition>
    ),
    children: [
      dashboardRouter,
      {
        path: 'invite/confirm',
        element: <AcceptTeamInvite />,
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'plans',
        element: <PlansPage />,
      },
      {
        path: 'register',
        element: <Register />,
      },
    ],
  },
  iframeRouter,
])
