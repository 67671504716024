/** @format */

import {LockClosedIcon} from '@heroicons/react/solid'
import {useDisclosure} from '@mantine/hooks'
import * as Sentry from '@sentry/react'
import {PaymentMethod} from '@stripe/stripe-js'
import React, {PropsWithChildren} from 'react'
import {Link} from 'react-router-dom'

import {StripeElementsProvider} from '@src/components/tailwind/StripeElementsProvider'
import {UpgradeSubscriptionModal} from '@src/components/tailwind/UpgradeSubscriptionModal'

import {AnalyticsEvent, sendAnalytics} from '@src/analytics'
import {FeatureId, Plan, useAccountContext, useProductsApi} from '@src/contexts'
import {useChangePlan} from '@src/hooks/useChangePlan'

type UpgradeLinkProps = PropsWithChildren<{
  className?: string
  feature: FeatureId
  overrideFeatureName?: string
}>

export function UpgradeLink(props: UpgradeLinkProps) {
  const {
    children = <DefaultChildren />,
    className = 'primaryLink',
    overrideFeatureName,
    feature,
  } = props

  const {account} = useAccountContext()

  function handleUpgradeLinkClick() {
    sendAnalytics(AnalyticsEvent.upgrade_clicked, {feature})
  }

  if (account.flags.upgrade_plan_v2) {
    return (
      <StripeElementsProvider>
        <UpgradePlanV2
          className={className}
          feature={feature}
          onUpgradeClick={handleUpgradeLinkClick}
          overrideFeatureName={overrideFeatureName}
        >
          {children}
        </UpgradePlanV2>
      </StripeElementsProvider>
    )
  }

  return (
    <Link className={className} onClick={handleUpgradeLinkClick} to="/plans">
      {children}
    </Link>
  )
}

type UpgradePlanV2Props = PropsWithChildren<{
  className: string
  feature: FeatureId
  onUpgradeClick: () => void
  overrideFeatureName?: string
}>

// need a separate component to conditionally call useChangePlan
function UpgradePlanV2(props: UpgradePlanV2Props) {
  const {children, className, feature, onUpgradeClick, overrideFeatureName} =
    props

  const productsApi = useProductsApi()
  const f = productsApi.getFeatureById(feature)
  const products = productsApi.getProductsWithFeature(feature)
  const productNames = products.map(id => productsApi.getProductById(id).name)

  const changePlan = useChangePlan()
  const [isModalOpen, modal] = useDisclosure(false)

  function handleUpgradeClick() {
    modal.open()
    onUpgradeClick()
  }

  async function handleConfirmUpgrade(
    plan: Plan,
    paymentMethod?: PaymentMethod,
  ): Promise<void> {
    await changePlan(plan, paymentMethod)
    close()
  }

  const featureName = overrideFeatureName ?? f.name
  const description = formatUpgradeModalDescription(featureName, productNames)
  return (
    <>
      <button className={className} onClick={handleUpgradeClick} type="button">
        {children}
      </button>

      <UpgradeSubscriptionModal
        description={description}
        isOpen={isModalOpen}
        onClose={() => modal.close()}
        onConfirmUpgrade={handleConfirmUpgrade}
        options={products}
        title={`Upgrade to unlock ${featureName}`}
      />
    </>
  )
}

function DefaultChildren() {
  return (
    <>
      <LockClosedIcon className="inline-block h-4 w-4 align-text-bottom" />
      <span>&nbsp;Upgrade to unlock</span>
    </>
  )
}

function formatUpgradeModalDescription(
  featureName: string,
  productNames: string[],
): string {
  const [p1, p2, p3] = productNames
  const prefix = `${featureName} requires a subscription`
  switch (productNames.length) {
    case 1:
      return `${prefix} to a ${p1} plan.`
    case 2:
      return `${prefix} to a ${p1} or ${p2} plan.`
    case 3:
      return `${prefix} to a ${p1}, ${p2} or ${p3} plan.`
    default:
      Sentry.captureException(
        new Error(
          `Encounter incorrect number of products (${productNames.length})`,
        ),
        {
          tags: {
            names: productNames.join(','),
          },
        },
      )
      return `${prefix}.`
  }
}
