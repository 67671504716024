/** @format */

import * as Sentry from '@sentry/react'
import React, {lazy} from 'react'
import {Outlet, useParams, type RouteObject} from 'react-router-dom'

import {AllFormsContextProvider} from '@src/contexts/AllFormsContext'
import {FormApiProvider} from '@src/contexts/FormApi'
import {ProjectsContextProvider} from '@src/contexts/ProjectsContext'

const CustomTemplatesSelect = lazy(
  () => import('@src/forms/FormPage/CustomTemplatesSelect'),
)

export const iframeRouter = {
  path: 'iframe',
  children: [
    {
      path: 'forms/:hashid',
      element: <FormsPageLayout />,
      children: [
        {
          path: 'whitelabel',
          element: <CustomTemplatesSelect />,
        },
      ],
    },
  ],
} satisfies RouteObject

function FormsPageLayout() {
  const {hashid} = useParams() as {hashid: string}
  return (
    <AllFormsContextProvider>
      <ProjectsContextProvider>
        <Sentry.ErrorBoundary>
          <FormApiProvider hashid={hashid}>
            <Outlet />
          </FormApiProvider>
        </Sentry.ErrorBoundary>
      </ProjectsContextProvider>
    </AllFormsContextProvider>
  )
}
